import { createStore, combineReducers } from "redux";
import { GetUser } from "./UserDetails/UserReducer";
import { LoginReducer } from "./Login/LoginReducer";

const reducerMap = {
  LoginReducer: LoginReducer,
  getUser: GetUser
};

const appReducer = combineReducers(reducerMap);

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export const basicReduxStore = createStore(
  rootReducer,
  // Enable the Redux DevTools extension if available
  /// See more: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfiblj
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
