import React from 'react';

import * as LEVELS from '../constants/levels';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const EditLevelDialog = (props) => {

  const editLevelDialog = props.editLevelDialog;
  const editLevel = props.editLevel;
  const setEditLevel = props.setEditLevel;
  const currencyOptions = props.currencyOptions;
  const submitUpdateLevel = props.submitUpdateLevel;
  const closeEditLevelDialog = props.closeEditLevelDialog;

  const hasProjectWeighting =
    (editLevel.type === LEVELS.TYPE.Level ||
      editLevel.type === LEVELS.TYPE.Register) &&
    editLevel.number === 2 &&
    (editLevel.model === LEVELS.Model.Quantity ||
      editLevel.model === LEVELS.Model.RuleOfCredit);
      
  return (
    <Dialog className="topLevelDialogContainer dialogWidth900 overFlowYScroll" open={editLevelDialog} onClose={closeEditLevelDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{`Level ${editLevel.number} - ${editLevel.name}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={0} >
          <Grid item lg={12} xs={12}>
            <Grid container spacing={1} >
              {/* Name */}
              <Grid item lg={3} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    id="newName"
                    label="Name"
                    variant="outlined"
                    size="small"
                    value={editLevel.name}
                    onChange={event => {
                      setEditLevel({ ...editLevel, name: event.target.value })
                    }}
                    required />
                </FormControl>
              </Grid>
              {/* Discipline */}
              <Grid item lg={3} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    id="newCode"
                    label="WBS Code"
                    variant="outlined"
                    size="small"
                    value={editLevel.discipline}
                    onChange={event => {
                      setEditLevel({ ...editLevel, discipline: event.target.value })
                    }}
                    inputProps={{ maxLength: 5 }}
                    required />
                </FormControl>
              </Grid>
              {/* basis */}
              <Grid item lg={3} xs={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="registerName">Basis</InputLabel>
                  <Select
                    labelId="register-name-label"
                    id="name"
                    label="Name"
                    margin="dense"
                    value={editLevel.basis}
                    onChange={event => {
                      setEditLevel({ ...editLevel, basis: event.target.value })
                    }}
                    required
                  >
                    <MenuItem value={1} >Cost </MenuItem>
                    <MenuItem value={0} > Man-Hours</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Unit of Measurement */}
              <Grid item lg={3} xs={12}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel id="currencyLabel">Currency</InputLabel>
                  <Select
                    labelId="currencyLabel"
                    id="Unit of Measurement"
                    label="Unit of Measurement"
                    margin="dense"
                    value={editLevel.currencyId}
                    onChange={event => {
                      setEditLevel({ ...editLevel, currencyId: event.target.value })
                    }}
                    required
                  >
                    {currencyOptions.map((item) => (
                      <MenuItem value={item.id} key={item.id}>{item.name} </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {editLevel.basis === LEVELS.CostBasis.ManHour ?
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="newManHour"
                      label="Man Hour"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={editLevel.manHour}
                      onChange={event => {
                        setEditLevel({ ...editLevel, manHour: event.target.value })
                      }}
                    />
                  </FormControl>
                </Grid>
                :
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="newCost"
                      label="Cost"
                      variant="outlined"
                      size="small"
                      value={editLevel.cost}
                      type="number"
                      onChange={event => {
                        setEditLevel({ ...editLevel, cost: event.target.value })
                      }}
                      required />
                  </FormControl>
                </Grid>}
              {/* Register */}
              <Grid item lg={3} xs={12}>
                <FormControl variant="outlined" required>
                  <FormControlLabel
                    control={<Checkbox name="isRegisterCheckbox" checked={editLevel.type === 1 ? true : false} onChange={event => {
                      setEditLevel({ ...editLevel, type: editLevel.type === 1 ? 0 : 1 })
                    }} />}
                    label="Register"
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>

              {editLevel.type === LEVELS.TYPE.Register &&
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="editLevel_activityModelLabel">Progress System</InputLabel>
                    <Select
                      id="editLevel_activityModel"
                      label="Progress System"
                      labelId="editLevel_activityModelLabel"
                      margin="dense"
                      value={editLevel.model}
                      onChange={(event) => {
                        setEditLevel({ ...editLevel, model: event.target.value });
                      }}
                      required
                    >
                      <MenuItem value={0}>Rule of Credit</MenuItem>
                      <MenuItem value={1}>Quantity</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              }

              {(editLevel.type === LEVELS.TYPE.Register || editLevel.number === 1) &&
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="newWeighting"
                      label="Weighting"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={editLevel.weighting}
                      inputProps={{ min: 0, max: 100 }}
                      onChange={event => {
                        setEditLevel({ ...editLevel, weighting: event.target.value })
                      }}
                      required />
                  </FormControl>
                </Grid>
              }


              {hasProjectWeighting &&
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="editLevel_projectWeighting"
                      label="Project Weighting"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={editLevel.projectWeighting}
                      inputProps={{ min: 0, max: 100 }}
                      onChange={event => {
                        setEditLevel({ ...editLevel, projectWeighting: event.target.value })
                      }}
                      required />
                  </FormControl>
                </Grid>
              }

            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeEditLevelDialog} color="primary">
          Cancel
            </Button>
        <Button onClick={submitUpdateLevel} color="primary">
          Submit
            </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLevelDialog;