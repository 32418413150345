import React, { useState } from "react";
import "date-fns";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { DATE_FORMAT } from "../../constants/strings";
import { LevelsService } from "../../../Service/LevelsService";
import moment from "moment";

const POLL_INTERVAL_DURATION = 2000;
let pollInterval;

const DownloadMDR = ({ projectId, levelId }) => {
  const [showCutoffDateDialog, setShowCutoffDateDialog] = useState(false);
  const [MDRCutoffDate, setMDRCutoffDate] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [progressDialog, setProgressDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  const downloadMDR = async () => {
    setShowCutoffDateDialog(false);
    setProgressDialog({
      message: "Excel download in progress",
      title: "Downloading",
      open: true,
    });
    try {
      let operation = await LevelsService.downloadMDRExcel(
        projectId,
        levelId,
        MDRCutoffDate
      );
      setDownloadProgress(operation.percentage);
      const operationId = operation.id;

      if (operation.status === 0 || operation.status === 1) {
        pollInterval = setInterval(async () => {
          const currentProgress = operation.percentage;
          operation = await LevelsService.getOperationStatus(operationId);
          if (operation.percentage > currentProgress) {
            setDownloadProgress(operation.percentage);
          }
          if (operation.status === 2) {
            setProgressDialog({
              message: "Excel download completed",
              title: "Completed",
              open: true,
            });
            handleDownloadLink(operation.result);
            clearInterval(pollInterval);
          }
          if (operation.status === 3) {
            setProgressDialog({
              message: "Excel download failed",
              title: "Error",
              open: true,
            });
            clearInterval(pollInterval);
          }
        }, POLL_INTERVAL_DURATION);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadLink = (result) => {
    const exportedFileName = "RegisterMDR.xlsx";
    const link = document.createElement("a");
    link.setAttribute("href", result.location);
    link.setAttribute("download", exportedFileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closeProgressDialog = () => {
    setProgressDialog({ ...progressDialog, open: false });
    setDownloadProgress(0);
  };

  const cutoffDialog = () => {
    return (
      <Dialog aria-labelledby="dialog-title" open={showCutoffDateDialog}>
        <DialogTitle id="dialog-title">Select cutoff date?</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={DATE_FORMAT}
                margin="dense"
                label={"Cutoff Date (Optional)"}
                value={MDRCutoffDate}
                onChange={(newDate) => {
                  if (newDate !== null) {
                    setMDRCutoffDate(moment(newDate).format("yyyy-MM-DD"));
                  } else {
                    setMDRCutoffDate(null);
                  }
                }}
                autoOk={true}
                KeyboardButtonProps={{ "aria-label": "change date" }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowCutoffDateDialog(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={downloadMDR} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ProgressDialog = () => {
    return (
      <Dialog
        aria-labelledby="dialog-title"
        open={progressDialog.open}
        onClose={closeProgressDialog}
      >
        <DialogTitle id="dialog-title">{progressDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {progressDialog.message}
          </DialogContentText>
          <LinearProgress
            style={{ marginBottom: "12px" }}
            value={downloadProgress}
            variant="determinate"
          />
          {downloadProgress === 100 && (
            <DialogActions>
              <Button onClick={closeProgressDialog} color="primary">
                Ok
              </Button>
            </DialogActions>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <Grid item lg={3} xs={12}>
        <IconButton
          color="primary"
          aria-label="download MDR"
          component="span"
          onClick={() => {
            setShowCutoffDateDialog(true);
          }}
        >
          <GetAppRoundedIcon />
        </IconButton>
        <Typography variant="caption">Download MDR</Typography>
      </Grid>
      {cutoffDialog()}
      {ProgressDialog()}
    </>
  );
};

export default DownloadMDR;
