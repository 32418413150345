import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";

const ActivitiesSelector = ({
  projects,
  onProjectChange,
  onRegisterChange,
}) => {
  const [registers, setRegisters] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedRegister, setSelectedRegister] = useState("");

  const handleChangeProject = (event) => {
    const newProject = event.target.value;
    onProjectChange(newProject);
    setSelectedProject(newProject);
    setRegisters(newProject.registers);
    setSelectedRegister("");
  };

  const handleRegisterChange = (event) => {
    const newRegister = event.target.value;
    onRegisterChange(newRegister);
    setSelectedRegister(newRegister);
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            id="project"
            labelId="project-label"
            value={selectedProject}
            onChange={handleChangeProject}
            label="Project"
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="register-label">Register</InputLabel>
          <Select
            id="register"
            labelId="register-label"
            value={selectedRegister}
            onChange={handleRegisterChange}
            label="Register"
          >
            {registers
              .map((register) => (
                <MenuItem key={register.id} value={register}>
                  {register.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ActivitiesSelector;
