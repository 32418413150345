import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const getAllUsers = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/users", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            console.log(error);
            return error;
        });
};

const getCurrentUser = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/users/me", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            console.log(error);
            return error;
        });
}

const getAllProjects = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/projects", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            console.log(error);
            return error;
        });
};

const getProjectById = async (id) => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/projects/" + id, {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload.project;
        })
        .catch(function (error) {
            console.log(error);
            throw error;
        });
};

export const homeService = {

    getAllUsers,
    getCurrentUser,
    getAllProjects,
    getProjectById

};