import axios from "axios";
import { authProvider } from "../MSAL/authProvider";
import { appConfig } from "../../appConfig";

let token = null;

const GetToken = async () => {
  return await authProvider.getAccessToken().then((token) => {
    return "Bearer " + token.accessToken;
  });
};

const setToken = async () => {
  token = await GetToken();
}
setToken();

const getProject = async (id) => {
  return axios
    .get(appConfig.baseUrl + "/projects/" + id, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};

const updateProject = async (projectData) => {
  return axios
    .put(appConfig.baseUrl + "/projects", projectData, {
      headers: {
        Authorization: token,
      }
    })
    .then(response => {
      return response.data.payload;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

export const ProjectDetailsService = {
  getProject,
  updateProject
};