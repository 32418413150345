import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

const addRuleOfCredit = async (projectId, levelId, newROC) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .post(appConfig.baseUrl + "/projects/" + projectId + "/levels/" + levelId + "/ruleofcredits", newROC, {
      headers: {
        Authorization: token,
      }
    })
    .then(response => {
      return response.data.payload.ruleOfCredit;
    })
    .catch(basicErrorHandler);
};

const deleteRuleOfCredit = async (projectId, levelId, rocId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .delete(appConfig.baseUrl + "/projects/" + projectId + "/levels/" + levelId + "/ruleofcredits/" + rocId, {
      headers: {
        Authorization: token,
      }
    })
    .then(response => {
      return rocId;
    })
    .catch(basicErrorHandler);
};

const updateRuleOfCredit = async (projectId, levelId, updatedRuleOfCredit) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .put(appConfig.baseUrl + "/projects/" + projectId + "/levels/" + levelId + "/ruleofcredits", updatedRuleOfCredit, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.ruleOfCredit;
    })
    .catch(basicErrorHandler);
};

const getProjectById = async (id) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/projects/" + id, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};

export const RuleOfCreditsService = {
  addRuleOfCredit,
  deleteRuleOfCredit,
  updateRuleOfCredit,
  getProjectById
};