import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

const getActivities = async (projectId, levelId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/projects/" + projectId + "/levels/" + levelId + "/activities", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.activities;
    })
    .catch(basicErrorHandler);
};

const updateActivityProgress = async (projectId, levelId, activityId, progressObj) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .put(`${appConfig.baseUrl}/projects/${projectId}/levels/${levelId}/activities/${activityId}/progress`, progressObj, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return {
        dataId: response.data.dataId,
        activity: response.data.payload.activity
      };
    })
    .catch(basicErrorHandler);
}

const updateActivityMyProgress = async (projectId, levelId, activityId, progressObj) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .put(`${appConfig.baseUrl}/projects/${projectId}/levels/${levelId}/activities/${activityId}/my/progress`, progressObj, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return {
        dataId: response.data.dataId,
        activity: response.data.payload.activity
      };
    })
    .catch(basicErrorHandler);
}

const getCurrentUser = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/users/me", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.user;
    })
    .catch(basicErrorHandler);
}

export const ActivitiesService = {
  getActivities,
  updateActivityProgress,
  updateActivityMyProgress,
  getCurrentUser
};