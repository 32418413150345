import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Button from '@material-ui/core/Button';

const ArchivesDialog = ({archives, open, closeArchivesDialog}) => {
  
  const onClickListItem = (archive) => {
    const link = document.createElement("a");
    if (link.download == undefined) { // feature detection
      // TODO: Add exception code
    }

    // Browsers that support HTML5 download attribute
    link.setAttribute("href", archive.url);
    link.setAttribute("download", archive.fileName);
    link.style.visibility = 'hidden';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog open={open} onClose={closeArchivesDialog} fullWidth>
      <DialogTitle id="archives-dialog-title">Register Archives</DialogTitle>
      <DialogContent>
        <List>
          {archives.map(arc =>
            <ListItem key={arc.id} button divider onClick={() => {onClickListItem(arc)}}>
              <ListItemText primary={`Version ${arc.version}`} secondary={new Date(arc.triggerDate).toLocaleString()} />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeArchivesDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchivesDialog;