import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import ProjectDetailsTabs from "../ProjectDetailsTabs/ProjectDetailsTabs";
import EditLevelDialog from "../EditLevelDialog/EditLevelDialog";
import { levelDetailsService } from "../../Service/levelDetailsService";
import { UsersService } from "../../Service/UsersService";
import * as PROJECTS from "../constants/projects";
import * as LEVELS from "../constants/levels";
import "./levelDetails.css";
import ImportWBS from "./ImportWBS";

import { currency } from "../filters/currency";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import L2Table from "../L2Table/L2Table";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

export default function LevelsDetails(props) {
  const { id } = useParams();
  const history = useHistory();

  const [showSpinner, setShowSpinner] = useState(false);
  const [newLevelData, setNewLevelData] = useState({
    name: "",
    code: "",
    basis: 1,
    currency: "",
    cost: "",
    manHour: "",
    register: false,
    expanded: false,
    weighting: "",
    model: 0,
    l2s: [],
    errors: {
      name: {
        error: false,
        helperText: "",
      },
      code: {
        error: false,
        helperText: "",
      },
      basis: {
        error: false,
        helperText: "",
      },
      currency: {
        error: false,
        helperText: "",
      },
      cost: {
        error: false,
        helperText: "",
      },
      weighting: {
        error: false,
        helperText: "",
      },
    },
  });
  const [userPermissions, setUserPermissions] = useState({
    systemAdmin: false,
    projectManager: false,
    projectRole: 0,
  });

  const [existingLevels, setExistingLevels] = useState([]);

  const [currencyOptions, setCurrencies] = useState([]);

  const orderL1 = useMemo(
    () =>
      existingLevels.map((level) => {
        return level.order;
      }),
    [existingLevels]
  ).sort((a, b) => a - b);

  // componentDidMount: useEffect executes ONLY after UseManagement component is mounted
  useEffect(() => {
    // Call DB to get users and projects
    _initModule();
  }, []);

  const _initModule = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle("WBS");
      props.setViewMode("admin");

      const [levels, currency, project, currentUser] = await Promise.all([
        levelDetailsService.getLevelsByProjectId(parseInt(id)),
        levelDetailsService.getCurrencies(),
        levelDetailsService.getProjectById(id),
        UsersService.getCurrentUser(),
      ]);

      // Determine User permissions
      const permissions = _initializePermissions(currentUser, project);
      const validProjectRoles = [
        PROJECTS.Roles.ProjectAdmin,
        PROJECTS.Roles.InternalUser,
        PROJECTS.Roles.Viewer,
      ];

      if (
        !permissions.systemAdmin &&
        !permissions.projectManager &&
        !validProjectRoles.includes(permissions.projectRole)
      ) {
        alert("You do not have access to view the project");
        goHome();
      }

      setCurrencies(currency);
      let tempArray = levels.map((item) => ({
        id: parseInt(item.id),
        name: item.name,
        discipline: item.discipline,
        basis: item.costBasis,
        currency: item.currency,
        cost: item.cost ?? "",
        manHour: item.manHour ?? "",
        type: item.type,
        expanded: false,
        disableRegister: false,
        weighting: item.weight,
        attributeIds: item.attributes
          ? item.attributes.map((attr) => attr.id)
          : [],
        model: item.model,
        number: item.number,
        order: item.order,
        l2s: item.children
          ? item.children.map((child) => ({
              id: parseInt(child.id),
              name: child.name,
              discipline: child.discipline,
              basis: child.costBasis,
              currency: child.currency,
              cost: child.cost ?? "",
              manHour: child.manHour ?? "",
              type: child.type,
              weighting: child.weight,
              attributeIds: child.attributes
                ? child.attributes.map((childItem) => childItem.id)
                : [],
              model: child.model,
              number: child.number,
              order: child.order,
            }))
          : [],
      }));

      props.setPageTitle(`WBS - ${project.name}`);
      setExistingLevels([...tempArray]);
      setUserPermissions(permissions);
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  };

  const _initializePermissions = (currentUser, project) => {
    const systemAdmin = currentUser.admin;

    const projectManagerId = project.manager ? project.manager.id : null;
    const projectManager = currentUser.id === projectManagerId;

    const userPermission = currentUser.permissions.find(
      (p) => p.projectId === parseInt(id)
    );
    const projectRole = userPermission
      ? userPermission.role
      : PROJECTS.Roles.None;

    return {
      systemAdmin,
      projectManager,
      projectRole,
    };
  };

  const goHome = () => {
    history.push("/");
  };

  const handleCurrencyChange = (event) => {
    setNewLevelData({ ...newLevelData, currency: event.target.value });
  };

  const handleActivityModelChange = (event) => {
    setNewLevelData({ ...newLevelData, model: event.target.value });
  };

  const makeL2Register = (l1Index, l2Index) => {
    const newRegisterValue = !existingLevels[l1Index].l2s[l2Index].register;
    var newL1 = { ...existingLevels[l1Index] };

    if (newRegisterValue) {
      newL1.disableRegister = true;
    } else {
      newL1.disableRegister = false;
    }
    newL1.l2s[l2Index].register = newRegisterValue;
    const newLevels = [...existingLevels];
    newLevels[l1Index] = newL1;

    setExistingLevels(newLevels);
  };

  const deleteL2 = async (l1Index, l2Index) => {
    try {
      const parentL1 = existingLevels[l1Index];
      const l2ToDelete = parentL1.l2s[l2Index];

      const newL1 = {
        ...parentL1,
        l2s: [...parentL1.l2s],
      };
      newL1.l2s.splice(l2Index, 1);

      const newLevels = [...existingLevels];
      newLevels[l1Index] = newL1;

      setExistingLevels(newLevels);
      await levelDetailsService.deleteLevel(id, l2ToDelete.id);
    } catch (error) {
      console.log(error);
    }
  };

  const createNewL1 = async (arg) => {
    if (validateL1Form()) {
      try {
        setShowSpinner(true);
        let l1Data = {
          name: arg.name,
          type: arg.register ? 1 : 0,
          discipline: arg.code,
          costBasis: arg.basis,
          currencyId: arg.currency,
          weighting: arg.weighting,
        };

        if (l1Data.costBasis === 1) {
          l1Data.cost = arg.cost;
        } else {
          l1Data.manHour = arg.manHour;
        }

        if (l1Data.type === 1) {
          l1Data.model = arg.model;
        }

        await levelDetailsService.createL1(id, l1Data);
        window.location.reload();
      } catch (error) {
        console.log(error);
        alert(error.data.detail);
        setShowSpinner(false);
      }
    }
  };

  const createNewL2 = async (l2, parentId) => {
    try {
      setShowSpinner(true);

      const parentL1 = existingLevels.find((lvl) => lvl.id === parentId);

      let l2Data = {
        name: l2.name,
        type: l2.register ? 1 : 0,
        discipline: l2.code,
        costBasis: l2.basis,
        currencyId: l2.currency,
        parentId: parentId,
      };

      if (l2Data.costBasis === 1) {
        l2Data.cost = l2.cost;
      } else {
        l2Data.manHour = l2.manHour;
      }

      if (l2.register) {
        l2Data.weighting = l2.weighting;
        l2Data.model = l2.model;
      }

      // Parent L1 Quantity Register AND L2
      if (
        parentL1.type === LEVELS.TYPE.Register &&
        (parentL1.model === LEVELS.Model.Quantity ||
          parentL1.model === LEVELS.Model.RuleOfCredit) &&
        !l2.register
      ) {
        l2Data.projectWeighting = l2.projectWeighting;
      }

      const saveL2 = await levelDetailsService.createL2(id, l2Data);
      return saveL2;
    } catch (error) {
      console.log(error);
      setShowSpinner(false);
      throw error;
    }
  };

  const setRegisterNewLevel = () => {
    const newRegisterValue = !newLevelData.register;

    setNewLevelData({ ...newLevelData, register: newRegisterValue });
  };

  const setL1Register = (l1Index) => {
    var newRegisterValue = !existingLevels[l1Index].register;
    var newL1 = { ...existingLevels[l1Index], register: newRegisterValue };

    var newLevels = [...existingLevels];
    newLevels[l1Index] = newL1;

    setExistingLevels(newLevels);
  };

  const expandLevel = (l1Index) => {
    var newExpandedValue = !existingLevels[l1Index].expanded;
    var newL1 = { ...existingLevels[l1Index], expanded: newExpandedValue };

    var newLevels = [...existingLevels];
    newLevels[l1Index] = newL1;

    setExistingLevels(newLevels);
  };

  const deleteL1 = async (l1Index) => {
    try {
      const l1ToDelete = existingLevels[l1Index];
      const newLevels = existingLevels.filter((l) => l.id !== l1ToDelete.id);

      setExistingLevels(newLevels);
      await levelDetailsService.deleteLevel(id, l1ToDelete.id);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewL2 = async (l2, l1Index) => {
    try {
      let tmpL2 = await createNewL2(l2, existingLevels[l1Index].id);
      tmpL2.weighting = tmpL2.weight;
      tmpL2.basis = tmpL2.costBasis;

      var newL1 = { ...existingLevels[l1Index] };
      newL1.l2s = [...newL1.l2s];
      newL1.l2s.push(tmpL2);

      var newLevels = [...existingLevels];
      newLevels[l1Index] = newL1;

      setExistingLevels(newLevels);
    } catch (error) {
      console.log(error);
      alert(error.data.detail);
      setShowSpinner(false);
    } finally {
      setShowSpinner(false);
    }
  };

  const validateL1Form = () => {
    let formValid = true;
    let errorObject = {
      name: {
        error: false,
        helperText: "",
      },
      code: {
        error: false,
        helperText: "",
      },
      basis: {
        error: false,
        helperText: "",
      },
      currency: {
        error: false,
        helperText: "",
      },
      cost: {
        error: false,
        helperText: "",
      },
      weighting: {
        error: false,
        helperText: "",
      },
    };
    if (!newLevelData.name) {
      formValid = false;
      errorObject.name.error = true;
      errorObject.name.helperText = "Name is required";
    }
    if (!newLevelData.code) {
      formValid = false;
      errorObject.code.error = true;
      errorObject.code.helperText = "WBS code is required";
    }
    if (!newLevelData.currency) {
      formValid = false;
      errorObject.currency.error = true;
      errorObject.currency.helperText = "Currencies is required";
    }
    if (!newLevelData.cost) {
      formValid = false;
      errorObject.cost.error = true;
      errorObject.cost.helperText = "Cost is required";
    }
    if (!newLevelData.weighting) {
      formValid = false;
      errorObject.weighting.error = true;
      errorObject.weighting.helperText = "Weighting is required";
    }
    if (newLevelData.weighting < 0 || newLevelData.weighting > 100) {
      formValid = false;
      errorObject.weighting.error = true;
      errorObject.weighting.helperText = "Weighting has to be 0-100";
    }

    setNewLevelData({ ...newLevelData, errors: errorObject });
    return formValid;
  };

  const _outputRegisterModel = (level) => {
    if (level.model === 0) {
      return "ROC";
    } else if (level.model === 1) {
      return "Quantity";
    } else {
      return "None";
    }
  };

  //#region EditLevelDialog
  const [editLevelDialog, setEditLevelDialog] = useState(false);
  const [editLevel, setEditLevel] = useState({
    name: "",
    code: "",
    basis: "",
    currency: "",
    cost: "",
    manHour: "",
    register: false,
    expanded: false,
    weighting: "",
    number: "",
    model: "",
    type: "",
    projectWeighting: "",
    parentId: "",
    order: "",
  });

  const openEditLevelDialog = (level, parentL1Index) => {
    // Required to update projectWeighting
    setEditLevel(formatLevelInfo(level, parentL1Index));

    setEditLevelDialog(true);
  };

  const formatLevelInfo = (level, parentL1Index) => {
    const parentL1Id =
      parentL1Index === undefined ? null : existingLevels[parentL1Index].id;

    return {
      ...level,
      weighting: level.weighting.value,
      projectWeighting: level.weighting.project,
      currencyId: level.currency.id,
      parentId: parentL1Id,
    };
  };

  const submitUpdateLevel = async (levelUpdated) => {
    if (validateUpdateLevel(levelUpdated)) {
      try {
        setEditLevelDialog(false);
        setShowSpinner(true);

        let levelUpdateData = {
          id: levelUpdated.id,
          name: levelUpdated.name,
          type: levelUpdated.type,
          discipline: levelUpdated.discipline,
          costBasis: levelUpdated.basis,
          currencyId: levelUpdated.currencyId,
          attributeIds: levelUpdated.attributeIds,
          order: levelUpdated.order,
        };

        if (levelUpdateData.costBasis === 1) {
          levelUpdateData.cost = levelUpdated.cost;
        } else {
          levelUpdateData.manHour = levelUpdated.manHour;
        }

        if (
          levelUpdated.type === LEVELS.TYPE.Register ||
          levelUpdated.type === LEVELS.TYPE.Level
        ) {
          levelUpdateData.model = levelUpdated.model;

          if (
            (levelUpdated.type === LEVELS.TYPE.Level &&
              levelUpdated.number === 1) ||
            levelUpdated.type === LEVELS.TYPE.Register
          ) {
            levelUpdateData.weighting = levelUpdated.weighting;
          }
          if (
            levelUpdated.model === LEVELS.Model.Quantity ||
            levelUpdated.model === LEVELS.Model.RuleOfCredit
          ) {
            levelUpdateData.projectWeighting = levelUpdated.projectWeighting;
          }
        }

        if (levelUpdated.number === 1) {
          const updatedL1Response = await levelDetailsService.updateLevel(
            id,
            levelUpdateData
          );
          const updatedL1WithL2s = await levelDetailsService.getLevel(
            id,
            updatedL1Response.level.id
          );
          _updateEditL1(updatedL1WithL2s, updatedL1Response.sequence);
        } else {
          const updatedL2Response = await levelDetailsService.updateLevel(
            id,
            levelUpdateData
          );
          _updateEditL2(
            updatedL2Response.level,
            levelUpdated.parentId,
            updatedL2Response.sequence
          );
        }
      } catch (error) {
        console.log(error);
        alert(error.data.detail);
        setShowSpinner(false);
      } finally {
        setShowSpinner(false);
      }
    }
  };

  const closeEditLevelDialog = () => {
    setEditLevelDialog(false);
  };

  const validateUpdateLevel = (arg) => {
    let formValid = true;

    if (!arg.name) {
      formValid = false;
      alert("Name is required");
    }
    if (!arg.discipline) {
      formValid = false;
      alert("WBS Code is required");
    }
    if (!arg.currency) {
      formValid = false;
      alert("Currencies is required");
    }
    if (arg.basis === 0) {
      if (isNaN(arg.manHour)) {
        formValid = false;
        alert("Man Hour is required");
      }
    } else {
      if (isNaN(arg.cost)) {
        formValid = false;
        alert("Cost is required");
      }
    }

    if (arg.register || arg.type === 1) {
      if (!arg.weighting) {
        formValid = false;
        alert("Weighting is required");
      }
    }

    if (
      arg.type === LEVELS.TYPE.Level &&
      arg.number === 2 &&
      (arg.model === LEVELS.Model.Quantity ||
        arg.model === LEVELS.Model.RuleOfCredit)
    ) {
      if (!arg.projectWeighting) {
        formValid = false;
        alert("Project Weighting is required");
      }
    }

    return formValid;
  };

  const _updateEditL1 = (updatedL1, newLevelsOrder) => {
    const currentL1Index = existingLevels.findIndex(
      (l1) => l1.id === updatedL1.id
    );
    const currentL1 = existingLevels[currentL1Index];

    const newL1 = {
      ...currentL1,
      id: updatedL1.id,
      name: updatedL1.name,
      discipline: updatedL1.discipline,
      basis: updatedL1.costBasis,
      currency: updatedL1.currency,
      cost: updatedL1.cost ?? "",
      manHour: updatedL1.manHour ?? "",
      type: updatedL1.type,
      expanded: currentL1.expanded,
      disableRegister: currentL1.disableRegister,
      weighting: updatedL1.weight,
      attributeIds: updatedL1.attributes
        ? updatedL1.attributes.map((a) => a.id)
        : [],
      model: updatedL1.model,
      number: updatedL1.number,
      order: updatedL1.order,
      l2s: updatedL1.children
        ? updatedL1.children.map((l2) => ({
            id: parseInt(l2.id),
            name: l2.name,
            discipline: l2.discipline,
            basis: l2.costBasis,
            currency: l2.currency,
            cost: l2.cost ?? "",
            manHour: l2.manHour ?? "",
            type: l2.type,
            weighting: l2.weight,
            attributeIds: l2.attributes
              ? l2.attributes.map((childItem) => childItem.id)
              : [],
            model: l2.model,
            number: l2.number,
            order: l2.order,
          }))
        : [],
    };

    const updatedExistingLevels = [...existingLevels];
    updatedExistingLevels[currentL1Index] = newL1;

    if (newLevelsOrder) {
      Object.keys(newLevelsOrder).forEach((levelId) => {
        updatedExistingLevels.find((l1) => l1.id === Number(levelId)).order =
          newLevelsOrder[levelId];
      });
    }

    setExistingLevels(updatedExistingLevels);
  };

  const _updateEditL2 = (updateL2, parentL1Id, newLevelsOrder) => {
    const parentL1Index = existingLevels.findIndex(
      (l1) => l1.id === parentL1Id
    );
    const parentL1 = existingLevels[parentL1Index];

    const currentL2Index = parentL1.l2s.findIndex(
      (l2) => l2.id === updateL2.id
    );
    const currentL2 = parentL1.l2s[currentL2Index];

    const newL2 = {
      ...currentL2,
      id: parseInt(updateL2.id),
      name: updateL2.name,
      discipline: updateL2.discipline,
      basis: updateL2.costBasis,
      currency: updateL2.currency,
      cost: updateL2.cost ?? "",
      manHour: updateL2.manHour ?? "",
      type: updateL2.type,
      weighting: updateL2.weight,
      attributeIds: updateL2.attributes
        ? updateL2.attributes.map((a) => a.id)
        : [],
      model: updateL2.model,
      number: updateL2.number,
      order: updateL2.order,
    };

    const updatedParentL1 = { ...parentL1 };
    updatedParentL1.l2s[currentL2Index] = newL2;

    if (newLevelsOrder) {
      Object.keys(newLevelsOrder).forEach((levelId) => {
        updatedParentL1.l2s.find((l2) => l2.id === Number(levelId)).order =
          newLevelsOrder[levelId];
      });
    }

    const updatedExistingLevels = [...existingLevels];
    updatedExistingLevels[parentL1Index] = updatedParentL1;

    setExistingLevels(updatedExistingLevels);
  };
  //#endregion

  const _userHasWriteAccess = () => {
    return (
      userPermissions.systemAdmin ||
      userPermissions.projectManager ||
      userPermissions.projectRole === PROJECTS.Roles.ProjectAdmin
    );
  };

  const _userOnlyHasReadAccess = () => {
    const readOnlyRoles = [PROJECTS.Roles.InternalUser, PROJECTS.Roles.Viewer];

    return (
      !userPermissions.systemAdmin &&
      !userPermissions.projectManager &&
      readOnlyRoles.includes(userPermissions.projectRole)
    );
  };

  return (
    <div className="LevelsDetails">
      <EditLevelDialog
        editLevelDialog={editLevelDialog}
        editLevel={editLevel}
        currencyOptions={currencyOptions}
        setEditLevel={setEditLevel}
        submitUpdateLevel={() => {
          submitUpdateLevel(editLevel);
        }}
        closeEditLevelDialog={closeEditLevelDialog}
      />

      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>

      <div className="fullWidth spaceEvenly marginTop marginBottom20">
        <ProjectDetailsTabs currentTab="wbs"></ProjectDetailsTabs>
      </div>
      {_userHasWriteAccess() && <ImportWBS />}
      {_userHasWriteAccess() && (
        <div className="fullWidth spaceEvenly marginTop30">
          <Grid item xs={10}>
            <Paper className="fullWidth" elevation={2}>
              <Typography variant="h5" component="h4" className="padding20">
                Add Level 1 WBS
              </Typography>
              <form
                id="levelDetails"
                name="levelDetails"
                autoComplete="off"
                className="padding20"
              >
                <Grid container spacing={3}>
                  {/* Name */}
                  <Grid item lg={3} xs={12}>
                    <FormControl fullWidth required>
                      <TextField
                        id="newName"
                        label="Name"
                        variant="outlined"
                        size="small"
                        value={newLevelData.name}
                        onChange={(event) => {
                          setNewLevelData({
                            ...newLevelData,
                            name: event.target.value,
                          });
                        }}
                        error={newLevelData.errors.name.error}
                        helperText={newLevelData.errors.name.helperText}
                        required
                      />
                    </FormControl>
                  </Grid>
                  {/* Discipline */}
                  <Grid item lg={3} xs={12}>
                    <FormControl fullWidth required>
                      <TextField
                        id="newCode"
                        label="WBS Code"
                        variant="outlined"
                        size="small"
                        value={newLevelData.code}
                        onChange={(event) => {
                          setNewLevelData({
                            ...newLevelData,
                            code: event.target.value,
                          });
                        }}
                        inputProps={{ maxLength: 5 }}
                        error={newLevelData.errors.code.error}
                        helperText={newLevelData.errors.code.helperText}
                        required
                      />
                    </FormControl>
                  </Grid>
                  {/* basis */}
                  <Grid item lg={3} xs={12}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel id="registerName">Basis</InputLabel>
                      <Select
                        labelId="register-name-label"
                        id="name"
                        label="Name"
                        margin="dense"
                        value={newLevelData.basis}
                        onChange={(event) => {
                          setNewLevelData({
                            ...newLevelData,
                            basis: event.target.value,
                          });
                        }}
                        error={newLevelData.errors.basis.error}
                        helperText={newLevelData.errors.basis.helperText}
                        required
                        disabled
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Cost </MenuItem>
                        <MenuItem value={0}> Man-Hours</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Unit of Measurement */}
                  <Grid item lg={3} xs={12}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="currencyLabel">Currency</InputLabel>
                      <Select
                        labelId="currencyLabel"
                        id="Unit of Measurement"
                        label="Unit of Measurement"
                        margin="dense"
                        value={newLevelData.currency}
                        onChange={handleCurrencyChange}
                        required
                        error={newLevelData.errors.currency.error}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {currencyOptions.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText className="redColorHelperText">
                        {newLevelData.errors.currency.helperText}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {newLevelData.basis === 0 ? (
                    <Grid item lg={3} xs={12}>
                      <FormControl fullWidth required>
                        <TextField
                          id="newManHour"
                          label="Man Hour"
                          variant="outlined"
                          size="small"
                          type="number"
                          value={newLevelData.manHour}
                          onChange={(event) => {
                            setNewLevelData({
                              ...newLevelData,
                              manHour: event.target.value,
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item lg={3} xs={12}>
                      <FormControl fullWidth required>
                        <TextField
                          id="newCost"
                          label="Cost"
                          variant="outlined"
                          size="small"
                          value={newLevelData.cost}
                          type="number"
                          onChange={(event) => {
                            setNewLevelData({
                              ...newLevelData,
                              cost: event.target.value,
                            });
                          }}
                          error={newLevelData.errors.cost.error}
                          helperText={newLevelData.errors.cost.helperText}
                          required
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {/* Weighting */}
                  <Grid item lg={3} xs={12}>
                    <FormControl fullWidth required>
                      <TextField
                        id="newWeighting"
                        label="Weighting"
                        variant="outlined"
                        size="small"
                        type="number"
                        value={newLevelData.weighting}
                        onChange={(event) => {
                          setNewLevelData({
                            ...newLevelData,
                            weighting: event.target.value,
                          });
                        }}
                        error={newLevelData.errors.weighting.error}
                        helperText={newLevelData.errors.weighting.helperText}
                        required
                      />
                    </FormControl>
                  </Grid>
                  {/* Register */}
                  <Grid item lg={3} xs={12}>
                    <FormControl variant="outlined" required>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isRegisterCheckbox"
                            checked={newLevelData.register}
                            onChange={setRegisterNewLevel}
                          />
                        }
                        label="Link a register?"
                        labelPlacement="start"
                      />
                    </FormControl>
                  </Grid>

                  {newLevelData.register && (
                    <Grid item lg={3} xs={12}>
                      <FormControl fullWidth variant="outlined" required>
                        <InputLabel id="activityModelLabel">
                          Progress System
                        </InputLabel>
                        <Select
                          id="activityModel"
                          label="Progress System"
                          labelId="activityModelLabel"
                          margin="dense"
                          value={newLevelData.model}
                          onChange={handleActivityModelChange}
                          required
                        >
                          <MenuItem value={0}>Rule of Credit</MenuItem>
                          <MenuItem value={1}>Quantity</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </form>
              {/* Action button container */}
              <Grid
                container
                justify="flex-end"
                spacing={3}
                className="paddingBottom10"
              >
                <Grid item lg={3} xs={12} className="spaceEvenly flexWrap">
                  <Grid item lg={5} xs={12} className="padding10">
                    <Button
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      onClick={() => createNewL1(newLevelData)}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item lg={5} xs={12} className="padding10">
                    <Button fullWidth={true} color="secondary" onClick={goHome}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      )}

      <div className="fullWidth spaceEvenly marginTop30">
        <Grid item xs={10}>
          <Paper className="fullWidth" elevation={2}>
            <Typography variant="h6" component="h4" className="padding20">
              Work Breakdown Structure (WBS)
            </Typography>

            {existingLevels
              .sort((aLevel, bLevel) => {
                return aLevel.order - bLevel.order;
              })
              .map((level, index) => (
                <div className="padding20" key={index}>
                  <Accordion expanded={level.expanded}>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon onClick={() => expandLevel(index)} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="spaceAround"
                    >
                      <div className="fullWidth ">
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            lg={_userHasWriteAccess() ? 10 : 12}
                            container
                            spacing={1}
                          >
                            <Grid item xs={12} lg={2}>
                              <Typography>{level.name}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <Typography>
                                {" "}
                                WBS Code: {level.discipline}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <Typography>
                                {" "}
                                Basis: {level.basis === 0 ? "Man Hour" : "Cost"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <Typography>
                                {" "}
                                Currency:{level.currency.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              <Typography>
                                {" "}
                                Cost:{" "}
                                {currency(
                                  level.basis === 0 ? level.manHour : level.cost
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <Typography>
                                {" "}
                                Weighting: {level.weighting.value}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} lg={2}>
                              <Typography>
                                {" "}
                                Link a Register?:{" "}
                                <Checkbox
                                  className="padding0"
                                  name="isRegisterCheckbox"
                                  checked={level.type === 1}
                                  onChange={
                                    _userOnlyHasReadAccess()
                                      ? null
                                      : () => setL1Register(index)
                                  }
                                  disabled={level.disableRegister}
                                  color="primary"
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              <Typography>
                                Progress System: {_outputRegisterModel(level)}
                              </Typography>
                            </Grid>
                          </Grid>
                          {_userHasWriteAccess() && (
                            <Grid
                              item
                              xs={12}
                              lg={2}
                              container
                              spacing={1}
                              justifyContent="flex-end"
                            >
                              <Grid
                                item
                                xs={3}
                                md={4}
                                className="alignTextCenter"
                              >
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => deleteL1(index)}
                                  className="padding0 "
                                >
                                  <DeleteOutlineRoundedIcon color="secondary" />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                className="alignTextCenter"
                              >
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => openEditLevelDialog(level)}
                                  className="padding0 "
                                >
                                  <EditRoundedIcon color="primary" />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={4}
                                className="alignTextCenter"
                              >
                                <FormControl fullWidth required>
                                  <Select
                                    id={"l1Order" + level.id}
                                    size="small"
                                    value={level.order}
                                    label="Order"
                                    onChange={(event) => {
                                      submitUpdateLevel({
                                        ...formatLevelInfo(level),
                                        order: Number(event.target.value),
                                        weighting: level.weighting.value,
                                      });
                                    }}
                                    required
                                  >
                                    {orderL1.map((levelOrder, index) => (
                                      <MenuItem
                                        value={levelOrder}
                                        selected={levelOrder === level.order}
                                      >
                                        {index + 1}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>Order</FormHelperText>
                                </FormControl>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <L2Table
                        l2Array={level.l2s}
                        l1Index={index}
                        l1RegisterValue={level.register}
                        l1TypeValue={level.type}
                        l1ModelValue={level.model}
                        deleteL2={deleteL2}
                        addNewL2={addNewL2}
                        makeL2RegisterFunc={makeL2Register}
                        editLevel={openEditLevelDialog}
                        currencyOptions={currencyOptions}
                        writeAccess={_userHasWriteAccess()}
                        readOnly={_userOnlyHasReadAccess()}
                        editOrder={(updateLevel, l1ParentIndex) => {
                          submitUpdateLevel(
                            formatLevelInfo(updateLevel, l1ParentIndex)
                          );
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}

            {existingLevels.length === 0 && (
              <Grid container justify="center" className="padding20">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  There are no Levels for this Project.
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </div>
    </div>
  );
}
