import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const getProjects = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/projects", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.projects;
    })
    .catch(_basicErrorHandler);
};

const getProjectById = async (id) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/projects/" + id, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(_basicErrorHandler);
};

const addUserToProject = async (project, userId, role) => {
  const updatedPermissions = project.permissions.map(pp => {
    return {
      userId: pp.user.id,
      role: pp.role
    };
  });

  updatedPermissions.push({
    userId: userId,
    role: role
  });

  // Pass minimum required information
  const projData = {
    id: project.id,
    name: project.name,
    client: project.client,
    externalReference: project.externalReference,
    internalReference: project.internalReference,
    managerId: project.manager.id,
    facilityEquipmentScopeId: project.facilityEquipmentScope.id,
    subBusinessLineId: project.subBusinessLine.id,
    executionLocationId: project.executionLocation.id,
    facilityLocationId: project.facilityLocation.id,
    cutOffWeekDay: project.cutOffWeekDay,
    cutOffMonthDay: project.cutOffMonthDay,
    contractualStartDate: project.contractualStartDate,
    contractualEndDate: project.contractualEndDate,
    forecastStartDate: project.forecastStartDate ? project.forecastStartDate : null,
    forecastEndDate: project.forecastEndDate ? project.forecastEndDate : null,
    actualStartDate: project.actualStartDate ? project.actualStartDate : null,
    actualEndDate: project.actualEndDate ? project.actualEndDate : null,
    permissions: updatedPermissions,
  };

  const token = await AuthTokenGenerator.getToken();

  return axios
    .put(appConfig.baseUrl + "/projects", projData, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(_basicErrorHandler);
};

const updateUserProjectRole = async (project, userId, newRole) => {
  const updatedPermissions = project.permissions.map(pp => {
    return {
      userId: pp.user.id,
      role: pp.role
    };
  });

  const permissionIndex = project.permissions.findIndex(p => p.user.id === userId);
  updatedPermissions[permissionIndex].role = newRole;

  // Pass minimum required information
  const projData = {
    id: project.id,
    name: project.name,
    client: project.client,
    externalReference: project.externalReference,
    internalReference: project.internalReference,
    managerId: project.manager.id,
    facilityEquipmentScopeId: project.facilityEquipmentScope.id,
    subBusinessLineId: project.subBusinessLine.id,
    executionLocationId: project.executionLocation.id,
    facilityLocationId: project.facilityLocation.id,
    cutOffWeekDay: project.cutOffWeekDay,
    cutOffMonthDay: project.cutOffMonthDay,
    contractualStartDate: project.contractualStartDate,
    contractualEndDate: project.contractualEndDate,
    forecastStartDate: project.forecastStartDate ? project.forecastStartDate : null,
    forecastEndDate: project.forecastEndDate ? project.forecastEndDate : null,
    actualStartDate: project.actualStartDate ? project.actualStartDate : null,
    actualEndDate: project.actualEndDate ? project.actualEndDate : null,
    permissions: updatedPermissions
  };

  const token = await AuthTokenGenerator.getToken();

  return axios
    .put(appConfig.baseUrl + "/projects", projData, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(_basicErrorHandler);
};

const removeUserFromProject = async (project, userId) => {
  const updatedPermissions = project.permissions.map(pp => {
    return {
      userId: pp.user.id,
      role: pp.role
    };
  });

  const permissionIndex = updatedPermissions.findIndex(p => p.userId === userId);
  updatedPermissions.splice(permissionIndex, 1);

  // Pass minimum required information
  const projData = {
    id: project.id,
    name: project.name,
    client: project.client,
    externalReference: project.externalReference,
    internalReference: project.internalReference,
    managerId: project.manager.id,
    facilityEquipmentScopeId: project.facilityEquipmentScope.id,
    subBusinessLineId: project.subBusinessLine.id,
    executionLocationId: project.executionLocation.id,
    facilityLocationId: project.facilityLocation.id,
    cutOffWeekDay: project.cutOffWeekDay,
    cutOffMonthDay: project.cutOffMonthDay,
    contractualStartDate: project.contractualStartDate,
    contractualEndDate: project.contractualEndDate,
    forecastStartDate: project.forecastStartDate ? project.forecastStartDate : null,
    forecastEndDate: project.forecastEndDate ? project.forecastEndDate : null,
    actualStartDate: project.actualStartDate ? project.actualStartDate : null,
    actualEndDate: project.actualEndDate ? project.actualEndDate : null,
    permissions: updatedPermissions,

  };

  const token = await AuthTokenGenerator.getToken();

  return axios
    .put(appConfig.baseUrl + "/projects", projData, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(_basicErrorHandler);
};

const _basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

export const UserAccessRole = {
  ProjectAdmin: 1,
  InternalUser: 2,
  ExternalUser: 3,
  Viewer: 4
};

export const ProjectsService = {
  getProjects,
  getProjectById,
  addUserToProject,
  updateUserProjectRole,
  removeUserFromProject,
};