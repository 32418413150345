import React from 'react';
import { Providers } from "@microsoft/mgt";
import { graphProvider } from "./components/MSAL/authProvider";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import Login from './components/Login/Login';
import './app.css';

const App = () => {
  Providers.globalProvider = graphProvider;
  
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Login />
    </AppInsightsContext.Provider>
  );
};

export default App;