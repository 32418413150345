import React, { useMemo, useState } from "react";

import StyledTableCell from "../Shared/StyledTableCell/StyledTableCell";
import * as LEVELS from "../constants/levels";
import "./l2Table.css";
import { currency } from "../filters/currency";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

export default function L2Table(props) {
  const [newLevelData, setNewLevelData] = useState({
    name: "",
    code: "",
    basis: 1,
    currency: "",
    cost: "",
    manHour: "",
    register: false,
    expanded: false,
    weighting: "",
    model: props.l1TypeValue === 0 ? 0 : props.l1ModelValue,
    projectWeighting: "",
    errors: {
      name: {
        error: false,
        helperText: "",
      },
      code: {
        error: false,
        helperText: "",
      },
      basis: {
        error: false,
        helperText: "",
      },
      currency: {
        error: false,
        helperText: "",
      },
      cost: {
        error: false,
        helperText: "",
      },
      manHour: {
        error: false,
        helperText: "",
      },
      weighting: {
        error: false,
        helperText: "",
      },
      projectWeighting: {
        error: false,
        helperText: "",
      },
    },
  });
  const [currencyOptions] = useState(props.currencyOptions);

  const handleBasisNameChange = (event) => {
    setNewLevelData({ ...newLevelData, basis: event.target.value });
  };

  const handleCurrencyChange = (event) => {
    setNewLevelData({ ...newLevelData, currency: event.target.value });
  };

  const changeRegisterCheckBox = (l1Index, l2Index) => {
    props.makeL2RegisterFunc(l1Index, l2Index);
  };

  const handleActivityModelChange = (event) => {
    setNewLevelData({ ...newLevelData, model: event.target.value });
  };

  const deleteL2 = (l1Index, l2Index) => {
    props.deleteL2(l1Index, l2Index);
  };

  const createNewL2 = (l1Index) => {
    const { formValid, errorObject } = validateL2Form();

    if (formValid) {
      const newLevelToAdd = { ...newLevelData };

      props.addNewL2(newLevelToAdd, l1Index);

      setNewLevelData({
        name: "",
        code: "",
        basis: 1,
        currency: "",
        cost: "",
        manHour: "",
        register: false,
        expanded: false,
        weighting: "",
        model: props.l1TypeValue === 0 ? 0 : props.l1ModelValue,
        projectWeighting: "",
        errors: {
          name: {
            error: false,
            helperText: "",
          },
          code: {
            error: false,
            helperText: "",
          },
          basis: {
            error: false,
            helperText: "",
          },
          currency: {
            error: false,
            helperText: "",
          },
          cost: {
            error: false,
            helperText: "",
          },
          manHour: {
            error: false,
            helperText: "",
          },
          weighting: {
            error: false,
            helperText: "",
          },
          projectWeighting: {
            error: false,
            helperText: "",
          },
        },
      });

      //props.addNewL2(newLevelToAdd, l1Index);
    } else {
      setNewLevelData({ ...newLevelData, errors: errorObject });
      alert("Please make sure all the inputs are valid.");
    }
  };

  const setNewL2Register = () => {
    var newRegisterValue = !newLevelData.register;
    setNewLevelData({ ...newLevelData, register: newRegisterValue });
  };

  const validateL2Form = () => {
    let formValid = true;
    let errorObject = {
      name: {
        error: false,
        helperText: "",
      },
      code: {
        error: false,
        helperText: "",
      },
      basis: {
        error: false,
        helperText: "",
      },
      currency: {
        error: false,
        helperText: "",
      },
      cost: {
        error: false,
        helperText: "",
      },
      manHour: {
        error: false,
        helperText: "",
      },
      weighting: {
        error: false,
        helperText: "",
      },
      projectWeighting: {
        error: false,
        helperText: "",
      },
    };

    if (!newLevelData.name) {
      formValid = false;
      errorObject.name.error = true;
      errorObject.name.helperText = "Name is required";
    }
    if (!newLevelData.code) {
      formValid = false;
      errorObject.code.error = true;
      errorObject.code.helperText = "WBS Code is required";
    }
    if (!newLevelData.currency) {
      formValid = false;
      errorObject.currency.error = true;
      errorObject.currency.helperText = "Currencies is required";
    }

    if (newLevelData.basis === 0) {
      if (!newLevelData.manHour) {
        formValid = false;
        errorObject.manHour.error = true;
        errorObject.manHour.helperText = "Man Hour is required";
      }
    } else {
      if (!newLevelData.cost) {
        formValid = false;
        errorObject.cost.error = true;
        errorObject.cost.helperText = "Cost is required";
      }
    }
    if (newLevelData.register) {
      if (!newLevelData.weighting) {
        formValid = false;
        errorObject.weighting.error = true;
        errorObject.weighting.helperText = "Weighting is required";
      }
      if (newLevelData.weighting < 0 || newLevelData.weighting > 100) {
        formValid = false;
        errorObject.weighting.error = true;
        errorObject.weighting.helperText = "Weighting has to be 0-100";
      }
    }

    if (props.l1TypeValue === 1 && props.l1ModelValue === 1) {
      if (!newLevelData.projectWeighting) {
        formValid = false;
        errorObject.projectWeighting.error = true;
        errorObject.projectWeighting.helperText =
          "Project Weighting is required";
      }
      if (
        newLevelData.projectWeighting < 0 ||
        newLevelData.projectWeighting > 100
      ) {
        formValid = false;
        errorObject.projectWeighting.error = true;
        errorObject.projectWeighting.helperText =
          "Project Weighting has to be 0-100";
      }
    }

    return { formValid, errorObject };
  };

  const updateLevel = (level) => {
    props.editLevel(level, props.l1Index);
  };

  const _outputRegisterModel = (level) => {
    if (level.model === 0) {
      return "ROC";
    } else if (level.model === 1) {
      return "Quantity";
    } else {
      return "";
    }
  };

  const totalWeight =  props.l2Array.reduce((sum, row) => sum + row.weighting.value, 0)

  const totalProjectWeight = props.l2Array.reduce((sum, row) => sum + row.weighting.project, 0)

  const orderL2 = props.l2Array
    .map((level) => level.order)
    .sort((a, b) => a - b);

  const hasProjectWeighting =
    (props.l1TypeValue === LEVELS.TYPE.Register ||
      props.l1TypeValue === LEVELS.TYPE.Level) &&
    (props.l1ModelValue === LEVELS.Model.Quantity ||
      props.l1ModelValue === LEVELS.Model.RuleOfCredit ||
      props.l1ModelValue === LEVELS.Model.None);

  return (
    <div className="fullWidth l2Table">
      {!props.readOnly && (
        <form
          id="l2form"
          name="l2form"
          autoComplete="off"
          className="verticalPadding noMargin centerHorizontal"
        >
          <Grid container spacing={3}>
            {/* Name */}
            <Grid item lg={2} xs={12} className="">
              <FormControl fullWidth required>
                <TextField
                  id="newName"
                  label="Name"
                  variant="outlined"
                  size="small"
                  value={newLevelData.name}
                  onChange={(event) =>
                    setNewLevelData({
                      ...newLevelData,
                      name: event.target.value,
                    })
                  }
                  error={newLevelData.errors.name.error}
                  helperText={newLevelData.errors.name.helperText}
                  required
                />
              </FormControl>
            </Grid>

            {/* Discipline */}
            <Grid item lg={2} xs={12}>
              <FormControl fullWidth required>
                <TextField
                  id="newCode"
                  label="WBS Code"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  value={newLevelData.code}
                  onChange={(event) =>
                    setNewLevelData({
                      ...newLevelData,
                      code: event.target.value,
                    })
                  }
                  error={newLevelData.errors.code.error}
                  helperText={newLevelData.errors.code.helperText}
                  required
                />
              </FormControl>
            </Grid>

            {/* basis */}
            <Grid item lg={3} xs={12}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="registerName">Basis</InputLabel>
                <Select
                  labelId="register-name-label"
                  id="name"
                  label="Name"
                  margin="dense"
                  value={newLevelData.basis}
                  onChange={handleBasisNameChange}
                  required
                >
                  <MenuItem value={1}>Cost </MenuItem>
                  <MenuItem value={0}> Man-Hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Currency  */}
            <Grid item lg={3} xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="currencyLabel">Currency</InputLabel>
                <Select
                  labelId="currencyLabel"
                  id="Currency"
                  label="Currency"
                  margin="dense"
                  value={newLevelData.currency}
                  onChange={handleCurrencyChange}
                  error={newLevelData.errors.currency.error}
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {currencyOptions.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="redColorHelperText">
                  {newLevelData.errors.currency.helperText}
                </FormHelperText>
              </FormControl>
            </Grid>

            {newLevelData.basis === 1 ? (
              <Grid item lg={3} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    id="newCost"
                    label="Cost"
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{ maxLength: 5 }}
                    value={newLevelData.cost}
                    onChange={(event) =>
                      setNewLevelData({
                        ...newLevelData,
                        cost: event.target.value,
                      })
                    }
                    error={newLevelData.errors.cost.error}
                    helperText={newLevelData.errors.cost.helperText}
                    required
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid item lg={3} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    id="manHour"
                    label="Man Hour"
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{ maxLength: 5 }}
                    value={newLevelData.manHour}
                    onChange={(event) =>
                      setNewLevelData({
                        ...newLevelData,
                        manHour: event.target.value,
                      })
                    }
                    error={newLevelData.errors.manHour.error}
                    helperText={newLevelData.errors.manHour.helperText}
                    required
                  />
                </FormControl>
              </Grid>
            )}

            {/* Register */}
            <Grid item lg={3} xs={12}>
              <FormControl variant="outlined" required>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isRegisterCheckbox"
                      checked={newLevelData.register}
                      onChange={setNewL2Register}
                      disabled={props.l1RegisterValue}
                    />
                  }
                  label="Link a register?"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>

            {newLevelData.register && (
              <>
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="newWeighting"
                      label="Weighting"
                      variant="outlined"
                      size="small"
                      type="number"
                      value={newLevelData.weighting}
                      onChange={(event) => {
                        setNewLevelData({
                          ...newLevelData,
                          weighting: event.target.value,
                        });
                      }}
                      error={newLevelData.errors.weighting.error}
                      helperText={newLevelData.errors.weighting.helperText}
                      required
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="l2_activityModelLabel">
                      Progress System
                    </InputLabel>
                    <Select
                      id="l2_activityModel"
                      label="Progress System"
                      labelId="l2_activityModelLabel"
                      margin="dense"
                      value={newLevelData.model}
                      onChange={handleActivityModelChange}
                      required
                    >
                      <MenuItem value={0}>Rule of Credit</MenuItem>
                      <MenuItem value={1}>Quantity</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            {hasProjectWeighting && (
              <Grid item lg={3} xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    id="projectWeighting"
                    label="Project Weighting"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={newLevelData.projectWeighting}
                    onChange={(event) => {
                      setNewLevelData({
                        ...newLevelData,
                        projectWeighting: event.target.value,
                      });
                    }}
                    error={newLevelData.errors.projectWeighting.error}
                    helperText={newLevelData.errors.projectWeighting.helperText}
                    required
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item lg={3} xs={12} className="">
              <div className="fullWidth">
                <Grid item lg={12} xs={12} className="">
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    onClick={() => createNewL2(props.l1Index)}
                  >
                    <AddCircleOutlineRoundedIcon /> Save
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </form>
      )}

      {props.l2Array.length > 0 ? (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">L2 Name</StyledTableCell>
                <StyledTableCell align="right">WBS Code</StyledTableCell>

                {hasProjectWeighting && (
                  <StyledTableCell align="right">
                    Project Weighting
                  </StyledTableCell>
                )}

                <StyledTableCell align="right">Basis</StyledTableCell>
                <StyledTableCell align="right">Currency</StyledTableCell>
                <StyledTableCell align="right">Cost/Manhour</StyledTableCell>
                <StyledTableCell align="right">Weight</StyledTableCell>
                <StyledTableCell align="right">Register</StyledTableCell>
                <StyledTableCell align="right">Progress System</StyledTableCell>

                {!props.readOnly && (
                  <>
                    <StyledTableCell align="center">Delete</StyledTableCell>
                    <StyledTableCell align="center">Edit</StyledTableCell>
                    <StyledTableCell align="center">Order</StyledTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.l2Array
                .sort((aLevel, bLevel) => {
                  return aLevel.order - bLevel.order;
                })
                .map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell align="left"> {row.name} </TableCell>
                    <TableCell align="right">{row.discipline}</TableCell>

                    {hasProjectWeighting && (
                      <TableCell align="right">
                        {currency(row.weighting.project)}
                      </TableCell>
                    )}

                    <TableCell align="right">
                      {row.basis === 0 ? "Man Hour" : "Cost"}
                    </TableCell>
                    <TableCell align="right">{row.currency.name}</TableCell>
                    <TableCell align="right">
                      {currency(row.basis === 0 ? row.manHour : row.cost)}
                    </TableCell>
                    <TableCell align="right">
                      {currency(row.weighting.value)}
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        checked={row.type === 1}
                        onClick={
                          props.readOnly
                            ? null
                            : () => changeRegisterCheckBox(props.l1Index, index)
                        }
                        name="registerCheckBox"
                        color="primary"
                        disabled={props.l1RegisterValue}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {_outputRegisterModel(row)}
                    </TableCell>

                    {!props.readOnly && (
                      <>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteL2(props.l1Index, index)}
                          >
                            <DeleteOutlineRoundedIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            onClick={() => updateLevel(row)}
                          >
                            <EditRoundedIcon color="primary" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <Select
                            id="l2Order"
                            size="small"
                            value={row.order}
                            label="Order"
                            onChange={(event) => {
                              props.editOrder(
                                { ...row, order: Number(event.target.value) },
                                props.l1Index
                              );
                            }}
                            required
                          >
                            {orderL2.map((levelOrder, index) => (
                              <MenuItem
                                value={levelOrder}
                                selected={levelOrder === row.order}
                              >
                                {index + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="right"></TableCell>
                {hasProjectWeighting && (
                  <TableCell align="right">
                    Total: <strong>{currency(totalProjectWeight)}</strong>{" "}
                  </TableCell>
                )}
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  Total: <strong>{currency(totalWeight)} </strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid item lg={12} xs={12} className="centerHorizontal">
          <Typography variant="subtitle2" align="center">
            There are no L2s for this L1.
          </Typography>
        </Grid>
      )}
    </div>
  );
}
