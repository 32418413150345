import React, { useEffect }from 'react';

import LevelTreeView from '../LevelTreeView/LevelTreeView';
import LevelData from '../LevelData/LevelData';
import './levelsView.css';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';

const LevelsView = (props) => {

  //#region LevelTreeView
  const levels = props.levels;
  const onSelectLevelNode = props.onSelectLevelNode;
  //#endregion

  //#region LevelData
  const selectedLevel = props.selectedLevel;
  const registers = props.registers;
  //#endregion

  const [selectedActivityId, setSelectedActivityId] = useState(props.selectedActivityId)

  useEffect(() => {
    setSelectedActivityId(props.selectedActivityId)
  }, [props.selectedActivityId])

  return (
    <div className="LevelsSelectionTile spaceEvenly fullWidth marginBottom20">
      <Grid item xs={10}>
        <Paper elevation={2} style={{ height: '100%' }}>

          <Typography variant="h5" component="h5" className="padding20">
            WBS Levels
          </Typography>

          <Grid id="levels-grid" container spacing={3} className="padding20">
            {levels.length > 0 ?
              <>
                <Grid item lg={4} xs={12} className="tree-panel">
                  <LevelTreeView
                    levels={levels}
                    onSelectLevelNode={onSelectLevelNode}
                    selectedItem = {selectedActivityId}
                  />
                </Grid>

                <Grid item lg={8} xs={12}>
                  <LevelData
                    selectedLevel={selectedLevel}
                    registers={registers}
                  />
                </Grid>
              </>
              :
              <Grid item xs={12} style={{ textAlign: "center" }}>
                There are no Levels/Activities for this Project.
              </Grid>
            }
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default LevelsView;