import React, { useState, useEffect } from "react";

import { UsersService } from "../../Service/UsersService";
import { ActivitiesService } from "../../Service/ActivitiesService";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import BackgroundImage from "../../assets/background.png";
import { ReactComponent as SLB_Logo } from "../../assets/SLBlogo.svg";
import ActivitiesSelector from "./ActivitiesSelectors/ActivitiesSelectors";
import ActivitiesAccordion from "./ActivitiesAccordion/ActivitiesAccordion";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    backgroundSize: "cover",
    backgroundImage: `url(${BackgroundImage})`,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    overflow: "auto",
    paddingTop: "30px",
    position: "relative",
    width: "100%",
  },
  footer: {
    margin: "34px",
    width: "auto",
  },
}));

const MyActivities = (props) => {
  const classes = useStyles();

  const [showSpinner, setShowSpinner] = useState(false);
  const [projects, setProjects] = useState([]);

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRegister, setSelectedRegister] = useState(null);

  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [alertProps, setAlertProps] = useState({
    message: "",
    severity: "",
  });

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackOpen(false);
  };

  const exitSnackbar = () => {
    setAlertProps({
      message: "",
      severity: "",
    });
  };

  useEffect(() => {
    _initialize();
  }, []);

  const _initialize = async () => {
    setShowSpinner(true);
    props.setPageTitle("Activities");
    props.setViewMode("user");

    const userActivitiesPayload = await UsersService.getUserActivities();
    const fetchedProjects = userActivitiesPayload.projects;
    setProjects(fetchedProjects);
    setShowSpinner(false);
  };

  const onProjectChange = (newProject) => {
    setSelectedProject(newProject);
    setSelectedRegister(null);
  };

  const onRegisterChange = (newRegister) => {
    setSelectedRegister(newRegister);
  };

  const handleSaveActivityProgress = async (activityStep) => {
    setShowSpinner(true);
    const activityToUpdate = selectedRegister.activities.find(
      (activity) => activity.id === activityStep.activityId
    );
    try {
      const updatedProgressData = {
        stepId: activityStep.stepId,
        forecast: activityStep.forecastDate,
        actual: activityStep.actualDate,
      };
      const { activity } = await ActivitiesService.updateActivityMyProgress(
        selectedProject.id,
        activityToUpdate.levelId,
        activityToUpdate.id,
        updatedProgressData
      )

      const activityIndex = selectedRegister.activities.findIndex(
        (a) => a.id === activity.id
      );
      if (isActivityComplete(activity)) {
        selectedRegister.activities.splice(activityIndex, 1);
      } else {
        const step = activity.configuration.ruleOfCreditSteps.find(
          (s) => s.stepId === updatedProgressData.stepId
        );
        const stepToUpdate = selectedRegister.activities[
          activityIndex
        ].configuration.ruleOfCreditSteps.find((s) => s.id === step.id);
        stepToUpdate.actualDate = step.actualDate;
        stepToUpdate.plannedDate = step.plannedDate;
        stepToUpdate.forecastDate = step.forecastDate;
        stepToUpdate.status = step.status;
      }
      showSuccessSnack();
    } catch (error) {
      console.error(error.data.detail);
      showErrorSnack(error.data.detail);
    } finally {
      setShowSpinner(false);
    }
  };

  const isActivityComplete = (activity) => activity.configuration.ruleOfCreditSteps.every(step => step.actualDate);

  const showSuccessSnack = () => {
    setAlertProps({
      message: "Activity updated successfully",
      severity: "success",
    });
    setIsSnackOpen(true);
  };

  const showErrorSnack = (message) => {
    setAlertProps({
      message: message,
      severity: "error",
    });
    setIsSnackOpen(true);
  };

  const saveQuantityProgress = async (activityToUpdate, updatedProgress) => {
    setShowSpinner(true);

    try {
      const updatedProgressData = {
        forecastStart: updatedProgress.forecastStartDate,
        forecastFinish: updatedProgress.forecastFinishDate,
        actualStart: updatedProgress.actualStartDate,
        actualFinish: updatedProgress.actualFinishDate,
        plannedQuantity: updatedProgress.plannedQuantity,
        completedQuantity: updatedProgress.completedQuantity,
      };

      const { activity } = await ActivitiesService.updateActivityMyProgress(
        selectedProject.id,
        activityToUpdate.levelId,
        activityToUpdate.id,
        updatedProgressData
      );
      activity.levelId = activityToUpdate.levelId;
      activity.path = activityToUpdate.path;
      
      const activityIndex = selectedRegister.activities.findIndex(
        (a) => a.id === activity.id
      );
      if (activity.configuration.quantity.actualFinishDate) {
        selectedRegister.activities.splice(activityIndex, 1);
      } else {
        selectedRegister.activities[activityIndex] = activity;
      }
      showSuccessSnack();
    } catch (error) {
      showErrorSnack(error.data.detail);
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <div id="content" className={classes.content}>
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          {projects.length > 0 && (
            <ActivitiesSelector
              projects={projects}
              onProjectChange={onProjectChange}
              onRegisterChange={onRegisterChange}
            ></ActivitiesSelector>
          )}

          <div style={{ marginTop: "30px" }}></div>

          {selectedRegister && (
            <ActivitiesAccordion
              activities={selectedRegister.activities}
              onSaveActivityProgress={handleSaveActivityProgress}
              saveQuantityProgress={saveQuantityProgress}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar
        key="alert"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSnackOpen}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        onExited={exitSnackbar}
      >
        <Alert elevation={6} variant="filled" severity={alertProps.severity}>
          {alertProps.message}
        </Alert>
      </Snackbar>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.footer}
      >
        <SLB_Logo />
      </Grid>
    </div>
  );
};

export default MyActivities;
