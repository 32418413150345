import React from 'react';

import './newROCForm.css';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

const NewROCForm = (props) => {

  // Props State
  const newROC = props.newROC;
  const newRocRegister = props.newRocRegister;
  const newStep = props.newStep;
  const registers = props.registers;

  // Props Functions
  const onChangeNewROCName = props.onChangeNewROCName;
  const onChangeNewRocRegister = props.onChangeNewRocRegister;
  const onChangeNewStepName = props.onChangeNewStepName;
  const onChangeNewStepPercent = props.onChangeNewStepPercent;

  const addNewActivityStep = props.addNewActivityStep;
  const editActivityStep = props.editActivityStep;
  const removeNewActivityStep = props.removeNewActivityStep;
  const addRuleOfCredit = props.addRuleOfCredit;

  return (
    <div className="NewROCForm fullWidth spaceEvenly">
      <Grid item xs={10}>
        <Paper className="fullWidth" elevation={2}>

          <Typography variant="h5" component="h5" className="padding20">
            Add ROC
          </Typography>

          <form id="ruleOfCreditsForm" name="ruleOfCreditsForm" autoComplete="off" className="padding20">
            <Grid container justify="space-around" spacing={3}>
              {/* Name */}
              <Grid item lg={4} xs={12}>
                <FormControl variant="outlined" fullWidth required>
                  <TextField
                    id="rocName"
                    label="Rule of Credit Name"
                    variant="outlined"
                    size="small"
                    value={newROC.name}
                    onChange={onChangeNewROCName}
                    inputProps={{ maxLength: 100 }}
                    required />
                </FormControl>
              </Grid>

              {/* Register */}
              <Grid item lg={4} xs={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="new-register-label" >Register</InputLabel>
                  <Select
                    id="new-register"
                    labelId="new-register-label"
                    label="Register"
                    margin="dense"
                    required
                    value={newRocRegister}
                    onChange={onChangeNewRocRegister}
                    disabled={registers.length === 0}
                  >
                  {registers.map(register =>
                    <MenuItem key={register.id} value={register}>
                      {register.name}
                    </MenuItem>
                  )}
                  </Select>
                  {registers.length === 0 &&
                    <FormHelperText>
                      No Rule of Credit based Registers defined in current project
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>

              {/* Add ROC Button */}
              <Grid item lg={3} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  startIcon={<SaveRoundedIcon/>}
                  onClick={addRuleOfCredit}
                >
                  Save Rule of Credit
                </Button>
              </Grid>

              {/* New Step */}
              <Grid container item lg={8} xs={12} justify="space-evenly" alignItems="center">

                {/* Name */}
                <Grid item xs={4}>
                  <FormControl variant="outlined" fullWidth required>
                    <TextField
                      id="new-step-name"
                      label="New Step Name"
                      variant="outlined"
                      size="small"
                      value={newStep.name}
                      onChange={onChangeNewStepName}
                      inputProps={{ maxLength: 100 }}
                      required
                    />
                  </FormControl>
                </Grid>

                {/* Percent */}
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth required>
                    <TextField
                      id="new-step-percent"
                      label="New Step Percent"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={newStep.percent}
                      onChange={onChangeNewStepPercent}
                      inputProps={{ min: 0, max: 100 }}
                      required
                    />
                  </FormControl>
                </Grid>

                {/* Add New Step */}
                <Grid item xs={1} style={{textAlign: "center"}}>
                  <IconButton aria-label="add-new-step" onClick={addNewActivityStep}>
                    <AddCircleOutlineRoundedIcon  color="primary" />
                  </IconButton>
                </Grid>
              </Grid>


              {/* Actvity Steps */}
              {newROC.activitySteps.map((activity, index) =>
                <Grid key={index} container item lg={8} xs={12} justify="space-evenly" alignItems="center">

                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth >
                      <TextField
                        id={"new-step-name-$" + index}
                        label="Name"
                        variant="outlined"
                        size="small"
                        value={activity.name}
                        onChange={event => {
                          editActivityStep(index, 'name', event.target.value)
                        }}
                        inputProps={{ maxLength: 100 }}
                        required />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        id={"new-step-percent-$" + index}
                        label="Percent"
                        type="number"
                        size="small"
                        variant="outlined"
                        value={activity.percent}
                        onChange={event => editActivityStep(index, 'percent', event.target.value)}
                        inputProps={{ min: 0, max: 100 }}
                        InputLabelProps={{ shrink: true }}
                        required
                      >
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={1} style={{textAlign: "center"}}>
                    <IconButton aria-label="delete-new-step" onClick={(event) => removeNewActivityStep(event, index)}>
                      <RemoveCircleOutlineRoundedIcon  color="secondary" />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

            </Grid>
          </form>
        </Paper>
      </Grid>
    </div>
  );
};

export default NewROCForm;