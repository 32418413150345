import React, { useState, useEffect, useContext } from "react";

import QuantityActivityRow from "./QuantityActivityRow/QuantityActivityRow";
import StyledTableCell from "../../Shared/StyledTableCell/StyledTableCell";
import UserContext from "../context/userContext";
import * as PROJECTS from "../../constants/projects";
import "./quantityActivitiesTable.css";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import TableCell from "@material-ui/core/TableCell";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import IconButton from "@material-ui/core/IconButton";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { makeStyles } from "@material-ui/core/styles";
import { DATE_FORMAT } from "../../constants/strings";

const useDatePickerStyles = makeStyles({
  root: {
    "& > div": {
      marginTop: "4px",
      marginBottom: "4px",
    },
  },
});

const CustomDatePicker = (props) => {
  // Custom Styles
  const datePickerClasses = useDatePickerStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={datePickerClasses.root}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id={props.id}
          disableToolbar
          variant="inline"
          format={DATE_FORMAT}
          margin="normal"
          label={props.label}
          value={props.value}
          minDate={props.minDate}
          onChange={props.onChange}
          autoOk={true}
          KeyboardButtonProps={{ "aria-label": "change date" }}
          readOnly={props.readOnly}
          InputProps={{ readOnly: props.readOnly }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

const QuantityActivitiesTable = (props) => {
  // Context
  const userContext = useContext(UserContext);

  // Props state
  const levelId = props.levelId;
  const activities = props.activities;
  const attributes = props.attributes;
  const categories = props.categories;
  const cutOffDate = props.cutOffDate;

  const onChangeCutOffDate = props.onChangeCutOffDate;
  const onUpdatePlannedQuantity = props.onUpdatePlannedQuantity;
  const onUpdateCompletedQuantity = props.onUpdateCompletedQuantity;
  const onUpdateForecastStartDate = props.onUpdateForecastStartDate;
  const onUpdateForecastFinishDate = props.onUpdateForecastFinishDate;
  const onUpdateActualStartDate = props.onUpdateActualStartDate;
  const onUpdateActualFinishDate = props.onUpdateActualFinishDate;
  const saveActivities = props.saveActivities;
  const filterByActivitySearch = props.filterByActivitySearch;

  //#region Pagination Settings
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const activitiesTotalCount = activities.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage =
      event.target.value === "All"
        ? activitiesTotalCount
        : parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  //#endregion

  //#region  Filter Handlers
  const [baseFilterObj, setBaseFilterObj] = useState({});
  const [attributesFilterObj, setAttributesFilterObj] = useState({});
  const [dateFilterObj, setDateFilterObj] = useState({
    planStart: null,
    planFinish: null,
    forecastStart: null,
    forecastFinish: null,
    actualStart: null,
    actualFinish: null,
  });
  const [filteredActivities, setFilteredActivities] = useState([])

  const updateBaseFilter = (property, newValue) => {
    const updatedBaseFilterObj = { ...baseFilterObj };
    updatedBaseFilterObj[property] = { ...updatedBaseFilterObj[property] };
    updatedBaseFilterObj[property].value = newValue;
    setBaseFilterObj(updatedBaseFilterObj);
  };

  const updateAttributesFilter = (attributeId, newValue) => {
    const updatedFilters = { ...attributesFilterObj };
    updatedFilters[attributeId] = { ...updatedFilters[attributeId] };
    updatedFilters[attributeId].value = newValue;
    setAttributesFilterObj(updatedFilters);
  };

  // Filters options will change when activities array changes
  useEffect(() => {
    _buildFilters();
  }, [activities]);

  useEffect(() => {
    if (baseFilterObj.id) {
      setFilteredActivities(filterActivities(activities))
    }
  }, [baseFilterObj]);

  useEffect(() => {
    if (filterByActivitySearch && baseFilterObj.id) {
      updateBaseFilter("id", filterByActivitySearch)
    }
  }, [filterByActivitySearch]);

  // Filters setup
  const _buildFilters = () => {
    // Initialize Base Filter
    const initBaseFilterObj = {
      id: {
        value: filterByActivitySearch ? filterByActivitySearch : "",
        options: [],
      },
      description: {
        value: "",
        options: [],
      },
      costValue: {
        value: "",
        options: [],
      },
      weighting: {
        value: "",
        options: [],
      },
      responsibleParty: {
        value: "",
        options: [],
      },
      primaveraTaskId: {
        value: "",
        options: [],
      },
    };
    // Initialize Attributes Filter
    const initAttributeFilterObj = {};

    for (const activity of activities) {
      // Base
      initBaseFilterObj.id.options.push(activity.id);
      initBaseFilterObj.description.options.push(activity.description);
      initBaseFilterObj.costValue.options.push(activity.costValue);
      initBaseFilterObj.weighting.options.push(activity.weighting);
      initBaseFilterObj.responsibleParty.options.push(
        activity.responsibleParty
      );
      initBaseFilterObj.primaveraTaskId.options.push(activity.primaveraTaskId);

      // Attributes Filter
      for (const def of activity.definitions) {
        const attId = def.attributeId;
        const value = def.value;

        if (!initAttributeFilterObj[attId]) {
          initAttributeFilterObj[attId] = { value: "", options: [] };
        }

        initAttributeFilterObj[attId].options.push(value);
      }
    }

    // Remove Duplicates & Sort
    for (const key in initBaseFilterObj) {
      initBaseFilterObj[key].options = [
        ...new Set(initBaseFilterObj[key].options),
      ];

      if (key === "id") {
        continue;
      } else if (key === "costValue" || key === "weighting") {
        initBaseFilterObj[key].options.sort((a, b) => Number(a) - Number(b));
      } else {
        initBaseFilterObj[key].options.sort();
      }
    }

    for (const key in initAttributeFilterObj) {
      initAttributeFilterObj[key].options = [
        ...new Set(initAttributeFilterObj[key].options),
      ];
      initAttributeFilterObj[key].options.sort();
    }

    setBaseFilterObj(initBaseFilterObj);
    setAttributesFilterObj(initAttributeFilterObj);
  };

  const _applyFilters = (activity) => {
    let show = true;

    if (
      baseFilterObj.id &&
      baseFilterObj.id.value !== "" &&
      activity.id !== baseFilterObj.id.value
    ) {
      return false;
    }

    if (
      baseFilterObj.description &&
      baseFilterObj.description.value !== "" &&
      activity.description !== baseFilterObj.description.value
    ) {
      return false;
    }

    if (
      baseFilterObj.costValue &&
      baseFilterObj.costValue.value !== "" &&
      activity.costValue !== baseFilterObj.costValue.value
    ) {
      return false;
    }

    if (
      baseFilterObj.weighting &&
      baseFilterObj.weighting.value !== "" &&
      activity.weighting !== baseFilterObj.weighting.value
    ) {
      return false;
    }

    if (
      baseFilterObj.responsibleParty &&
      baseFilterObj.responsibleParty.value !== "" &&
      activity.responsibleParty !== baseFilterObj.responsibleParty.value
    ) {
      return false;
    }

    if (
      baseFilterObj.primaveraTaskId &&
      baseFilterObj.primaveraTaskId.value !== "" &&
      activity.primaveraTaskId !== baseFilterObj.primaveraTaskId.value
    ) {
      return false;
    }

    for (const attDef of activity.definitions) {
      const attributeId = attDef.attributeId;
      const attributeValue = attDef.value;

      if (
        attributesFilterObj[attributeId] &&
        attributesFilterObj[attributeId].value !== "" &&
        attributeValue !== attributesFilterObj[attributeId].value
      ) {
        return false;
      }
    }

    return show;
  };

  const _applyCutOffDateFilter = (activity) => {
    let show = false;

    // Ignore cutOffDate filter when date is null
    if (!cutOffDate) {
      return true;
    }

    // Filter out activities w/o configuration or quantity
    // if (!activity.configuration || !activity.configuration.quantity) {
    //   return show;
    // }

    const quantity = activity.configuration.quantity;
    if (
      (quantity.planStartDate &&
        _isDateOnOrBeforeCutOffDate(quantity.planStartDate, cutOffDate)) ||
      (quantity.planFinishDate &&
        _isDateOnOrBeforeCutOffDate(quantity.planFinishDate, cutOffDate)) ||
      (quantity.forecastStartDate &&
        _isDateOnOrBeforeCutOffDate(quantity.forecastStartDate, cutOffDate)) ||
      (quantity.forecastFinishDate &&
        _isDateOnOrBeforeCutOffDate(quantity.forecastFinishDate, cutOffDate)) ||
      (quantity.actualStartDate &&
        _isDateOnOrBeforeCutOffDate(quantity.actualStartDate, cutOffDate)) ||
      (quantity.actualFinishDate &&
        _isDateOnOrBeforeCutOffDate(quantity.actualFinishDate, cutOffDate))
    ) {
      show = true;
    }

    return show;
  };

  const _applyHeaderDateFilters = (activity) => {
    const quantity = activity.configuration.quantity;

    let showPlanStart = false;
    let showPlanFinish = false;
    let showForecastStart = false;
    let showForecastFinish = false;
    let showActualStart = false;
    let showActualFinish = false;

    if (!dateFilterObj.planStart) {
      showPlanStart = true;
    } else if (
      quantity.planStartDate &&
      _isDateOnOrBeforeCutOffDate(
        quantity.planStartDate,
        dateFilterObj.planStart
      )
    ) {
      showPlanStart = true;
    }

    if (!dateFilterObj.planFinish) {
      showPlanFinish = true;
    } else if (
      quantity.planFinishDate &&
      _isDateOnOrBeforeCutOffDate(
        quantity.planFinishDate,
        dateFilterObj.planFinish
      )
    ) {
      showPlanFinish = true;
    }

    if (!dateFilterObj.forecastStart) {
      showForecastStart = true;
    } else if (
      quantity.forecastStartDate &&
      _isDateOnOrBeforeCutOffDate(
        quantity.forecastStartDate,
        dateFilterObj.forecastStart
      )
    ) {
      showForecastStart = true;
    }

    if (!dateFilterObj.forecastFinish) {
      showForecastFinish = true;
    } else if (
      quantity.forecastFinishDate &&
      _isDateOnOrBeforeCutOffDate(
        quantity.forecastFinishDate,
        dateFilterObj.forecastFinish
      )
    ) {
      showForecastFinish = true;
    }

    if (!dateFilterObj.actualStart) {
      showActualStart = true;
    } else if (
      quantity.actualStartDate &&
      _isDateOnOrBeforeCutOffDate(
        quantity.actualStartDate,
        dateFilterObj.actualStart
      )
    ) {
      showActualStart = true;
    }

    if (!dateFilterObj.actualFinish) {
      showActualFinish = true;
    } else if (
      quantity.actualFinishDate &&
      _isDateOnOrBeforeCutOffDate(
        quantity.actualFinishDate,
        dateFilterObj.actualFinish
      )
    ) {
      showActualFinish = true;
    }

    return (
      showPlanStart &&
      showPlanFinish &&
      showForecastStart &&
      showForecastFinish &&
      showActualStart &&
      showActualFinish
    );
  };

  const _isDateOnOrBeforeCutOffDate = (aDate, cutOffDate) => {
    let convertedDate = new Date(aDate);

    if (aDate instanceof Date) {
      convertedDate.setHours(0, 0, 0, 0);
    }

    return convertedDate <= cutOffDate;
  };
  //#endregion

  const filterActivities = (activities) => {
    return activities
      .filter((activity) => {
        return activity.configuration && activity.configuration.quantity;
      })
      .filter((activity) => {
        return (
          _applyCutOffDateFilter(activity) && _applyHeaderDateFilters(activity)
        );
      })
      .filter(_applyFilters);
  };

  const _userHasActivityWriteAccess = (anActivity) => {
    return (
      userContext.role === PROJECTS.Roles.ProjectAdmin ||
      (userContext.role === PROJECTS.Roles.InternalUser &&
        userContext.alias === anActivity.responsibleParty)
    );
  };

  return (
    <Grid
      id="activities-grid"
      className="QuantityActivitiesTable padding20"
      container
      spacing={3}
      justify="flex-end"
    >
      <Grid item xs={12} lg={3}>
        <FormControl variant="outlined" fullWidth>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id={"activity-date-filter"}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="dense"
              label={"Cutoff Date"}
              value={cutOffDate}
              onChange={(newDate) => {
                const dateOnly = new Date(
                  newDate.getFullYear(),
                  newDate.getMonth(),
                  newDate.getDate()
                ); // Clear time

                onChangeCutOffDate(dateOnly);
              }}
              autoOk={true}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onChangeCutOffDate(null);
                    }}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={{
                position: "start",
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell className="stickyColumn description">
                    Description
                  </StyledTableCell>
                  <StyledTableCell>Cost Value</StyledTableCell>
                  <StyledTableCell>Weighting</StyledTableCell>
                  <StyledTableCell>Responsible Party</StyledTableCell>
                  <StyledTableCell>Primavera Task ID</StyledTableCell>
                  <StyledTableCell>Project Weighting</StyledTableCell>
                  <StyledTableCell>Unit of Measurement</StyledTableCell>
                  <StyledTableCell>Unit Value</StyledTableCell>
                  <StyledTableCell>Quantity</StyledTableCell>
                  {attributes.map((attribute) => (
                    <StyledTableCell key={attribute.id}>
                      {attribute.name}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell className="editDatePickerWidth">
                    Plan Start Date
                  </StyledTableCell>
                  <StyledTableCell className="editDatePickerWidth">
                    Plan Finish Date
                  </StyledTableCell>
                  <StyledTableCell className="editDatePickerWidth">
                    Forecast Start Date
                  </StyledTableCell>
                  <StyledTableCell className="editDatePickerWidth">
                    Forecast Finish Date
                  </StyledTableCell>
                  <StyledTableCell className="editDatePickerWidth">
                    Actual Start Date
                  </StyledTableCell>
                  <StyledTableCell className="editDatePickerWidth">
                    Actual Finish Date
                  </StyledTableCell>
                  <StyledTableCell className="editQuantityMinWidth">
                    Planned Quantity
                  </StyledTableCell>
                  <StyledTableCell className="editQuantityMinWidth">
                    Completed Quantity
                  </StyledTableCell>
                  <StyledTableCell className="description">
                    Primary Category
                  </StyledTableCell>
                  <StyledTableCell className="description">
                    Secondary Category
                  </StyledTableCell>
                  <StyledTableCell className="">
                    Secondary Unit of Measurement
                  </StyledTableCell>
                  <StyledTableCell className="editQuantityMinWidth">
                    Secondary Planned Quantity
                  </StyledTableCell>
                  <StyledTableCell className="editQuantityMinWidth">
                    Secondary Completed Quantity
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  {[
                    "id",
                    "description",
                    "costValue",
                    "weighting",
                    "responsibleParty",
                    "primaveraTaskId",
                  ].map((field) => {
                    const filterObj = baseFilterObj[field];

                    if (!filterObj) {
                      return <TableCell key={field}></TableCell>;
                    }

                    const classNames =
                      field === "description"
                        ? "stickyColumn whiteBackground filterColumn"
                        : "filterColumn";

                    return (
                      <TableCell key={field} className={classNames}>
                        <FormControl>
                          <Select
                            value={filterObj.value}
                            onChange={(event) =>
                              updateBaseFilter(field, event.target.value)
                            }
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {filterObj.options.map((op) => (
                              <MenuItem key={op} value={op}>
                                {op}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    );
                  })}

                  <TableCell colSpan={4} />

                  {attributes.map((attribute) => {
                    const filterObj = attributesFilterObj[attribute.id];

                    if (!filterObj) {
                      return <TableCell key={attribute.id} />;
                    }

                    return (
                      <TableCell key={attribute.id}>
                        <FormControl>
                          <Select
                            value={filterObj.value}
                            onChange={(event) =>
                              updateAttributesFilter(
                                attribute.id,
                                event.target.value
                              )
                            }
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {filterObj.options.map((op) => (
                              <MenuItem key={op} value={op}>
                                {op}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <CustomDatePicker
                      id={"planStartFilter"}
                      label={null}
                      value={dateFilterObj.planStart}
                      onChange={(newDate) => {
                        const updatedDateFilterObj = { ...dateFilterObj };
                        updatedDateFilterObj.planStart = newDate;
                        setDateFilterObj(updatedDateFilterObj);
                      }}
                      readOnly={false}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomDatePicker
                      id={"planFinishFilter"}
                      label={null}
                      value={dateFilterObj.planFinish}
                      onChange={(newDate) => {
                        const updatedDateFilterObj = { ...dateFilterObj };
                        updatedDateFilterObj.planFinish = newDate;
                        setDateFilterObj(updatedDateFilterObj);
                      }}
                      readOnly={false}
                    />
                  </TableCell>

                  <TableCell>
                    <CustomDatePicker
                      id={"forecastStartFilter"}
                      label={null}
                      value={dateFilterObj.forecastStart}
                      onChange={(newDate) => {
                        const updatedDateFilterObj = { ...dateFilterObj };
                        updatedDateFilterObj.forecastStart = newDate;
                        setDateFilterObj(updatedDateFilterObj);
                      }}
                      readOnly={false}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomDatePicker
                      id={"forecastFinishFilter"}
                      label={null}
                      value={dateFilterObj.forecastFinish}
                      onChange={(newDate) => {
                        const updatedDateFilterObj = { ...dateFilterObj };
                        updatedDateFilterObj.forecastFinish = newDate;
                        setDateFilterObj(updatedDateFilterObj);
                      }}
                      readOnly={false}
                    />
                  </TableCell>

                  <TableCell>
                    <CustomDatePicker
                      id={"actualStartFilter"}
                      label={null}
                      value={dateFilterObj.actualStart}
                      onChange={(newDate) => {
                        const updatedDateFilterObj = { ...dateFilterObj };
                        updatedDateFilterObj.actualStart = newDate;
                        setDateFilterObj(updatedDateFilterObj);
                      }}
                      readOnly={false}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomDatePicker
                      id={"actualFinishFilter"}
                      label={null}
                      value={dateFilterObj.actualFinish}
                      onChange={(newDate) => {
                        const updatedDateFilterObj = { ...dateFilterObj };
                        updatedDateFilterObj.actualFinish = newDate;
                        setDateFilterObj(updatedDateFilterObj);
                      }}
                      readOnly={false}
                    />
                  </TableCell>

                  <TableCell colSpan={2} />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredActivities
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((activity) => {
                    const activityWritePermission =
                      _userHasActivityWriteAccess(activity);
                    return (
                      <QuantityActivityRow
                        key={activity.id}
                        levelId={levelId}
                        activity={activity}
                        attributes={attributes}
                        categories={categories}
                        readOnly={!activityWritePermission}
                        onUpdatePlannedQuantity={onUpdatePlannedQuantity}
                        onUpdateCompletedQuantity={onUpdateCompletedQuantity}
                        onUpdateForecastStartDate={onUpdateForecastStartDate}
                        onUpdateForecastFinishDate={onUpdateForecastFinishDate}
                        onUpdateActualStartDate={onUpdateActualStartDate}
                        onUpdateActualFinishDate={onUpdateActualFinishDate}
                        onUpdateSecondaryCategory={
                          props.onUpdateSecondaryCategory
                        }
                        onUpdatePrimaryCategory={props.onUpdatePrimaryCategory}
                        onUpdateSecondaryPlannedQuantity={
                          props.onUpdateSecondaryPlannedQuantity
                        }
                        onUpdateSecondaryCompletedQuantity={
                          props.onUpdateSecondaryCompletedQuantity
                        }
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[3, 5, 10, 20, "All"]}
            component="div"
            count={filteredActivities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="flex-end" spacing={3}>
          <Grid item lg={3} xs={12}>
            <Button
              fullWidth={true}
              variant="contained"
              color="primary"
              onClick={saveActivities}
              startIcon={<SaveRoundedIcon />}
            >
              Save Activities
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuantityActivitiesTable;
