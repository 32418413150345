import React, { useState } from 'react';

import StyledTableCell from '../../Shared/StyledTableCell/StyledTableCell';
import './levelSummary.css';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { currency } from '../../filters/currency';

import { Model, TYPE } from '../../constants/levels';

const LevelSummary = (props) => {

  // Pagination Settings
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = (event.target.value === 'All')
      ? levelsAndActivitiesTotalCount
      : parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  // Extract child levels and child activities
  const level = props.level;

  const getChildren = (level) => {
    const aLevel = [{
      id: level.id, 
      name: level.name,
      type: level.type,
      number: level.number,
      description: `L${level.number}`,
      weight: level.weight,
      progress: level.progress,
      model: level.model
    }];

    if (level.activities) {
      return aLevel.concat(
        level.activities.map(a => ({
          id: a.id,
          name: a.description,
          description: 'Activity',
          weight: a.weight,
          progress: a.progress
        }))
      );
    }

    if (level.children) {
      const children = level.children.map(lvl => getChildren(lvl)).flat();
      return aLevel.concat(children);
    }

    return aLevel;
  };

  const adjustLevelValues = (currentLevel, value) => {
    const weight = currentLevel.weight.project;
    return currentLevel.type === TYPE.Level && currentLevel.number > 1 ? currency((value / weight)*100)  : currency(value);
  }

  const levelsAndActivities = getChildren(level);
  const levelsAndActivitiesTotalCount = levelsAndActivities.length;

  return (
    <Grid className="LevelSummary padding20" container spacing={3} justify="flex-start">

      <Grid item xs={12}>
        <Paper elevation={2}>
          <TableContainer className="level-summary-table-container">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell className="id">ID</StyledTableCell>
                  <StyledTableCell className="name">Name</StyledTableCell>
                  <StyledTableCell className="type">Type</StyledTableCell>
                  <StyledTableCell className="plan">Plan</StyledTableCell>
                  <StyledTableCell className="forecast">Forecast</StyledTableCell>
                  <StyledTableCell className="actual">Actual</StyledTableCell>
                  <StyledTableCell className="weighted-plan">Weighted Plan</StyledTableCell>
                  <StyledTableCell className="weighted-forecast">Weighted Forecast</StyledTableCell>
                  <StyledTableCell className="weighted-actual">Weighted Actual</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {levelsAndActivities
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) =>
                    <TableRow key={`${item.type}_${item.id}`}>
                      <StyledTableCell>{item.id}</StyledTableCell>
                      <StyledTableCell>{item.name}</StyledTableCell>
                      <StyledTableCell>{item.description}</StyledTableCell>
                      <StyledTableCell>{item.progress ? adjustLevelValues(item, item.progress.plan) + '%' : '-'}</StyledTableCell>
                      <StyledTableCell>{item.progress ? adjustLevelValues(item, item.progress.forecast) + '%' : '-'}</StyledTableCell>
                      <StyledTableCell>{item.progress ? adjustLevelValues(item, item.progress.actual) + '%' : '-'}</StyledTableCell>
                      <StyledTableCell>{currency(item.weight.plan)}%</StyledTableCell>
                      <StyledTableCell>{currency(item.weight.forecast)}%</StyledTableCell>
                      <StyledTableCell>{currency(item.weight.actual)}%</StyledTableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 'All']}
            component="div"
            count={levelsAndActivities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>

    </Grid>
  );
};

export default LevelSummary;