import React from 'react';

const userContext = React.createContext({
  id: 0,
  alias: "",
  name: "",
  admin: false,
  role: 0,
  projectManager: false
});

export default userContext;