import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

const replaceReport = (projectId, reportType) => {
  if (reportType === 2) {
    return Promise.all([
        updateReport(projectId, { jobReportType: 0 }),
        updateReport(projectId, { jobReportType: 1 })
      ])
  } else {
    return updateReport(projectId, { jobReportType: reportType });
  }
};

const updateReport = async (projectId, jobReportType) => {
  const Authorization = await AuthTokenGenerator.getToken();
  return axios
    .put(`${appConfig.baseUrl}/projects/${projectId}/reports`, jobReportType, {
      headers: {
        Authorization
      },
    })
    .then(response => response.data)
    .catch(basicErrorHandler);
};

const getLastReports = async (projectId) => {
  const Authorization = await AuthTokenGenerator.getToken();
  return axios
    .get(`${appConfig.baseUrl}/projects/${projectId}/reports`, {
      headers: {
        Authorization
      },
      params: {
        latest: true
      }
    })
    .then(response => response.data.payload.reports)
    .catch(basicErrorHandler);
};

export const ReportsService = {
  replaceReport,
  getLastReports
}