import React, { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SaveIcon from '@material-ui/icons/Save';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const UserRow = (props) => {
  // Props State
  const user = props.user;
  const projects = props.projects;

  // Componenet State
  const [open, setOpen] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const classes = useRowStyles();

  // Event Handlers
  const onSelectProjects = (event) => {
    const updatedProjects = event.target.value;
    setSelectedProjects(updatedProjects);
  };

  const onClickAssignButton = () => {
    setSelectedProjects([]);
    props.onAddUserToProjects(user, [...selectedProjects]);
  };

  // Private Helper Functions
  const _getProjectName = (projectPermission) => {
    if (projectPermission.name) {
      return projectPermission.name;
    }

    const matchingProject = projects.find(p => p.id === projectPermission.projectId);
    return (matchingProject) ? matchingProject.name : '';
  } 

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {user.id}
        </TableCell>
        <TableCell >
          {user.name}
        </TableCell>
        <TableCell >
          {user.alias}
        </TableCell>
        <TableCell >
          <Checkbox
            color="primary"
            checked={user.admin}
            onChange={(event) => props.onSysAdminChecked(user.id, event.target.checked)}
          />
        </TableCell>
        <TableCell >
          <IconButton aria-label="delete" onClick={() => props.onDeleteUser(user.id)}>
            <DeleteOutlineRoundedIcon color="secondary" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" gutterBottom component="div">Assign User To Projects</Typography>
              <Grid container item xs={12} style={{ margin: '40px' }}>
                <Grid container item spacing={2} xs={10}>
                  <form id="newUser" name="newUser" autoComplete="off" style={{ width: '100%' }}>
                    <Grid container item xs={12} spacing={3} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="projects-label">Projects</InputLabel>
                          <Select
                            labelId="projects-label"
                            id="projects"
                            multiple
                            value={selectedProjects}
                            onChange={onSelectProjects}
                            input={<Input id="multiple-project" />}
                            renderValue={(selected) => (
                              <div>
                                {selected.map((value) => (
                                  <Chip key={value.id} label={value.name} />
                                ))}
                              </div>
                            )}
                          >
                            {projects
                              .filter(project => {
                                const projPermission = user.permissions.findIndex(pm => pm.projectId === project.id);
                                return projPermission === -1;
                              })
                              .map((project) =>
                                <MenuItem key={project.id} value={project}>
                                  <Checkbox color="primary" checked={selectedProjects.indexOf(project) > -1} />
                                  <ListItemText primary={project.name} />
                                </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button variant="contained" color="primary" size="small" fullWidth={true} startIcon={<SaveIcon />} onClick={onClickAssignButton}>
                          Assign
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
              <Typography variant="h6" gutterBottom component="div">Assigned Projects</Typography>
              <Table size="small" aria-label="projects">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.permissions.map((proj) =>
                    <TableRow key={proj.projectId}>
                      <TableCell component="th" scope="row">
                        {proj.projectId}
                      </TableCell>
                      <TableCell>
                        {_getProjectName(proj)}
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined">
                          <Select
                            id="role-select"
                            margin="dense"
                            value={proj.role}
                            onChange={(event) => props.onProjectRoleChanged(user.id, proj.projectId, event.target.value)}
                          >
                            <MenuItem value={1}>Project Admin</MenuItem>
                            <MenuItem value={2}>Internal User</MenuItem>
                            <MenuItem value={3}>External User</MenuItem>
                            <MenuItem value={4}>Viewer</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="delete" onClick={() => props.onRemoveFromProject(user.id, proj.projectId)}>
                          <HighlightOffRoundedIcon color="secondary"/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default UserRow;