import React, { useState } from 'react';

import StyledTableCell from '../../Shared/StyledTableCell/StyledTableCell';
import './ROCTable.css';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const ROCRow = (props) => {
  const classes = useRowStyles();

  const ADD_DELETE_ACTIVITY_STEPS = false;
  const UPDATE_REGISTER = false;

  const ruleOfCredit = props.ruleOfCredit;
  const registers = props.registers;
  const readOnly = props.readOnly;

  // Bug Fix: Filter out registers w/o ROCs
  const parentRegister = registers
    .filter(rg => rg.ruleOfCredits)
    .find(rg => {
      const index = rg.ruleOfCredits.findIndex(roc => roc.id === ruleOfCredit.id);
      return index !== -1;
    });

  const [open, setOpen] = useState(false);
  const [newStep, setNewStep] = useState({
    name: "",
    percent: 1
  });

  /* Event Handlers */
  const expandROCRow = () => setOpen(!open);

  const addAnActivityStepHandler = () => {
    if (newStep.name === "") {
      window.alert("New Activity Step name cannot be empty");
      return;
    }
    if (newStep.percent === "" || newStep.percent < 0 || newStep.percent > 100) {
      window.alert("New Activity Step percent must be within 0 to 100");
      return;
    }

    props.addAnActivityStep(ruleOfCredit.id, newStep.name, newStep.percent);
    setNewStep({
      name: "",
      percent: 1
    });
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={expandROCRow}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {ruleOfCredit.id}
        </TableCell> */}
        <TableCell>
          <FormControl variant="outlined" required>
            <TextField
              id={"rocName_$" + ruleOfCredit.id}
              variant="outlined"
              size="small"
              value={ruleOfCredit.name}
              onChange={event => {
                props.updateRuleOfCreditName(ruleOfCredit.id, event.target.value)
              }}
              onClick={event => event.stopPropagation()}
              inputProps={{
                maxLength: 100,
                readOnly: readOnly
              }}
              required />
          </FormControl>
        </TableCell>
        <TableCell>
          { (UPDATE_REGISTER) ?
            <FormControl variant="outlined" required>
              <InputLabel id={"register-label_$" + ruleOfCredit.id}>Register</InputLabel>
              <Select
                id={"register_$" + ruleOfCredit.id}
                labelId={"register-label_$" + ruleOfCredit.id}
                label="Register"
                margin="dense"
                value={parentRegister}
                onChange={event => props.updateRuleOfCreditRegister(ruleOfCredit.id, event.target.value)}
                required
              >
              {registers.map((register, index) =>
                <MenuItem key={register.id} value={register}>
                  {register.name}
                </MenuItem>
              )}
              </Select>
            </FormControl> : parentRegister.name
          }
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="save"
            onClick={() => props.saveRuleOfCredit(ruleOfCredit.id, parentRegister.id)}
            disabled={readOnly}
          >
            <SaveRoundedIcon color={readOnly ? "disabled" : "primary"} />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="delete"
            onClick={() => props.deleteRuleOfCredit(ruleOfCredit.id, parentRegister.id)}
            disabled={readOnly}
          >
            <DeleteOutlineRoundedIcon color={readOnly ? "disabled" : "secondary"} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" gutterBottom component="div" style={{textAlign: "center", marginBottom: "20px"}}>
                ROC Steps
              </Typography>

              {ADD_DELETE_ACTIVITY_STEPS &&
                <Grid container spacing={3} className="marginBottom20">
                  <Grid item lg={5} xs={12}>
                    <FormControl variant="outlined" fullWidth >
                      <TextField
                        id={"newActivityNameForROC_$" + ruleOfCredit.id}
                        label="Name"
                        variant="outlined"
                        size="small"
                        value={newStep.name}
                        onChange={event => {
                          setNewStep({ ...newStep, name: event.target.value });
                        }}
                        inputProps={{ maxLength: 100 }}
                        required />
                    </FormControl>
                  </Grid>
                  <Grid item lg={5} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        id={"newActivityPercentForROC_$" + ruleOfCredit.id}
                        label="Percent"
                        type="number"
                        size="small"
                        variant="outlined"
                        value={newStep.percent}
                        onChange={event => {
                          setNewStep({ ...newStep, percent: event.target.value });
                        }}
                        inputProps={{ min: 0, max: 100 }}
                        InputLabelProps={{ shrink: true }}
                        required
                      >
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addAnActivityStepHandler}
                      style={{ width: '100%' }}
                      startIcon={<AddCircleRoundedIcon />}
                    >
                      Add New
                    </Button>
                  </Grid>
                </Grid>
              }

              <Grid container justify="space-around" spacing={3}>
                {ruleOfCredit.activitySteps.map((step, index) =>
                  <Grid key={step.id} container item lg={8} xs={12} justify="space-evenly" alignItems="center">

                    <Grid item xs={4}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          id={"step-name-$" + step.id}
                          label="Name"
                          variant="outlined"
                          size="small"
                          value={step.name}
                          onChange={event => {
                            props.updatedAnActivityStep(ruleOfCredit.id, step.id, 'name', event.target.value)
                          }}
                          onClick={event => event.stopPropagation()}
                          inputProps={{
                            maxLength: 100,
                            readOnly: readOnly
                          }}
                          required />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <FormControl variant="outlined">
                        <TextField
                          id={"step-percent-$" + step.id}
                          label="Percent"
                          variant="outlined"
                          type="number"
                          size="small"
                          value={step.percent}
                          onChange={event => {
                            props.updatedAnActivityStep(ruleOfCredit.id, step.id, 'percent', event.target.value)
                          }}
                          onClick={event => event.stopPropagation()}
                          inputProps={{
                            min: 0,
                            max: 100,
                            readOnly: readOnly
                          }}
                          InputLabelProps={{ shrink: true }}
                          required />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* <TableContainer component={Paper}>
                <Table size="small" className="activity-steps-table">
                  <TableHead>
                    <TableRow className={classes.tableHead}>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Percent</StyledTableCell>
                      { ADD_DELETE_ACTIVITY_STEPS && <StyledTableCell>Delete</StyledTableCell> }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ruleOfCredit.activitySteps.map((step, index) =>
                      <TableRow key={step.id}>
                        <TableCell component="th" scope="row">
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              id={"stepName_$" + step.id}
                              variant="outlined"
                              size="small"
                              value={step.name}
                              onChange={event => {
                                props.updatedAnActivityStep(ruleOfCredit.id, step.id, 'name', event.target.value)
                              }}
                              onBlur={() => props.saveRuleOfCredit(ruleOfCredit.id, parentRegister.id)}
                              onClick={event => event.stopPropagation()}
                              inputProps={{ maxLength: 100 }}
                              required />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl variant="outlined">
                            <TextField
                              id={"stepPercent_$" + step.id}
                              variant="outlined"
                              type="number"
                              size="small"
                              value={step.percent}
                              onChange={event => {
                                props.updatedAnActivityStep(ruleOfCredit.id, step.id, 'percent', event.target.value)
                              }}
                              onBlur={() => props.saveRuleOfCredit(ruleOfCredit.id, parentRegister.id)}
                              onClick={event => event.stopPropagation()}
                              inputProps={{ min: 0, max: 100 }}
                              InputLabelProps={{ shrink: true }}
                              required />
                          </FormControl>
                        </TableCell>
                        { ADD_DELETE_ACTIVITY_STEPS &&
                          <TableCell>
                            <IconButton aria-label="delete" onClick={() => props.deleteAnActivityStep(ruleOfCredit.id, step.id)}>
                              <DeleteOutlineRoundedIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                        }
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer> */}

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const ROCTable = (props) => {
  const ruleOfCredits = props.ruleOfCredits;
  const registers = props.registers;
  const readOnly = props.readOnly;

  return (
    <div className="ROCTable fullWidth spaceEvenly marginTop30">
      <Grid item xs={10}>
        <Paper className="fullWidth" elevation={2}>

          <Typography variant="h5" component="h5" className="padding20">
            Rule of Credits
          </Typography>

          {(ruleOfCredits && ruleOfCredits.length > 0) ?
            <>
              {!readOnly &&
                <Grid container justify="flex-end" className="padding20" style={{paddingTop: "0", paddingBottom: "0"}}>
                  <Grid item lg={3} xs={12}>
                    <Button color="primary" variant="contained" fullWidth startIcon={<SaveRoundedIcon/>} onClick={props.saveRuleOfCredits}>
                      Save Rule of Credits
                    </Button>
                  </Grid>
                </Grid>
              }

              <div className="padding20">
                <TableContainer component={Paper}>
                  <Table className="credits-table">
                    <TableHead>
                      <TableRow >
                        <StyledTableCell className="collapse-arrow" />
                        <StyledTableCell className="roc-details">ROC Name</StyledTableCell>
                        <StyledTableCell className="roc-details">Register</StyledTableCell>
                        <StyledTableCell className="save-roc-btn" align="center">Save</StyledTableCell>
                        <StyledTableCell className="delete-roc-btn" align="center">Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ruleOfCredits.map((roc, index) =>
                        <ROCRow
                          key={roc.id}
                          ruleOfCredit={roc}
                          registers={registers}
                          readOnly={readOnly}

                          updateRuleOfCreditName={props.updateRuleOfCreditName}
                          updateRuleOfCreditRegister={props.updateRuleOfCreditRegister}
                          deleteRuleOfCredit={props.deleteRuleOfCredit}

                          addAnActivityStep={props.addAnActivityStep}
                          updatedAnActivityStep={props.updatedAnActivityStep}
                          deleteAnActivityStep={props.deleteAnActivityStep}

                          saveRuleOfCredit={props.saveRuleOfCredit}/>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
            :
            <Grid container justify="center" className="padding20">
              <Grid item xs={12} style={{textAlign: "center"}}>
                There are no Rule of Credits for this Project.
              </Grid>
            </Grid>
          }
        </Paper>
      </Grid>
    </div>
  );
};

export default ROCTable;