import React, { useEffect, useState } from "react";
import "./activitySearch.css";
import StyledTableCell from "../../Shared/StyledTableCell/StyledTableCell";

import {
  Grid,
  Paper,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import Autocomplete from "@material-ui/lab/Autocomplete";

const ActivitySearch = ({ selectedLevel, activities, activitySelected }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [inputValue, setInputValue] = useState("");
  const [filteredActivities, setFilteredActivities] = useState(activities);

  const showActivity = (steps, id) => {
    activitySelected(steps, id);
  };

  const updateSearchTerm = debounce((target, value) => {
    setFilteredActivities(
      value
        ? activities.filter((activity) => {
            return activity.description.includes(value);
          })
        : activities
    );
    setPage(0);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage =
      event.target.value === "All"
        ? filteredActivities.length
        : parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  useEffect(() => {
    setFilteredActivities(activities);
    setInputValue("");
  }, [activities]);

  return (
    <div className="activities-table-tile space-evenly full-width">
      <Grid item xs={10}>
        <Paper elevation={2}>
          {selectedLevel.id !== "" ? (
            <>
              <Typography
                variant="h5"
                component="h5"
                className="search-box search-title"
              >
                {`Searching Activities for [L${selectedLevel.number}] ${selectedLevel.name}`}
              </Typography>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                className="search-box"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid xs={6}>
                    <Autocomplete
                      id="activity-autocomplete"
                      freeSolo
                      inputValue={inputValue}
                      options={filteredActivities.map((activity) => {
                        return activity.description;
                      })}
                      onChange={updateSearchTerm}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Search Activity" />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  className="search-box search-table"
                  container
                  spacing={3}
                  justify="flex-start"
                >
                  <Grid item xs={12}>
                    <Paper elevation={2}>
                      <TableContainer className="level-summary-table-container">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell className="id">
                                ID
                              </StyledTableCell>
                              <StyledTableCell className="name">
                                Name
                              </StyledTableCell>
                              <StyledTableCell className="name">
                                Activity Path
                              </StyledTableCell>
                              <StyledTableCell className="button" />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredActivities
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((item, index) => (
                                <TableRow
                                  onClick={() => {
                                    showActivity(item.steps, item.id);
                                    setPage(0);
                                  }}
                                  key={`${item.type}_${item.id}`}
                                  className="search-table-row"
                                >
                                  <StyledTableCell>{item.id}</StyledTableCell>
                                  <StyledTableCell>
                                    {item.description}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item.levelPath.join(" / ")}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {<NavigateNextIcon />}
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 30, "All"]}
                        component="div"
                        count={filteredActivities.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container justify="center" className="padding20">
              <Grid item xs={12} style={{ textAlign: "center" }}>
                There are no Levels selected to search. Please select a Level.
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </div>
  );
};

export default ActivitySearch;
