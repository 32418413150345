import React, { Component } from "react";
import classes from "./Login.module.css";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { basicReduxStore } from "../../store/reduxStore";
import { authProvider } from "../MSAL/authProvider";
import { Layout } from '../Layout';

class Login extends Component {

  render() {
    return (
      <AzureAD provider={authProvider} reduxStore={basicReduxStore} forceLogin={true}>
        {({ login, logout, authenticationState }) => {
          const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
          if (isAuthenticated) {
            return <Layout />;
          } else {
            return (
              <div className={classes.background}>
                {login}
              </div>
            );
          }
        }}
      </AzureAD >
    );
  }
}
export default Login;