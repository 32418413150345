import React, { useState } from "react";

import ROCActivitiesTable from "../ROCActivitiesTable/ROCActivitiesTable";
import QuantityActivitiesTable from "../QuantityActivitiesTable/QuantityActivitiesTable";
import LevelSummary from "../LevelSummary/LevelSummary";

import "./activitiesLayout.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const ActivitiesLayout = (props) => {
  //#region LevelSummary
  const selectedLevel = props.selectedLevel;
  //#endregion

  //#region ActivitiesLayout
  const selectedLvlId = selectedLevel.id;
  const activities = props.activities;
  const attributes = props.attributes;
  const ruleOfCredits = props.ruleOfCredits;
  const onUpdateRocProgressDate = props.onUpdateRocProgressDate;
  const onAddNewRocProgress = props.onAddNewRocProgress;
  const onAddNewRocProgressObj = props.onAddNewRocProgressObj;
  const saveActivities = props.saveActivities;

  const onUpdatePlannedQuantity = props.onUpdatePlannedQuantity;
  const onUpdateCompletedQuantity = props.onUpdateCompletedQuantity;
  const onUpdateForecastStartDate = props.onUpdateForecastStartDate;
  const onUpdateForecastFinishDate = props.onUpdateForecastFinishDate;
  const onUpdateActualStartDate = props.onUpdateActualStartDate;
  const onUpdateActualFinishDate = props.onUpdateActualFinishDate;
  const activitySearchId = props.activitySearchId;
  const [cutOffDate, setCutOffDate] = useState(null);

  const onChangeCutOffDate = (newDate) => {
    setCutOffDate(newDate);
  };

  //#endregion

  //#region Render Table Logic
  const _renderTable = () => {
    // IF a level has been selected
    if (selectedLvlId !== "") {
      // IF there are activities to render
      // THEN render the Activities Table
      if (activities.length > 0) {
        // ROC Based
        if (
          selectedLevel.model === 0 &&
          ruleOfCredits?.length
        ) {
          return (
            <ROCActivitiesTable
              levelId={selectedLvlId}
              activities={activities}
              attributes={attributes}
              ruleOfCredits={ruleOfCredits}
              cutOffDate={cutOffDate}
              onUpdateRocProgressDate={onUpdateRocProgressDate}
              onAddNewRocProgress={onAddNewRocProgress}
              onAddNewRocProgressObj={onAddNewRocProgressObj}
              onChangeCutOffDate={onChangeCutOffDate}
              saveActivities={saveActivities}
              filterByActivitySearch={activitySearchId}
            />
          );
        }

        // Quantity Based
        else if (selectedLevel.model === 1) {
          return (
            <QuantityActivitiesTable
              levelId={selectedLvlId}
              activities={activities}
              attributes={attributes}
              categories={selectedLevel.categories}
              cutOffDate={cutOffDate}
              onChangeCutOffDate={onChangeCutOffDate}
              onUpdatePlannedQuantity={onUpdatePlannedQuantity}
              onUpdateCompletedQuantity={onUpdateCompletedQuantity}
              onUpdateForecastStartDate={onUpdateForecastStartDate}
              onUpdateForecastFinishDate={onUpdateForecastFinishDate}
              onUpdateActualStartDate={onUpdateActualStartDate}
              onUpdateActualFinishDate={onUpdateActualFinishDate}
              onUpdateSecondaryCategory={props.onUpdateSecondaryCategory}
              onUpdatePrimaryCategory={props.onUpdatePrimaryCategory}
              onUpdateSecondaryPlannedQuantity={
                props.onUpdateSecondaryPlannedQuantity
              }
              onUpdateSecondaryCompletedQuantity={
                props.onUpdateSecondaryCompletedQuantity
              }
              saveActivities={saveActivities}
              filterByActivitySearch={activitySearchId}
            />
          );
        }
      }

      // If selected level does not have activities
      // THEN render a summary of Weighting/Progress %
      else {
        return <LevelSummary level={selectedLevel} />;
      }
    }

    // IF there is no selected activity
    // THEN render a message
    else {
      return (
        <Grid container justify="center" className="padding20">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            There are no Levels/Activities to display. Please select a Level.
          </Grid>
        </Grid>
      );
    }
  };
  //#endregion

  return (
    <div className="activities-table-tile space-evenly full-width">
      <Grid item xs={10}>
        <Paper elevation={2}>
          <Typography variant="h5" component="h5" className="padding20">
            Levels/Activities
          </Typography>
          {_renderTable()}
        </Paper>
      </Grid>
    </div>
  );
};

export default ActivitiesLayout;
