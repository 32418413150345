import React, { useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MuiAccordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";
import { DATE_FORMAT } from "../../constants/strings";

import "date-fns";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  accordionStepsContainer: {
    "&> .stepAccordion": {
      width: "100%",
    },
    "&> .stepAccordion:first-child": {
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
    },
    "&> .stepAccordion:nth-child(odd)": {
      backgroundColor: "#FFFFFF",
    },
    "&> .stepAccordion:nth-child(even)": {
      backgroundColor: "#C7D0D8",
    },
  },
  activityDatePicker: {
    backgroundColor: "rgba(255,255,255,0.12)",
    borderRadius: "4px 4px 0 0",
    margin: "0 0 20px 0",
    "& > div": {
      margin: 0,
      padding: "22px 16px 10px 16px",
    },
    "& > label": {
      left: "16px",
      top: "10px",
    },
  },
}));

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordion);

const ROCStep = ({step, onSaveStep}) => {
  const classes = useStyles();


  const getDateObject = (date) => {
    return date ? moment(date) : null
  }

  const [plannedDate, setPlannedDate] = useState(getDateObject(step.plannedDate))
  const [forecastDate, setForecastDate] = useState(getDateObject(step.forecastDate))
  const [actualDate, setActualDate] = useState(getDateObject(step.actualDate))

  const getChipStatusProps = () => {
    const chipProps = {
      label: null,
      style: {
        backgroundColor: null,
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.25px",
        lineHeight: "20px",
        textAlign: "Center",
        width: "112px",
      },
    };

    if (step.actualDate) {
      chipProps.label = "Complete";
      chipProps.style.backgroundColor = "LimeGreen";
    } else {
      switch (step.status) {
        case 0:
          chipProps.label = "Past Due";
          chipProps.style.backgroundColor = "rgba(218,58,58,0.87)";
          break;
        case 1:
          chipProps.label = "Due in 3 days";
          chipProps.style.backgroundColor = "rgba(255,152,0,0.87)";
          break;
        case 2:
        default:
          chipProps.label = "In progress";
          chipProps.style.backgroundColor = "rgba(199,208,216,0.87)";
      }
    }

    return chipProps;
  };

  const handleSaveActivity = () => {
    const updatedStep = {...step};
    updatedStep.plannedDate = moment(plannedDate).format("YYYY-MM-DD");
    updatedStep.forecastDate = moment(forecastDate).format("YYYY-MM-DD");
    updatedStep.actualDate = moment(actualDate).format("YYYY-MM-DD");
    onSaveStep(updatedStep);
  };

  return (
    <Accordion className="stepAccordion">
      <AccordionSummary
        id={`stepAccordionSummary-${step.id}`}
        aria-controls="panel1b-content"
        expandIcon={<ExpandMoreIcon />}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                color: "rgba(0,0,0,0.87)",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "0.1px",
                lineHeight: "14px",
                textAlign: "left",
              }}
            >
              {step.stepName}
            </Typography>
          </Box>
          <Box>
            <Chip {...getChipStatusProps()} />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <FormControl variant="outlined" fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={`plannedDate-${step.id}`}
                className={classes.activityDatePicker}
                disableToolbar
                variant="inline"
                format={DATE_FORMAT}
                margin="normal"
                label={`Planned`}
                value={plannedDate}
                onChange={(newDate) => setPlannedDate(newDate)}
                autoOk={true}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                readOnly={false}
                InputProps={{ readOnly: false }}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={`forecastDate-${step.id}`}
                className={classes.activityDatePicker}
                disableToolbar
                variant="inline"
                format={DATE_FORMAT}
                margin="normal"
                label={`Forecast`}
                value={forecastDate}
                onChange={(newDate) => setForecastDate(newDate)}
                autoOk={true}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                readOnly={false}
                InputProps={{ readOnly: false }}
                disabled={step.actualDate}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={`actualDate-${step.id}`}
                className={classes.activityDatePicker}
                disableToolbar
                variant="inline"
                format={DATE_FORMAT}
                margin="normal"
                label={`Actual`}
                value={actualDate}
                onChange={(newDate) => setActualDate(newDate)}
                autoOk={true}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                readOnly={false}
                InputProps={{ readOnly: false }}
                disabled={step.actualDate}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <Grid container justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              justifySelf="end"
              disabled={step.actualDate}
              onClick={handleSaveActivity}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const ROCSteps = ({ steps, onSaveStep }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.accordionStepsContainer}>
      {steps.map((step) => (
        <ROCStep
          key={step.id}
          step={step}
          onSaveStep={onSaveStep}
        />
      ))}
    </Grid>
  );
};

export default ROCSteps;
