import React, { useEffect, useState } from "react";

import "./levelTreeView.css";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";

const LevelTreeView = (props) => {
  // Props State
  const levels = props.levels;

  // Props Functions
  const onSelectLevelNode = props.onSelectLevelNode;

  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState("");

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    if (props.selectedItem) {
      const uniqueSelectedSet = [
        ...new Set([...props.selectedItem.map(String), ...expanded]),
      ];
      setSelected(
        props.selectedItem ? props.selectedItem.slice(-1).toString() : ""
      );
      setExpanded(Array.from(uniqueSelectedSet));
    }
  }, [props.selectedItem]);

  // Recursive Tree Node Function
  const renderTree = (level) => (
    <TreeItem
      key={level.id}
      id={`level-tree-${level.id}`}
      nodeId={level.id.toString()}
      label={`[L${level.number}] ${level.name} (${level.totalActivities})`}
      onClick={() => onSelectLevelNode(level)}
    >
      {Array.isArray(level.children)
        ? level.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      className="LevelTreeView"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {levels.map((lvl) => renderTree(lvl))}
    </TreeView>
  );
};

export default LevelTreeView;
