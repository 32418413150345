import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

const getAttributes = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/attributes", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.attributes;
    })
    .catch(basicErrorHandler);
};

const addAttribute = async (name, description) => {
  const newAttribute = { name, description };
  const token = await AuthTokenGenerator.getToken();

  return axios
    .post(appConfig.baseUrl + "/attributes", newAttribute, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.attribute;
    })
    .catch(basicErrorHandler);
};

const getCurrencies = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/currencies", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.currencies;
    })
    .catch(basicErrorHandler);
};

export const AttributesService = {
  getAttributes,
  addAttribute,
  getCurrencies
};