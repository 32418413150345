import React, { useContext, useState } from "react";

import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Paper from "@material-ui/core/Paper";
import StyledTableCell from "../../Shared/StyledTableCell/StyledTableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import useExpandableRow from "../useExpandableRow";

import { ProjectContext } from "../../Context/ProjectContext/ProjectContext";

const CategoriesTable = ({
  onCategoryDelete,
  readOnly,
  categories,
  onAddSecondary,
}) => {
  const handleRemoveCategory = (category) => {
    onCategoryDelete(category);
  };

  return (
    <>
      {" "}
      <TableContainer component={Paper} className="inner-table">
        <Table size="small">
          <TableHead>
            <TableRow className="primary-categories-table-head">
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              {!readOnly && <StyledTableCell>Remove</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <CategoryRows
                key={category.id}
                category={category}
                readOnly={readOnly}
                onRemoveCategory={handleRemoveCategory}
                onAddSecondary={onAddSecondary}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const WarningDialog = ({ alertOpen, onClose, title, text, continueAction }) => {
  return (
    <Dialog open={alertOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={continueAction} color="primary">
          Yes, remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CategoryRows = ({
  category,
  readOnly,
  onRemoveCategory,
  onAddSecondary,
}) => {
  const [isExpanded, toggleExpand] = useExpandableRow(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryUnitOfMeasure, setNewCategoryUnitOfMeasure] = useState({
    id: 0,
    name: "",
  });
  const [uomName, setUomName] = useState("");
  const [isNameError, setIsNameError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    text: ""
  });
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const { unitsOfMeasurement } = useContext(ProjectContext);

  const handleNewNameChange = (e) => {
    if (e.target.value.length > 0 && isNameError) {
      setIsNameError(false);
    }
    setNewCategoryName(e.target.value);
  };

  const handleNewUnitChange = (e, value) => {
    setUomName(value);
  };

  const handleSelectUnit = (e, value) => {
    setNewCategoryUnitOfMeasure(value);
  };

  const handleClickNewCategory = () => {
    if (validateSecondaryParams()) {
      setIsNameError(false);
      const newSecondaryCategory = {
        name: newCategoryName,
        parentId: category.id,
        unitOfMeasurement: newCategoryUnitOfMeasure.name || uomName,
      };
      onAddSecondary(newSecondaryCategory);
      setNewCategoryName("");
      setNewCategoryUnitOfMeasure({
        id: 0,
        name: "",
      });
    } else {
      setIsNameError(true);
    }
  };

  const validateSecondaryParams = () => {
    return (
      newCategoryName.length > 0 &&
      ((newCategoryUnitOfMeasure.name &&
        newCategoryUnitOfMeasure.name.length > 0) ||
        uomName.length > 0)
    );
  };

  const handleRemove = (selectedCategory) => {
    setCategoryToDelete(selectedCategory);
    if (!checkRemovePrimaryWithChild(selectedCategory)) {
      openWarning();
    }
  };

  const checkRemovePrimaryWithChild = (selectedCategory) => {
    if (selectedCategory.children && selectedCategory.children.length > 0) {
      openPrimaryWarning();
      return true;
    }
    return false;
  };
  
  const openPrimaryWarning = () => {
    setAlertOpen(true);
    setDialogContent({
      title: "Delete primary?",
      text:"Are you sure you want to delete this primary category?, this action cannot be reverted! It will delete all associated secondary categories."
    });
  };

  const openWarning = () => {
    setAlertOpen(true);
    setDialogContent({
      title: "Delete category?",
      text:"Are you sure you want to delete this category?, this action cannot be reverted!"
    });
  };

  const continueDelete = () => {
    if (categoryToDelete.unitOfMeasurement) {
      categoryToDelete.parentId = category.id;
    }
    onRemoveCategory(categoryToDelete);
    setAlertOpen(false);
  };

  return (
    <>
      <TableRow key={category.id} className="main-row">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleExpand}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {category.id}
        </TableCell>
        <TableCell>{category.name}</TableCell>
        {!readOnly && (
          <TableCell>
            <IconButton
              aria-label="delete"
              onClick={() => handleRemove(category)}
              disabled={readOnly}
            >
              <HighlightOffRoundedIcon
                color={readOnly ? "disabled" : "secondary"}
              />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow className="secondaries-row collapsable-row">
        <TableCell colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              className="category-section-title"
            >
              Secondary Categories
            </Typography>
            {!readOnly && (
              <Grid container spacing={3} className="new-categories-grid">
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="newSecondaryName"
                      label="Name"
                      variant="outlined"
                      size="small"
                      value={newCategoryName}
                      onChange={handleNewNameChange}
                      required
                      error={isNameError}
                      helperText={isNameError ? "Required" : ""}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={2} xs={12} alignItems="center">
                  <FormControl fullWidth required>
                    <Autocomplete
                      id="newSecondaryUnit"
                      value={newCategoryUnitOfMeasure}
                      onChange={handleSelectUnit}
                      options={unitsOfMeasurement}
                      getOptionLabel={(option) => option.name}
                      inputValue={uomName}
                      onInputChange={handleNewUnitChange}
                      freeSolo
                      required
                      renderInput={(params) => (
                        <TextField {...params} label="Unit of Measurement" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={2} xs={12}>
                  <div className="fullWidth">
                    <Grid item lg={9} xs={12}>
                      <Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        onClick={handleClickNewCategory}
                        startIcon={<AddCircleOutlineRoundedIcon />}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
            <TableContainer component={Paper} className="inner-table">
              <Table size="small" className="grey-table">
                <TableHead>
                  <TableRow className="secondary-categories-table-head">
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Unit of measurement</StyledTableCell>
                    {!readOnly && <StyledTableCell>Remove</StyledTableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category.children &&
                    category.children.map((secondary, index) => (
                      <TableRow key={secondary.id}>
                        <TableCell>{secondary.id}</TableCell>
                        <TableCell>{secondary.name}</TableCell>
                        <TableCell>
                          {secondary.unitOfMeasurement.name}
                        </TableCell>
                        {!readOnly && (
                          <TableCell>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleRemove(secondary)}
                              disabled={readOnly}
                            >
                              <HighlightOffRoundedIcon
                                color={readOnly ? "disabled" : "secondary"}
                              />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
      <WarningDialog
        alertOpen={alertOpen}
        text={dialogContent.text}
        title={dialogContent.title}
        onClose={() => setAlertOpen(false)}
        continueAction={continueDelete}
      />
    </>
  );
};

export default CategoriesTable;
