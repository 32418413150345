export const dateFilter = (date) => {
    if (typeof date === 'string') {
        date = Date.parse(date);
    }
    const dateToFormat = new Date(date);
    let monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"];

    let day = dateToFormat.getUTCDate();
    let monthIndex = dateToFormat.getUTCMonth();
    let monthName = monthNames[monthIndex];
    let year = dateToFormat.getUTCFullYear();
    
    return `${day}-${monthName}-${year}`;
}