import axios from "axios";
import { authProvider } from "../MSAL/authProvider";
import { appConfig } from "../../appConfig";

let token = null;

const GetToken = async () => {
  return await authProvider.getAccessToken().then((token) => {
    return "Bearer " + token.accessToken;
  });
};

const setToken = async () => {
  token = await GetToken();
}
setToken();

const getFacilityEquipmentScopes = async () => {
  return axios
    .get(appConfig.baseUrl + "/facilityequipmentscopes", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.facilityEquipmentScopes;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};

const getSubBusinessLines = async () => {
  return axios
    .get(appConfig.baseUrl + "/subbusinesslines", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.subBusinessLines;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};


const getExecutionLocations = async () => {
  return axios
    .get(appConfig.baseUrl + "/executionlocations", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.executionLocations;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};

const getFacilityLocations = async () => {
  return axios
    .get(appConfig.baseUrl + "/facilitylocations", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.facilityLocations;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};

const getAllUsers = async () => {
  return axios
    .get(appConfig.baseUrl + "/users", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

export const MasterDataService = {
  getFacilityEquipmentScopes,
  getSubBusinessLines,
  getExecutionLocations,
  getFacilityLocations,
  getAllUsers
};