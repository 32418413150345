import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";

import UploadActivitiesDialog from "../uploadActivitiesDialog/UploadActivitiesDialog";
import ArchivesDialog from "../ArchivesDialog/ArchivesDialog";
import { LevelsService } from "../../../Service/LevelsService";
import UserContext from "../context/userContext";
import * as PROJECTS from "../../constants/projects";
import * as LEVELS from "../../constants/levels";
import "./levelData.css";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";

import DownloadMDR from "../DownloadMDR/DownloadMDR";

const LevelData = (props) => {
  const { id } = useParams();
  const userContext = useContext(UserContext);

  const selectedLevel = props.selectedLevel;
  const [dialogSettings, setDialogSettings] = useState({
    title: "",
    message: "",
    open: false,
  });

  //#region Upload Dialog
  const [openUploadDialog, openUploadDialogSettings] = useState(false);

  const showUploadDialog = () => {
    openUploadDialogSettings(true);
  };

  const hideUploadDialog = () => {
    openUploadDialogSettings(false);
  };
  //#endregion

  const downloadRegisterExcel = async () => {
    try {
      setDialogSettings({
        title: "Excel Download In Progress",
        message:
          "Excel download can takes minutes. Please be patient and remain on the page.",
        open: true,
      });
      const excel = await LevelsService.getLevelExcel(id, selectedLevel.id);

      var exportedFileName = "RegisterActivityDetails.xlsx";
      var blob = new Blob([excel.file], { type: excel.type });

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFileName);
      } else {
        var link = document.createElement("a");
        if (link.download == undefined) {
          // feature detection
          // TODO: Add exception code
        }

        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDialogSettings({ ...dialogSettings, open: false });
    }
  };

  //#region Archives Dialog
  const [openArchivesDialog, setOpenArchivesDialog] = useState(false);
  const registers = props.registers;

  const openArchivesDialogHandler = () => {
    setOpenArchivesDialog(true);
  };

  const closeArchivesDialog = () => {
    setOpenArchivesDialog(false);
  };

  const _getRegisterArchives = () => {
    if (selectedLevel.id === "" || selectedLevel.type !== 1) {
      return [];
    }

    const sameRegister = registers.find((r) => r.id === selectedLevel.id);
    return sameRegister.archives.slice(0).reverse();
  };

  const registerArchives = _getRegisterArchives();
  //#endregion

  const _canUserPerformRegisterActions = () => {
    return (
      userContext.admin ||
      userContext.projectManager ||
      userContext.role === PROJECTS.Roles.ProjectAdmin
    );
  };

  return (
    <form id="levelForm" name="levelForm" autoComplete="off">
      <Grid container spacing={3}>
        <Grid item lg={2} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={"lvl_id_$" + selectedLevel.id}
              label="Level Id"
              variant="outlined"
              size="small"
              value={selectedLevel.id}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item lg={4} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={"lvl_name_$" + selectedLevel.id}
              label="Level Name"
              variant="outlined"
              size="small"
              value={selectedLevel.name}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item lg={3} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={"lvl_Discipline_$" + selectedLevel.id}
              label="WBS Code"
              variant="outlined"
              size="small"
              value={selectedLevel.discipline}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item lg={3} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={"lvl_currency_$" + selectedLevel.id}
              label="Currency"
              variant="outlined"
              size="small"
              value={selectedLevel.currency.name}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item lg={3} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={"lvl_manHour_$" + selectedLevel.id}
              label="Man Hour"
              variant="outlined"
              size="small"
              value={selectedLevel.manHour ? selectedLevel.manHour : ""}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item lg={3} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={"lvl_cost_$" + selectedLevel.id}
              label="Cost"
              variant="outlined"
              size="small"
              value={selectedLevel.cost ? selectedLevel.cost : ""}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item lg={3} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <TextField
              id={"lvl_weighting_$" + selectedLevel.id}
              label="Weighting"
              variant="outlined"
              size="small"
              value={selectedLevel.weight ? selectedLevel.weight.value : 0}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item lg={2} xs={12}>
          <FormControlLabel
            variant="outlined"
            required
            label="Link a register?"
            control={
              <Checkbox
                checked={selectedLevel.type === 1}
                name={"lvl_register_$" + selectedLevel.weighting}
                color="primary"
              />
            }
          />
        </Grid>

        {selectedLevel.type === LEVELS.TYPE.Register &&
          _canUserPerformRegisterActions() && (
            <>
              <DownloadMDR projectId={id} levelId={selectedLevel.id} />

              <Grid item lg={3} xs={12}>
                <IconButton
                  color="primary"
                  aria-label="download Register"
                  component="span"
                  onClick={() => {
                    downloadRegisterExcel();
                  }}
                >
                  <GetAppRoundedIcon />
                </IconButton>
                <Typography variant="caption">Download Activities</Typography>
              </Grid>

              <Grid item lg={3} xs={12}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    showUploadDialog();
                  }}
                >
                  <PublishRoundedIcon />
                </IconButton>
                <Typography variant="caption">Upload Activities</Typography>
              </Grid>

              <Grid item lg={3} xs={12}>
                <IconButton
                  color="primary"
                  aria-label="Archives List"
                  component="span"
                  disabled={registerArchives.length === 0}
                  onClick={openArchivesDialogHandler}
                >
                  <FolderRoundedIcon />
                </IconButton>
                <Typography variant="caption">Archives List</Typography>
              </Grid>
            </>
          )}
      </Grid>

      <ArchivesDialog
        open={openArchivesDialog}
        closeArchivesDialog={closeArchivesDialog}
        archives={registerArchives}
      />

      <UploadActivitiesDialog
        open={openUploadDialog}
        registerId={selectedLevel.id}
        projectId={id}
        closeHandler={hideUploadDialog}
      />

      <Dialog aria-labelledby="dialog-title" open={dialogSettings.open}>
        <DialogTitle id="dialog-title">{dialogSettings.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {dialogSettings.message}
          </DialogContentText>
          <LinearProgress style={{ marginBottom: "12px" }} />
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default LevelData;
