import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import LandingButton from './LandingButton';
import { UsersService } from '../../Service/UsersService';
import * as PROJECTS from '../constants/projects';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import './landingPage.css';
import BackgroundImage from '../../assets/background.png';
import { ReactComponent as SLB_Logo } from '../../assets/SLBlogo.svg';

const useStyles = makeStyles((theme) => ({
  activityStatus: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    
    "& div.header": {
      alignItems: 'center',
      backgroundColor: '#E8ECF2',
      display: 'flex',
      height: '57px'
    },

    "& div.statusBox": {
      height: '48px',
      width: '90%',

      "& div.statusLabel": {
        color: 'rgba(0,0,0,0.87)',
        fontSize: '20px',
        fontWeight: '500',
        letterSpacing: '0.15px',
        lineHeight: '24px'
      }
    }
  },
  content: {
    alignItems: 'center',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    overflow: 'auto',
    position: 'relative',
    width: '100%'
  },
  footer: {
    margin:'34px'
  },
  headerText: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  userNameText: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '34px',
    letterSpacing: '0',
    lineHeight: '36px'
  },
  welcomeText: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  }
}));

const LandingPage = (props) => {

  const classes = useStyles();

  const [showSpinner, setShowSpinner] = useState(false);
  const [user, setUser] = useState({permissions: []});
  const [activitiesSummary, setActivitiesSummary] = useState([
    {
      label: 'Past Due',
      count: 0,
      backgroundColor: '#DA3A3A'
    },
    {
      label: 'Due in 3 days',
      count: 0,
      backgroundColor: '#FF9800'
    },
    {
      label: 'Upcoming',
      count: 0,
      backgroundColor: '#C7D0D8'
    }
  ]);
  const summaryCount = activitiesSummary.length;

  //#region Navigation
  const history = useHistory();
  
  const routeToAdminDashboard = () => {
    history.push('/dashboard');
  }

  const routeToMyActivities = () => {
    history.push('/myActivities');
  };
  //#endregion

  //#region componentDidMount
  useEffect(() => {
    _initialize();
  }, []);

  const _initialize = async () => {
    setShowSpinner(true);
    props.setPageTitle(null);
    props.setViewMode("user");

    const [fetchedUser, userActivitiesPayload] = await Promise.all([
      UsersService.getCurrentUser(),
      UsersService.getUserActivities()
    ]);

    const fetchedActivitiesSummary = userActivitiesPayload.summary;
    const updatedSummary = activitiesSummary.map(s => {
      if (s.label === 'Past Due') {
        return {
          ...s,
          count: fetchedActivitiesSummary.pastDue
        };
      }
      else if (s.label === 'Due in 3 days') {
        return {
          ...s,
          count: fetchedActivitiesSummary.threeDaysDue
        };
      }
      else if (s.label === 'Upcoming') {
        return {
          ...s,
          count: fetchedActivitiesSummary.thirtyDaysDue + fetchedActivitiesSummary.thirtyDaysMoreDue
        };
      }  
    });
    
    setUser(fetchedUser);
    setActivitiesSummary(updatedSummary);
    setShowSpinner(false);
  };
  //#endregion

  const _isUserAdmin = () => {
    const isSystemAdmin = user.admin;
    const isProjectAdmin = !!user.permissions.find(p => p.role === PROJECTS.Roles.ProjectAdmin);

    return isSystemAdmin || isProjectAdmin;
  };

  return (
    <div id="content" className={classes.content}>
      
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>
      
      <Grid container justify="center" alignItems="center" style={{paddingTop: '75px'}}>
        <Grid item xs={12} sm={8} md={6} lg={4} style={{paddingLeft: '15px', paddingRight: '15px'}}>
            
          {/* Avatar */}
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} container justify="center">
              <mgt-person
                person-query="me"
                avatar-size="large"
              ></mgt-person>
            </Grid>
          </Grid>

          <div style={{marginTop: '20px'}}></div>

          {/* Welcome Text */}
          <Grid container justify="center" alignItems="center">

            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.welcomeText}>
                Welcome back,
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" align="center" className={classes.userNameText}>
                {user.name}
              </Typography>
            </Grid>
          </Grid>

          <div style={{marginTop: '30px'}}></div>
            
          <Grid container justify="center" className={classes.activityStatus}>
            
            <Grid item xs={12} className="header">
              <Typography variant="h6" className={classes.headerText} style={{flexGrow: 1}} align="center">
                Activity Status Update
              </Typography>
            </Grid>

            {
              activitiesSummary.map((status, index) => {
                const isFinalRow = (index + 1) === summaryCount;

                return (
                  <Grid
                    key={index}
                    item xs={11}
                    container
                    justify="space-between"
                    alignItems="center"
                    className="statusBox"
                  >
                    <Grid item xs={9}>
                      <Typography className="statusLabel">
                        {status.label}
                      </Typography>
                    </Grid>
                    
                    <Grid item xs={3} container justify="flex-end">
                      <Avatar style={{
                        backgroundColor: status.backgroundColor,
                        color: "#FFFFFF",
                        height: "37px",
                        width: "37px"
                      }}>
                        {status.count}
                      </Avatar>
                    </Grid>

                    {!isFinalRow &&
                      <Grid item xs={12}>
                        <Divider variant="fullWidth"/>
                      </Grid>
                    }
                  </Grid>
                );
              })
            }

          </Grid>
            
          <div style={{marginTop: '20px'}}></div>

          <LandingButton clickHandler={routeToMyActivities}>
            My Activities
          </LandingButton>

          {_isUserAdmin() &&
            <>
              <div style={{marginTop: '20px'}}></div>

              <LandingButton clickHandler={routeToAdminDashboard}>
                My Dashboard
              </LandingButton>
            </>
          }

        </Grid>
      </Grid>
    
      <Grid container justify="center" alignItems="center" className={classes.footer} >
        <SLB_Logo />
      </Grid>
    </div>
  );
};

export default LandingPage;