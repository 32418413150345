import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const getExecutionLocations = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/executionlocations", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
                throw error.response;
            } else {
                console.log(error);
                throw error;
            }
        });
};

const getFacilityEquipmentScopes = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/facilityequipmentscopes", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
                throw error.response;
            } else {
                console.log(error);
                throw error;
            }
        });
};

const getFacilityLocations = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/facilitylocations", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
                throw error.response;
            } else {
                console.log(error);
                throw error;
            }
        });
};

const getSubBusinessLines = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/subbusinesslines", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
                throw error.response;
            } else {
                console.log(error);
                throw error;
            }
        });
};

const getAllUsers = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/users", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
                throw error.response;
            } else {
                console.log(error);
                throw error;
            }
        });
};

const getCurrentUser = async () => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .get(appConfig.baseUrl + "/users/me", {
            headers: {
                Authorization: token,
            },
        })
        .then(function (response) {
            return response.data.payload;
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
                throw error.response;
            } else {
                console.log(error);
                throw error;
            }
        });
}

const addProject = async (projectData) => {
    const token = await AuthTokenGenerator.getToken();
    return axios
        .post(appConfig.baseUrl + "/projects", projectData, {
            headers: {
                Authorization: token,
            }
        })
        .then(response => {
            return response.data.payload;
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response);
                throw error.response;
            } else {
                console.log(error);
                throw error;
            }
        });
};

export const newProjectService = {
    getExecutionLocations,
    getFacilityEquipmentScopes,
    getFacilityLocations,
    getSubBusinessLines,
    getAllUsers,
    getCurrentUser,
    addProject

};