import React, { useState } from "react";
import { Route, Switch as RouteSwitch } from "react-router";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ButtonAppBar } from "./AppBar/AppBar";
import Home from "./Home";
import NewProjectForm from "./NewProject/NewProjectForm";
import UserManagement from "./UserManagement/UserManagement";
import ProjectDetails from "./projectDetails/ProjectDetails";
import RegisterDetails from "./RegisterDetails/RegisterDetails";
import LevelDetails from "./LevelDetails/LevelDetails";
import ProjectUsers from "./ProjectUsers/ProjectUsers";
import RuleOfCredits from "./RuleOfCredits/RuleOfCredits";
import Activities from "./Activities/Activities";
import Report from "./Report/Report";
import LandingPage from "./LandingPage/LandingPage";
import MyActivities from "./MyActivities/MyActivities";

export const Layout = (props) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#005EBD",
      },
    },
  });

  //#region Shared AppBar Navigation
  const [pageTitle, setPageTitle] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  //#endregion

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ButtonAppBar pageTitle={pageTitle} viewMode={viewMode} />
      <RouteSwitch>
        <Route
          exact
          path="/"
          render={(props) => (
            <LandingPage
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/dashboard"
          render={(props) => (
            <Home
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/new-project"
          render={(props) => (
            <NewProjectForm
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/user-management"
          render={(props) => (
            <UserManagement
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/project-details/:id"
          render={(props) => (
            <ProjectDetails
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/register-details/:id"
          render={(props) => (
            <RegisterDetails
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/level-details/:id"
          render={(props) => (
            <LevelDetails
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/project-users/:id"
          render={(props) => (
            <ProjectUsers
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/rule-of-credits/:id"
          render={(props) => (
            <RuleOfCredits
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/activities/:id"
          render={(props) => (
            <Activities
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/report/:id"
          render={(props) => (
            <Report
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
        <Route
          path="/myActivities"
          render={(props) => (
            <MyActivities
              {...props}
              setPageTitle={setPageTitle}
              setViewMode={setViewMode}
            />
          )}
        />
      </RouteSwitch>
    </ThemeProvider>
  );
};
