import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import ProjectDetailsTabs from '../ProjectDetailsTabs/ProjectDetailsTabs';
import StyledTableCell from '../Shared/StyledTableCell/StyledTableCell';
import { ProjectsService } from '../../Service/ProjectsService';
import { UsersService } from '../../Service/UsersService';
import * as PROJECTS from '../constants/projects';
import './projectUsers.css';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';

const ProjectUsers = (props) => {

  //#region Component State
  const urlParams = useParams();
  const projectId = urlParams.id;
  const [project, setProject] = useState({ permissions: [] });
  const [showSpinner, setShowSpinner] = useState(false);
  const [userPermissions, setUserPermissions] = useState({
    systemAdmin: false,
    projectManager: false,
    projectRole: 0
  });

  const [unassignedUsers, setUnassignedUsers] = useState([]);
  const [unassignedUser, setUnassignedUser] = useState("");
  const [access, setAccess] = useState("");
  //#endregion

  //#region componentDidMount
  useEffect(() => {
    if (projectId) {
      _initialize();
    } else {
      alert('Invalid or no ID provided.');
      goHome();
    }
  }, []);

  const _initialize = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle("Project Users");
      props.setViewMode("admin");

      const [project, allUsers, currentUser] = await Promise.all([
        ProjectsService.getProjectById(projectId),
        UsersService.getUsers(),
        UsersService.getCurrentUser()
      ]);

      // Determine User permissions
      const permissions = _initializePermissions(currentUser, project);
      const validProjectRoles = [PROJECTS.Roles.ProjectAdmin, PROJECTS.Roles.InternalUser, PROJECTS.Roles.Viewer];

      if (
        !permissions.systemAdmin &&
        !permissions.projectManager &&
        !validProjectRoles.includes(permissions.projectRole)
      ) {
        alert('You do not have access to view the project');
        goHome();
      }

      const unassignedUsers = _getUnassignedUsers(allUsers, project.id);

      props.setPageTitle(`Project Users - ${project.name}`);
      setProject(project);
      setUnassignedUsers(unassignedUsers);
      setUserPermissions(permissions);
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  };

  const _initializePermissions = (currentUser, project) => {
    const systemAdmin = currentUser.admin;

    const projectManagerId = (project.manager) ? project.manager.id : null;
    const projectManager = currentUser.id === projectManagerId;

    const userPermission = currentUser.permissions.find(p => p.projectId === parseInt(projectId));
    const projectRole = (userPermission) ? userPermission.role : PROJECTS.Roles.None;

    return {
      systemAdmin,
      projectManager,
      projectRole
    };
  };

  const _getUnassignedUsers = (users, projectId) => {
    return users.filter(user => {
      const permissions = user.permissions;
      const foundPermission = permissions.find(pm => pm.projectId === projectId);
      return foundPermission === undefined;
    }).map(user => ({
      id: user.id,
      name: user.name,
      alias: user.alias
    }));
  };
  //#endregion

  //#region Navigation
  const history = useHistory();
  const goHome = () => {
    history.push('/');
  }
  //#endregion

  //#region New User Form
  const updateSelectUser = (event) => {
    setUnassignedUser(event.target.value);
  };

  const updateSelectProject = (event) => {
    setAccess(event.target.value);
  };

  const assignUserToProject = async () => {
    if (unassignedUser === "" || access === "") {
      window.alert('Please fill out the user registration form.');
      return;
    }

    try {
      setSnackbarSettings({ open: true, message: "Adding user to project...", severity: "info" });
      const updatedProject = await ProjectsService.addUserToProject(project, unassignedUser.id, access);

      // Remove added user from unassigned Users
      var updatedUnassignedUsers = [...unassignedUsers];
      updatedUnassignedUsers = updatedUnassignedUsers.filter(u => {
        return u.id !== unassignedUser.id;
      });

      setProject(updatedProject);
      setUnassignedUsers(updatedUnassignedUsers);
      clearUserForm();
      setSnackbarSettings({ open: true, message: "User added successfully", severity: "success" });
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  };

  const clearUserForm = () => {
    setUnassignedUser("");
    setAccess("");
  };
  //#endregion

  //#region Users Table
  const updateUserRole = async (userId, newRole) => {
    try {
      const projectPermissions = project.permissions;
      const aPermissionIndex = projectPermissions.findIndex(pm => pm.user.id === userId);
      const aPermission = projectPermissions[aPermissionIndex];

      // Update local state
      const updatedPermission = { ...aPermission };
      updatedPermission.role = newRole;

      const updatedProject = { ...project };
      updatedProject.permissions = [...projectPermissions];
      updatedProject.permissions[aPermissionIndex] = updatedPermission;

      setProject(updatedProject);

      const _projectFromDB = await ProjectsService.updateUserProjectRole(project, userId, newRole);
      setSnackbarSettings({ open: true, message: "User role updated", severity: "success" });
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveUser = async (aUser) => {
    try {
      const input = window.confirm('Are you sure you want to remove ' + aUser.name + ' from the project?');
      if (!input) { return; }

      setSnackbarSettings({ open: true, message: "Removing user from project...", severity: "info" });
      const updatedProject = await ProjectsService.removeUserFromProject(project, aUser.id);

      // Re-add removed user to unassigned users
      var updatedUnassignedUsers = [...unassignedUsers];
      updatedUnassignedUsers.push({
        id: aUser.id,
        name: aUser.name,
        alias: aUser.alias
      });

      setProject(updatedProject);
      setUnassignedUsers(updatedUnassignedUsers);
      setSnackbarSettings({ open: true, message: "User removed from project", severity: "success" });
    } catch (error) {
      console.log(error);
    }
  }
  //#endregion

  //#region Snackbar
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    message: "I <3 snacks",
    severity: "success"
  });

  const closeSnackbar = () => {
    setSnackbarSettings({ ...snackbarSettings, open: false });
  };
  //#endregion

  //#region Helpers
  const _userHasWriteAccess = () => {
    return (userPermissions.systemAdmin ||
      userPermissions.projectManager ||
      userPermissions.projectRole === PROJECTS.Roles.ProjectAdmin
    );
  };

  const _userHasReadOnlyAccess = () => {
    const readOnlyRoles = [PROJECTS.Roles.InternalUser, PROJECTS.Roles.Viewer];

    return (!userPermissions.systemAdmin &&
      !userPermissions.projectManager &&
      readOnlyRoles.includes(userPermissions.projectRole)
    );
  };
  //#endregion

    //#region popover
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const useStyles = makeStyles((theme) => ({
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      listHorizonalDisplay: {
        display: 'inline-block'
      }
    }));
    const classes = useStyles();
    //#endregion

  return (
    <div className="ProjectUsers">
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>

      <div className="fullWidth spaceEvenly marginTop marginBottom20">
        <ProjectDetailsTabs currentTab="projectUsers"></ProjectDetailsTabs>
      </div>

      {/* New User Form */}
      {_userHasWriteAccess() &&
        <div className="fullWidth spaceEvenly marginBottom20">
          <Grid item xs={10}>
            <Paper className="fullWidth" elevation={2}>
              <Typography variant="h5" component="h4" className="padding20">
                Add User
              </Typography>
              <form id="userToAdd" name="userToAdd" autoComplete="off" className="padding20">
                <Grid container spacing={3} alignItems="center">

                  {/* User */}
                  <Grid item lg={3} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="unassigned-user-label">User</InputLabel>
                      <Select
                        labelId="unassigned-user-label"
                        id="unassigned-user"
                        margin="dense"
                        label="User"
                        value={unassignedUser}
                        onChange={updateSelectUser}
                      >
                        {unassignedUsers.map(u =>
                          <MenuItem value={u} key={u.id}>{u.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Role */}
                  <Grid item lg={3} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="access-selector">Role</InputLabel>
                      <Select
                        labelId="access-selector"
                        id="access"
                        margin="dense"
                        label="Role"
                        value={access}
                        onChange={updateSelectProject}
                      >
                        <MenuItem value={1}>Project Admin</MenuItem>
                        <MenuItem value={2}>Internal User</MenuItem>
                        <MenuItem value={3}>External User</MenuItem>
                        <MenuItem value={4}>Viewer</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={1} xs={1}>
                    <Tooltip title="Role description and permissions" placement="top-start">
                     <HelpIcon onClick={handleOpen} className="helpButton" />
                    </Tooltip>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={open}>
                        <div className={classes.paper}>
                            <h2 id="transition-modal-title">Project Admin</h2>
                            <p id="transition-modal-description">
                              Project admin has read and write permission on all project data, <br/>
                              including configuration and users.
                            </p>

                            <h2 id="transition-modal-title">Internal User</h2>
                            <p id="transition-modal-description">
                              Internal User has read permission on all project data, <br />
                              and write permission only on assigned activities as "Responsible Party".
                            </p>

                            <h2 id="transition-modal-title">External User</h2>
                            <p id="transition-modal-description">
                              External User has read and write permission only on <br />
                              assigned activities as "Responsible Party".
                            </p>

                            <h2 id="transition-modal-title">Viewers</h2>
                            <p id="transition-modal-description">
                              Viewers has read only permission on all project data.
                            </p>
                        </div>
                      </Fade>
                    </Modal>
                  </Grid>

                  {/* Assign user */}
                  <Grid item lg={2} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PersonAddRoundedIcon />}
                      onClick={assignUserToProject}
                      fullWidth={true}>
                      Add
                    </Button>
                  </Grid>

                  {/* Clear Form */}
                  <Grid item lg={2} xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      onClick={clearUserForm}>
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </div>
      }

      {/* Users table */}
      <div className="fullWidth spaceEvenly ">
        <Grid item xs={10}>
          <Paper className="fullWidth" elevation={2}>
            <Typography variant="h5" component="h4" className="padding20">
              Project Users
            </Typography>
            <div className="padding20">
              <TableContainer component={Paper}>
                <Table aria-label="users-table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Alias</StyledTableCell>
                      <StyledTableCell>User Role</StyledTableCell>
                      {!_userHasReadOnlyAccess() &&
                        <StyledTableCell>Remove</StyledTableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {project.permissions.map(aPermission =>
                      <TableRow key={aPermission.user.id}>
                        <TableCell component="th" scope="row">
                          {aPermission.user.id}
                        </TableCell>
                        <TableCell>{aPermission.user.name}</TableCell>
                        <TableCell>{aPermission.user.alias}</TableCell>
                        <TableCell>
                          <FormControl variant="outlined">
                            <Select
                              id={"role-select-" + aPermission.user.id}
                              margin="dense"
                              value={aPermission.role}
                              onChange={(event) => updateUserRole(aPermission.user.id, event.target.value)}
                              inputProps={{
                                readOnly: _userHasReadOnlyAccess()
                              }}
                            >
                              <MenuItem value={1}>Project Admin</MenuItem>
                              <MenuItem value={2}>Internal User</MenuItem>
                              <MenuItem value={3}>External User</MenuItem>
                              <MenuItem value={4}>Viewer</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        {!_userHasReadOnlyAccess() &&
                          <TableCell>
                            <IconButton aria-label="delete" onClick={() => onRemoveUser(aPermission.user)}>
                              <HighlightOffRoundedIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                        }
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </Grid>
      </div>

      {/* Custom Snackbar */}
      <Snackbar
        key="custom-snackbar"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarSettings.open}
        onClose={closeSnackbar}
        autoHideDuration={3000}
      >
        <Alert
          elevation={6}
          variant="filled"
          iconMapping={{ info: <AutorenewRoundedIcon /> }}
          severity={snackbarSettings.severity}
        >
          {snackbarSettings.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ProjectUsers;