import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DashBoardTable from './Dashboard/DashBoardTable';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { homeService } from '../Service/homeService';

const Home = (props) => {
  
  const [showSpinner, setShowSpinner] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [projects, setProjects] = useState([]);

  //#region Initialization
  const _initModule = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle(null);
      props.setViewMode("admin");
      const tempCurrentUser = await homeService.getCurrentUser();

      if (tempCurrentUser.user.admin) {
        const allProjects = await homeService.getAllProjects();
        setProjects(allProjects.projects);
      } else {
        const projectsById = await Promise.all(tempCurrentUser.user.permissions.map((permission) => (
          homeService.getProjectById(permission.projectId)
        )))
        setProjects(projectsById);
      }


      setCurrentUser(tempCurrentUser.user);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    // Call DB to get users and projects
    _initModule();
  }, []);
  //#endregion

  return (
    <div className="fullWidth marginTop30">
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div className="fullWidth spaceEvenly ">
        <Grid item xs={11} lg={11} >
          <Typography variant="h3" component="h3" >
            My Dashboard
          </Typography>
        </Grid>
      </div>
      <div className="fullWidth spaceEvenly  marginTop30">
        <Grid item xs={11} lg={11} >
          <Paper className="fullWidth" elevation={2}>
            <DashBoardTable projects={projects} className="fullWidth" />
          </Paper>
        </Grid>
      </div>
    </div >
  );

}
export default Home;


