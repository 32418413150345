import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CategoriesService from "../../../Service/CategoriesService";

export const ProjectContext = createContext();

const ProjectProvider = ({ children }) => {
  const [unitsOfMeasurement, setUnitsOfmeasurement] = useState([]);
  const urlParams = useParams();
  const projectId = urlParams.id;

  useEffect(() => {
    fetchUnitsOfMeasurement();
  }, []);

  const fetchUnitsOfMeasurement = async () => {
    const units = await CategoriesService.getSecondaryUnitsOfMeasurement();
    setUnitsOfmeasurement(units);
  };

  return (
    <ProjectContext.Provider value={{ projectId, unitsOfMeasurement }}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
