import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

import ProjectDetailsTabs from "../ProjectDetailsTabs/ProjectDetailsTabs";
import ActivitiesLayout from "./ActivitiesLayout/ActivitiesLayout";
import LevelsView from "./LevelsView/LevelsView";
import { LevelsService } from "../../Service/LevelsService";
import { ActivitiesService } from "../../Service/ActivitiesService";
import * as PROJECTS from "../constants/projects";
import * as LEVELS from "../constants/levels";
import UserContext from "./context/userContext";
import "./activities.css";

import { dateFilter } from "../filters/date";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import ProjectProvider from "../Context/ProjectContext/ProjectContext";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ActivitySearch from "./ActivitySearch/ActivitySearch";
import { Grid, Paper } from "@material-ui/core";

const TabPanel = ({ index, tab, children }) => {
  return (
    <div
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      hidden={tab !== index}
    >
      {children}
    </div>
  );
};

const Activities = (props) => {
  // URL Parameters
  const urlParams = useParams();

  // Shared State
  const projectId = urlParams.id;
  const [registers, setRegisters] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [levelCategories, setLevelCategories] = useState([]);

  // Activities (Tree) View
  const [levels, setLevels] = useState([]);
  const [activitiesMap, setActivitiesMap] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [allActivities, setAllActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState();
  const [activitySearchId, setActivitySearchId] = useState();
  const [selectedLevel, setSelectedLevel] = useState({
    id: "",
    name: "",
    discipline: "",
    currency: { name: "" },
    manHour: "",
    cost: "",
    weighting: "",
    register: "",
    model: "",
  });

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  //#region Azure App Insights
  const appInsights = useAppInsightsContext();
  appInsights.trackMetric("[Activities] Component is in use");
  const trackSaveActivities = useTrackEvent(appInsights, "Save Activities");
  //#endregion

  let activitiesList = [];

  var checkForChild = (level, sequence, levelPath) => {
    if (level.hasOwnProperty("activities")) {
      const activityParent = level.activities.map((activity) => {
        return {
          id: activity.id,
          description: activity.description,
          parentId: level.id,
          steps: sequence,
          levelPath: levelPath
        }
      })
      activitiesList = [...activitiesList, ...activityParent];
    } else if (level.hasOwnProperty("children")) {
      level.children.forEach((level) => {
        checkForChild(level, [...sequence, level.id], [...levelPath, level.name]);
      });
    } else {
      return [];
    }
  };

  //#region componentDidMount
  useEffect(() => {
    if (projectId) {
      _initialize();
    } else {
      alert("Invalid or no ID provided.");
      goHome();
    }
  }, [projectId]);

  useEffect(() => {
    activitiesList = [];
    checkForChild(selectedLevel, [selectedLevel.id], [selectedLevel.name]);
    setAllActivities(activitiesList);
  }, [selectedLevel]);

  const _initialize = async () => {
    try {
      setDialogSettings({
        title: "Module Initialization In Progress",
        message:
          "Module Initialization can takes minutes. Please be patient and remain on the page.",
        open: true,
      });
      props.setPageTitle("Activities");
      props.setViewMode("admin");

      const [user, allLevels, project] = await Promise.all([
        ActivitiesService.getCurrentUser(),
        LevelsService.getLevelsByProjectId(projectId, { activities: true }),
        LevelsService.getProjectById(projectId),
      ]);

      // Check user permission
      const userObj = _setCurrentUserHandler(user, project);
      const validProjectRoles = [
        PROJECTS.Roles.ProjectAdmin,
        PROJECTS.Roles.InternalUser,
        PROJECTS.Roles.Viewer,
      ];

      if (
        !userObj.admin &&
        !userObj.projectManager &&
        !validProjectRoles.includes(userObj.role)
      ) {
        alert("You do not have access to view the project");
        goHome();
      }

      // Configure Registers
      const curretRegisters = LevelsService.searchRegisters({
        type: 0,
        children: allLevels,
      });

      const registerArchives = await Promise.all(
        curretRegisters.map((reg) => {
          return userObj.admin ||
            userObj.projectManager ||
            userObj.role === PROJECTS.Roles.ProjectAdmin
            ? LevelsService.getRegisterArchives(projectId, reg.id)
            : [];
        })
      );

      const updatedRegisters = curretRegisters.map((register, index) => {
        register.childrenIds = _getChildren(register);
        register.archives = registerArchives[index];
        return register;
      });

      props.setPageTitle(`Activities - ${project.name}`);
      setCurrentUser(userObj);
      setRegisters(updatedRegisters);
      _setActivitiesMapHandler(allLevels);
      setLevels(allLevels);
    } catch (error) {
      _basicErrorHandler(error);
    } finally {
      setDialogSettings({ ...dialogSettings, open: false });
    }
  };

  const _setCurrentUserHandler = (currentUser, project) => {
    const systemAdmin = currentUser.admin;

    const projectManagerId = project.manager ? project.manager.id : null;
    const projectManager = currentUser.id === projectManagerId;

    const userPermission = currentUser.permissions.find(
      (p) => p.projectId === parseInt(projectId)
    );
    const projectRole = userPermission
      ? userPermission.role
      : PROJECTS.Roles.None;

    return {
      id: currentUser.id,
      alias: currentUser.alias,
      name: currentUser.name,
      admin: systemAdmin,
      role: projectRole,
      projectManager,
    };
  };

  const _getChildren = (lvl) => {
    if (lvl.children && lvl.children.length > 0) {
      const childIds = lvl.children.map(_getChildren).flat();
      childIds.push(lvl.id);
      return childIds;
    } else {
      return [lvl.id];
    }
  };

  const _setActivitiesMapHandler = (allLevels) => {
    const activitiesMap = {};
    const fakeParentLevel = { children: allLevels };
    _checkLevelForActivities(fakeParentLevel, activitiesMap);
    setActivitiesMap(activitiesMap);
  };

  const _checkLevelForActivities = (currentLevel, activitiesMap) => {
    if (currentLevel.activities) {
      activitiesMap[currentLevel.id] = currentLevel.activities.map(
        (activity) => {
          const updatedActivity = { ...activity };

          // Format Dates for ROC Activity
          if (
            activity.configuration &&
            activity.configuration.ruleOfCreditSteps
          ) {
            const updatedSteps = activity.configuration.ruleOfCreditSteps.map(
              (step) => {
                const newStep = { ...step };

                if (step.plannedDate) {
                  newStep.plannedDate = _stringDateConverter(step.plannedDate);
                }
                if (step.forecastDate) {
                  newStep.forecastDate = _stringDateConverter(
                    step.forecastDate
                  );
                }
                if (step.actualDate) {
                  newStep.actualDate = _stringDateConverter(step.actualDate);
                }

                return newStep;
              }
            );

            updatedActivity.configuration = {
              ...activity.configuration,
              ruleOfCreditSteps: updatedSteps,
            };
          }
          // Format Dates for Quantity Activity
          else if (activity.configuration && activity.configuration.quantity) {
            const oldQuantity = activity.configuration.quantity;
            const updatedQuantity = { ...oldQuantity };

            if (oldQuantity.planStartDate) {
              updatedQuantity.planStartDate = _stringDateConverter(
                oldQuantity.planStartDate
              );
            }
            if (oldQuantity.planFinishDate) {
              updatedQuantity.planFinishDate = _stringDateConverter(
                oldQuantity.planFinishDate
              );
            }

            if (oldQuantity.forecastStartDate) {
              updatedQuantity.forecastStartDate = _stringDateConverter(
                oldQuantity.forecastStartDate
              );
            }
            if (oldQuantity.forecastFinishDate) {
              updatedQuantity.forecastFinishDate = _stringDateConverter(
                oldQuantity.forecastFinishDate
              );
            }

            if (oldQuantity.actualStartDate) {
              updatedQuantity.actualStartDate = _stringDateConverter(
                oldQuantity.actualStartDate
              );
            }
            if (oldQuantity.actualFinishDate) {
              updatedQuantity.actualFinishDate = _stringDateConverter(
                oldQuantity.actualFinishDate
              );
            }

            updatedActivity.configuration = {
              ...activity.configuration,
              quantity: updatedQuantity,
            };
          }

          return updatedActivity;
        }
      );
    } else if (currentLevel.children) {
      currentLevel.children.forEach((lvl) => {
        _checkLevelForActivities(lvl, activitiesMap);
      });
    } else {
      // Level has 0 children and 0 activities -> Do nothing
    }
  };
  //#endregion

  //#region Multi-Snackbar
  const [snackQueue, setSnackQueue] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackQueue.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackQueue[0] });
      setSnackQueue((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackQueue.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackQueue, messageInfo, open]);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const exitSnackbar = () => {
    setMessageInfo(undefined);
  };
  //#endregion

  //#region Navigation
  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };
  //#endregion

  //#region Level Tree
  const [parentRegister, setParentRegister] = useState({
    attributes: [],
    ruleOfCredits: [],
  });

  const onSelectLevelNode = (level) => {
    const cloneSelectedLevel = { ...level };
    if (cloneSelectedLevel.categories) {
      setLevelCategories(level.categories);
    } else if (levelCategories.length > 0) {
      cloneSelectedLevel.categories = levelCategories;
    }
    const lvlId = cloneSelectedLevel.id;
    // Determine parent register
    const parent = registers.find((rg) => rg.childrenIds.indexOf(lvlId) !== -1);
    setSelectedLevel(cloneSelectedLevel);
    setParentRegister(parent);
  };

  const getParentRegisterAttributes = () =>
    parentRegister &&
    parentRegister.attributes &&
    parentRegister.attributes.length > 0
      ? parentRegister.attributes
      : [];
  //#endregion

  const showSelectedActivityLevel = (steps, id) => {
    let childLevel = { ...selectedLevel };
    const fixStep = [...steps];
    fixStep.splice(0, 1);
    fixStep.forEach((step) => {
      if (childLevel.hasOwnProperty("children")) {
        childLevel = childLevel.children.find((child) => {
          return (child.id === step);
        });
      }
    });
    onSelectLevelNode(childLevel);
    setTabIndex(0);
    setActivitySearchId(id);
  };

  //#region ROC Activities Table
  const _getActivitiesByLevelId = () => {
    const lvlId = selectedLevel.id; // Should be a number or empty-string, never null/undefined

    // Lvl Id is a number AND Lvl Id has an activities array
    if (lvlId !== "" && !!activitiesMap[lvlId]) {
      return activitiesMap[lvlId];
    } else {
      return [];
    }
  };

  const onUpdateRocProgressDate = (
    lvlId,
    activityId,
    rocStepId,
    dateField,
    newDate
  ) => {
    const lvlActivities = activitiesMap[lvlId];

    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    const configurationSteps = anActivity.configuration.ruleOfCreditSteps;
    const stepIndex = configurationSteps.findIndex(
      (p) => p.stepId === rocStepId
    );
    const configurationStep = configurationSteps[stepIndex];

    // Update state
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        ruleOfCreditSteps: [...anActivity.configuration.ruleOfCreditSteps],
      },
    };

    const updatedConfigurationSteps =
      updatedActivities[anActivityIndex].configuration.ruleOfCreditSteps;
    updatedConfigurationSteps[stepIndex] = {
      ...configurationStep,
      [dateField]: newDate,
      dirty: true,
    };

    setActivitiesMap(updatedActivitiesMap);
  };

  const onAddNewRocProgress = (
    lvlId,
    activityId,
    ruleOfCreditId,
    rocStepId,
    dateField,
    newDate
  ) => {
    const lvlActivities = activitiesMap[lvlId];

    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update state
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = {
      ...anActivity,
      configuration: {
        ruleOfCreditId: ruleOfCreditId,
        ruleOfCreditSteps: [
          {
            stepId: rocStepId,
            [dateField]: newDate,
            dirty: true,
          },
        ],
      },
    };

    setActivitiesMap(updatedActivitiesMap);
  };

  const onAddNewRocProgressObj = (
    lvlId,
    activityId,
    rocStepId,
    dateField,
    newDate
  ) => {
    const lvlActivities = activitiesMap[lvlId];

    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update state
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        ruleOfCreditSteps: [
          ...anActivity.configuration.ruleOfCreditSteps,
          {
            stepId: rocStepId,
            [dateField]: newDate,
            dirty: true,
          },
        ],
      },
    };

    setActivitiesMap(updatedActivitiesMap);
  };

  // For now, only saving dirty ROC based progresses
  const saveActivities = async () => {
    trackSaveActivities();
    setSnackQueue((queue) => [
      ...queue,
      {
        message: "Saving activities...",
        severity: "info",
        key: new Date().getTime(),
      },
    ]);

    const progressObjsToSave = [];

    for (const levelId in activitiesMap) {
      const activities = activitiesMap[levelId];

      for (const activity of activities) {
        // ROC Activity
        if (activity.model === LEVELS.Model.RuleOfCredit) {
          if (
            activity.configuration &&
            activity.configuration.ruleOfCreditSteps
          ) {
            const ruleOfCreditProgresses =
              activity.configuration.ruleOfCreditSteps;

            for (const rocProg of ruleOfCreditProgresses) {
              if (!rocProg.dirty) {
                continue;
              }

              const rocProgData = {
                stepId: rocProg.stepId,
              };

              if (rocProg.plannedDate) {
                rocProgData.plan = rocProg.plannedDate;
              }
              if (rocProg.forecastDate) {
                rocProgData.forecast = rocProg.forecastDate;
              }
              if (rocProg.actualDate) {
                rocProgData.actual = rocProg.actualDate;
              }

              progressObjsToSave.push({
                levelId: levelId,
                activityId: activity.id,
                progressData: rocProgData,
              });
            }
          }
        } else if (activity.model === LEVELS.Model.Quantity) {
          if (activity.configuration && activity.configuration.quantity) {
            const aQuantity = activity.configuration.quantity;

            if (!aQuantity.dirty) {
              continue;
            }

            const updatedQuantity = {};

            if (aQuantity.planStartDate) {
              updatedQuantity.planStart = aQuantity.planStartDate;
            }
            if (aQuantity.planFinishDate) {
              updatedQuantity.planFinish = aQuantity.planFinishDate;
            }

            if (aQuantity.forecastStartDate) {
              updatedQuantity.forecastStart = aQuantity.forecastStartDate;
            }
            if (aQuantity.forecastFinishDate) {
              updatedQuantity.forecastFinish = aQuantity.forecastFinishDate;
            }

            if (aQuantity.actualStartDate) {
              updatedQuantity.actualStart = aQuantity.actualStartDate;
            }
            if (aQuantity.actualFinishDate) {
              updatedQuantity.actualFinish = aQuantity.actualFinishDate;
            }

            if (aQuantity.plannedQuantity) {
              updatedQuantity.plannedQuantity = aQuantity.plannedQuantity;
            }
            if (aQuantity.completedQuantity) {
              updatedQuantity.completedQuantity = aQuantity.completedQuantity;
            }
            if (aQuantity.primaryCategory) {
              updatedQuantity.primaryCategoryId = aQuantity.primaryCategory.id;
            }
            if (aQuantity.secondaryCategory) {
              updatedQuantity.secondaryCategoryId =
                aQuantity.secondaryCategory.id;
            }
            if (aQuantity.secondaryCategory) {
              updatedQuantity.secondaryCategoryId =
                aQuantity.secondaryCategory.id;
              updatedQuantity.secondaryPlannedQuantity =
                aQuantity.secondaryPlannedQuantity || 0;
              updatedQuantity.secondaryCompletedQuantity =
                aQuantity.secondaryCompletedQuantity || 0;
            }

            progressObjsToSave.push({
              levelId: levelId,
              activityId: activity.id,
              progressData: updatedQuantity,
            });
          }
        }
      }
    }

    try {
      // Bug?: Saving a NEW roc-based progress with only forecast/actuald date inserts w/ planned date of 01-01-0001
      await Promise.all(
        progressObjsToSave.map((prog) =>
          ActivitiesService.updateActivityProgress(
            projectId,
            prog.levelId,
            prog.activityId,
            prog.progressData
          )
        )
      );

      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Activities saved successfully...",
          severity: "success",
          key: new Date().getTime(),
        },
      ]);
    } catch (error) {
      _basicErrorHandler(error);
    }
  };

  const _stringDateConverter = (date) => {
    return dateFilter(date);
  };
  //#endregion

  //#region Quantity Activities Table
  const onUpdatePlannedQuantity = (lvlId, activityId, newPlannedQuantity) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update Quantity Activity
    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          plannedQuantity: newPlannedQuantity,
          dirty: true,
        },
      },
    };

    // Update State
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;

    setActivitiesMap(updatedActivitiesMap);
  };

  const onUpdateCompletedQuantity = (
    lvlId,
    activityId,
    newCompletedQuantity
  ) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update Quantity Activity
    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          completedQuantity: newCompletedQuantity,
          dirty: true,
        },
      },
    };

    // Update State
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;

    setActivitiesMap(updatedActivitiesMap);
  };

  const onUpdateForecastStartDate = (
    lvlId,
    activityId,
    newForecastStartDate
  ) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update Quantity Activity
    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          forecastStartDate: newForecastStartDate,
          dirty: true,
        },
      },
    };

    // Update State
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;

    setActivitiesMap(updatedActivitiesMap);
  };

  const onUpdateForecastFinishDate = (
    lvlId,
    activityId,
    newForecastFinishDate
  ) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update Quantity Activity
    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          forecastFinishDate: newForecastFinishDate,
          dirty: true,
        },
      },
    };

    // Update State
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;

    setActivitiesMap(updatedActivitiesMap);
  };

  const onUpdateActualStartDate = (lvlId, activityId, newActualStartDate) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update Quantity Activity
    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          actualStartDate: newActualStartDate,
          dirty: true,
        },
      },
    };

    // Update State
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;

    setActivitiesMap(updatedActivitiesMap);
  };

  const onUpdateActualFinishDate = (lvlId, activityId, newActualFinishDate) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    // Update Quantity Activity
    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          actualFinishDate: newActualFinishDate,
          dirty: true,
        },
      },
    };

    // Update State
    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;

    setActivitiesMap(updatedActivitiesMap);
  };

  const handleUpdateSecondaryCategory = (
    lvlId,
    activityId,
    secondaryCategory
  ) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    const newQuantity =
      secondaryCategory.id !== 0
        ? {
            ...anActivity.configuration.quantity,
            secondaryCategory,
            dirty: true,
          }
        : {
            ...anActivity.configuration.quantity,
            secondaryCategory: null,
            secondaryPlannedQuantity: null,
            secondaryCompletedQuantity: null,
            dirty: true,
          };

    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: newQuantity,
      },
    };

    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;

    setActivitiesMap(updatedActivitiesMap);
  };

  const handleUpdatePrimaryCategory = (lvlId, activityId, primaryCategory) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    const newQuantity =
      primaryCategory.id !== 0
        ? {
            ...anActivity.configuration.quantity,
            primaryCategory,
            dirty: true,
          }
        : {
            ...anActivity.configuration.quantity,
            primaryCategory: null,
            dirty: true,
          };

    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: newQuantity,
      },
    };

    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;
    setActivitiesMap(updatedActivitiesMap);
  };

  const handleUpdateSecondaryPlannedQuantity = (
    lvlId,
    activityId,
    secondaryPlannedQuantity
  ) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          secondaryPlannedQuantity,
          dirty: true,
        },
      },
    };

    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;
    setActivitiesMap(updatedActivitiesMap);
  };

  const handleUpdateSecondaryCompletedQuantity = (
    lvlId,
    activityId,
    secondaryCompletedQuantity
  ) => {
    const lvlActivities = activitiesMap[lvlId];
    const anActivityIndex = lvlActivities.findIndex((a) => a.id === activityId);
    const anActivity = lvlActivities[anActivityIndex];

    const updatedActivity = {
      ...anActivity,
      configuration: {
        ...anActivity.configuration,
        quantity: {
          ...anActivity.configuration.quantity,
          secondaryCompletedQuantity,
          dirty: true,
        },
      },
    };

    const updatedActivitiesMap = {
      ...activitiesMap,
      [lvlId]: [...lvlActivities],
    };

    const updatedActivities = updatedActivitiesMap[lvlId];
    updatedActivities[anActivityIndex] = updatedActivity;
    setActivitiesMap(updatedActivitiesMap);
  };
  //#endregion

  //#region Global Dialog
  const [dialogSettings, setDialogSettings] = useState({
    title: "",
    message: "",
    open: false,
  });
  //#endregion

  //#region Shared Private Helpers
  const _basicErrorHandler = (error) => {
    console.log(error);

    if (error.data && error.data.detail) {
      alert(error.data.detail);
    } else {
      alert(
        "An unexpected error ocurred. Please try again.\nIf error persists, contact the support team."
      );
    }
  };
  //#endregion

  return (
    <UserContext.Provider value={currentUser}>
      <div className="Activities">
        <Backdrop className="backDropZIndex" open={showSpinner}>
          <CircularProgress color="primary" />
        </Backdrop>

        <div className="fullWidth spaceEvenly marginTop marginBottom20">
          <ProjectDetailsTabs currentTab="activities"></ProjectDetailsTabs>
        </div>

        <LevelsView
          levels={levels}
          registers={registers}
          selectedLevel={selectedLevel}
          onSelectLevelNode={(level) => {
            onSelectLevelNode(level)
            setActivitySearchId("")
          }}  
          selectedActivityId={selectedActivity}
        />
        <div className="activities-table-tile space-evenly full-width">
          <Grid item xs={10}>
            <Paper className="root" elevation={3}>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="spaceEvenly marginBottom20"
              >
                <Tab label="Levels" value={0} />
                <Tab label="Search Activities" value={1} />
              </Tabs>
            </Paper>
          </Grid>
        </div>

        <TabPanel tab={tabIndex} index={0}>
          <ProjectProvider>
            <ActivitiesLayout
              selectedLevel={selectedLevel}
              activities={_getActivitiesByLevelId()}
              attributes={getParentRegisterAttributes()}
              ruleOfCredits={
                parentRegister ? parentRegister.ruleOfCredits : null
              }
              onUpdateRocProgressDate={onUpdateRocProgressDate}
              onAddNewRocProgress={onAddNewRocProgress}
              onAddNewRocProgressObj={onAddNewRocProgressObj}
              onUpdatePlannedQuantity={onUpdatePlannedQuantity}
              onUpdateCompletedQuantity={onUpdateCompletedQuantity}
              onUpdateForecastStartDate={onUpdateForecastStartDate}
              onUpdateForecastFinishDate={onUpdateForecastFinishDate}
              onUpdateActualStartDate={onUpdateActualStartDate}
              onUpdateActualFinishDate={onUpdateActualFinishDate}
              saveActivities={saveActivities}
              onUpdateSecondaryCategory={handleUpdateSecondaryCategory}
              onUpdatePrimaryCategory={handleUpdatePrimaryCategory}
              onUpdateSecondaryPlannedQuantity={
                handleUpdateSecondaryPlannedQuantity
              }
              onUpdateSecondaryCompletedQuantity={
                handleUpdateSecondaryCompletedQuantity
              }
              activitySearchId={activitySearchId}
            />
          </ProjectProvider>
        </TabPanel>
        <TabPanel tab={tabIndex} index={1}>
          <ActivitySearch selectedLevel={selectedLevel} activities={allActivities} activitySelected={(steps, id) => {
            showSelectedActivityLevel(steps, id)
            setSelectedActivity(steps)
          }}/>
        </TabPanel>

        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={4000}
          onClose={closeSnackbar}
          onExited={exitSnackbar}
        >
          <Alert
            elevation={6}
            variant="filled"
            iconMapping={{ info: <AutorenewRoundedIcon /> }}
            severity={messageInfo ? messageInfo.severity : undefined}
          >
            {messageInfo ? messageInfo.message : undefined}
          </Alert>
        </Snackbar>

        <Dialog aria-labelledby="dialog-title" open={dialogSettings.open}>
          <DialogTitle id="dialog-title">{dialogSettings.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              {dialogSettings.message}
            </DialogContentText>
            <LinearProgress style={{ marginBottom: "12px" }} />
          </DialogContent>
        </Dialog>
      </div>
    </UserContext.Provider>
  );
};

export default Activities;
