import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const getTemplateLevels = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/levels/template", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.template.levels;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const getLevelsByProjectId = async (projectId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/projects/" + projectId + '/levels', {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.levels;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const getCurrencies = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/currencies/", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.currencies;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const createL1 = async (projectId, l1Data) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .post(appConfig.baseUrl + "/projects/" + projectId + "/levels", l1Data, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.level.id;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const createL2 = async (projectId, l2Data) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .post(appConfig.baseUrl + "/projects/" + projectId + "/levels", l2Data, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.level;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const deleteLevel = async (projectId, levelId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .delete(appConfig.baseUrl + "/projects/" + projectId + "/levels/" + levelId, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const getProjectById = async (id) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/projects/" + id, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const updateLevel = async (projectId, level) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .put(appConfig.baseUrl + "/projects/" + projectId + "/levels", level, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const getLevel = async (projectId, levelId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(`${appConfig.baseUrl}/projects/${projectId}/levels/${levelId}`, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.level;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

export const levelDetailsService = {
  getTemplateLevels,
  getLevelsByProjectId,
  createL1,
  deleteLevel,
  createL2,
  getCurrencies,
  getProjectById,
  updateLevel,
  getLevel
};