import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshIcon from "@material-ui/icons/Refresh";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import { ReportsService } from "../../Service/ReportsService";
import { dateFilter } from "../filters/date";

const ReportRerun = ({ projectId }) => {
  const [reportToRun, setReportToRun] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [lastReports, setLastReports] = useState([]);
  const [resultDialog, setResultDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  const handleReportChange = (report) => {
    setReportToRun(report);
  };

  const requestUpdateReport = async () => {
    try {
      const response = await ReportsService.replaceReport(
        projectId,
        reportToRun
      );
      if (response.length) {
        setResultDialog({
          open: true,
          title: "Sucess",
          message: "Reports scheduled to be replaced.",
        });
      } else {
        setResultDialog({
          open: true,
          title: "Sucess",
          message: "Report scheduled to be replaced.",
        });
      }
    } catch (error) {
      setResultDialog({
        open: true,
        title: error.data.title,
        message: error.data.detail,
      });
    } finally {
      setAlertOpen(false);
    }
  };

  const handleClickUpdate = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleResultClose = () => {
    setResultDialog({
      open: false,
      title: "",
      message: "",
    });
  };

  const _initialize = async () => {
    const lastReportData = await ReportsService.getLastReports(projectId);
    if (lastReportData) {
      const reports = lastReportData.map((reportData) => {
        return {
          type: reportData.type,
          description:
            (reportData.type === 0 ? "Weekly" : "Monthly") +
            " - Last run: " +
            dateFilter(reportData.runTime),
        };
      });
      if (reports.length > 1) {
        reports.push({
          type: 2,
          description: "Both",
        });
      }
      setLastReports(reports);
    }
  };

  useEffect(() => {
    if (projectId) {
      _initialize();
    }
  }, [projectId]);

  return (
    <div className="rerun-report">
      <Typography variant="h5" component="h5" className="padding20">
        Rerun report
      </Typography>

      <Grid container justify="flex-start" spacing={3} className="padding20">
        <Grid item lg={4} xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="report-rerun-label">Report</InputLabel>
            <Select
              id="report-rerun"
              labelId="report-rerun-label"
              label="Report"
              margin="dense"
              value={reportToRun}
              disabled={lastReports.length === 0}
              onChange={(e) => handleReportChange(e.target.value)}
            >
              {lastReports.map((report) => (
                <MenuItem key={report.type} value={report.type}>
                  {report.description}
                </MenuItem>
              ))}
            </Select>
            {lastReports.length === 0 && (
              <FormHelperText>No Reports to be updated</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item lg={3} xs={12}>
          <Button
            color="primary"
            variant="contained"
            fullWidth={true}
            startIcon={<RefreshIcon />}
            onClick={handleClickUpdate}
            disabled={reportToRun === undefined}
          >
            Update report
          </Button>
        </Grid>
      </Grid>

      <Dialog open={alertOpen} onClose={handleAlertClose}>
        <DialogTitle>Update report?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to update these reports, this action cannot be
            reverted! The new report data will reflect in Power BI reports in
            about an hour.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={requestUpdateReport} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={resultDialog.open} onClose={handleResultClose}>
        <DialogTitle>{resultDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{resultDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResultClose} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReportRerun;
