import React from "react";

import ROCSteps from "./ROCSteps";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";

import "./rocAccordion.css";

const ROCActivityAccordion = ({
  activity,
  rocName,
  steps,
  onSaveActivityProgress,
}) => {
  const getActivityStatusModifier = () => {
    switch (activity.status) {
      case 0:
        return "past";
      case 1:
        return "urgent";
      case 2:
      case 3:
      default:
        return "upcoming";
    }
  };

  const getStepChipColor = (rocStep) => {
    if (rocStep.actualDate) {
      return "LimeGreen";
    } else {
      switch (rocStep.status) {
        case 0:
          return "#DA3A3A";
        case 1:
          return "#FEBB4C";
        case 2:
        case 3:
        default:
          return "#DFDADA";
      }
    }
  };

  const handleSaveStep = (step) => {
    onSaveActivityProgress({ ...step, activityId: activity.id });
  };

  const ActivityPath = ({ path }) => {
    const getPathString = () => path.join(" > ");
    return <span className="activity-path">{getPathString()}</span>;
  };

  return (
    <Accordion className="roc-accordion">
      <AccordionSummary
        id="activitySummary"
        aria-controls="panel1a-content"
        className={`roc-accordion-summary roc-accordion-summary--${getActivityStatusModifier()}`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container justify="space-between">
          <Grid item xs={6} container justify="flex-start">
            <Typography variant="h6" className="section-label">
              Activity Name
            </Typography>
            <ActivityPath path={activity.path} />
            <Typography
              variant="h6"
              className="section-value"
              style={{ height: "32px" }}
            >
              {activity.description}
            </Typography>
          </Grid>

          <Grid item xs={6} container justify="flex-end">
            <Typography
              variant="h6"
              className="section-label"
              style={{ textAlign: "end" }}
            >
              Rule of Credit
            </Typography>
            <Typography
              variant="h6"
              className="section-value"
              style={{ textAlign: "end", height: "32px" }}
            >
              {rocName}
            </Typography>
          </Grid>

          <Grid item xs={6} container justify="flex-start">
            <Typography variant="h6" className="section-label">
              Steps Status
            </Typography>
            <Box className="status-chip-container">
              {steps.map((step) => (
                <Chip
                  key={step.id}
                  className="roc-status"
                  style={{ backgroundColor: getStepChipColor(step) }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails id="activityDetails" style={{ padding: 0 }}>
        <ROCSteps steps={steps} onSaveStep={handleSaveStep} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ROCActivityAccordion;
