import React, { useContext, useState, useEffect } from "react";

import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import CategoriesTable from "./CategoriesTable";

import CategoriesService from "../../../Service/CategoriesService";

import useExpandableRow from "../useExpandableRow";
import { ProjectContext } from "../../Context/ProjectContext/ProjectContext";

const ChildCategories = ({ child, readOnly, onResult }) => {
  const [isExpanded, toggleExpand] = useExpandableRow(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categories, setCategories] = useState([]);
  const [isNameError, setIsNameError] = useState(false);
  const { projectId } = useContext(ProjectContext);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const levelCategories = await CategoriesService.getCategories(
      projectId,
      child.id
    );
    setCategories(levelCategories);
  };

  const handleNewNameChange = (e) => {
    if (e.target.value.length > 0 && isNameError) {
      setIsNameError(false);
    }
    setNewCategoryName(e.target.value);
  };

  const handleClickNewCategory = async () => {
    if (newCategoryName.length > 0) {
      setIsNameError(false);
      let newPrimaryCategory = {
        name: newCategoryName,
        children: [],
      };
      newPrimaryCategory = await CategoriesService.addCategory(
        projectId,
        child.id,
        newPrimaryCategory
      );
      setCategories([...categories, newPrimaryCategory]);
      setNewCategoryName("");
    } else {
      setIsNameError(true);
    }
  };

  const handleAddSecondary = async (newSecondary) => {
    const catCopy = [...categories];
    const catToUpdate = catCopy.find((c) => c.id === newSecondary.parentId);
    const newCategory = await CategoriesService.addCategory(
      projectId,
      child.id,
      newSecondary
    );
    if (!catToUpdate.children) {
      catToUpdate.children = [];
    }
    catToUpdate.children.push(newCategory);
    setCategories(catCopy);
  };

  const handleRemoveCategory = async (categoryToDelete) => {
    try {
      const response = await CategoriesService.deleteCategory(
        projectId,
        child.id,
        categoryToDelete
      );
      if (response.status === 200 || response.status === 204) {
        removeCategoryFromList(categoryToDelete);
      }
      onResult(response);
    } catch (error) {
      onResult(error);
    }
  };

  const removeCategoryFromList = (categoryToDelete) => {
    if (categoryToDelete.parentId) {
      const categoryChildren = categories.find(
        (cat) => cat.id === categoryToDelete.parentId
      ).children;
      const newChildren = categoryChildren.filter(
        (cat) => cat.id !== categoryToDelete.id
      );
      const catCopy = [...categories];
      catCopy.find((c) => c.id === categoryToDelete.parentId).children =
        newChildren;
      setCategories(catCopy);
    } else {
      const newCategories = categories.filter(
        (cat) => cat.id !== categoryToDelete.id
      );
      setCategories(newCategories);
    }
  };

  return (
    <>
      <TableRow key={child.id} className="child-row main-row">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleExpand}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {child.id}
        </TableCell>
        <TableCell>{child.name}</TableCell>
        <TableCell>{child.discipline}</TableCell>
      </TableRow>
      <TableRow className="categories-row collapsable-row">
        <TableCell colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              className="category-section-title"
            >
              Primary Categories
            </Typography>
            {!readOnly && (
              <Grid container spacing={3} className="new-categories-grid">
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="newName"
                      label="Name"
                      variant="outlined"
                      size="small"
                      value={newCategoryName}
                      onChange={handleNewNameChange}
                      required
                      error={isNameError}
                      helperText={isNameError ? "Required" : ""}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <div className="fullWidth">
                    <Grid item lg={9} xs={12}>
                      <Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        onClick={handleClickNewCategory}
                        startIcon={<AddCircleOutlineRoundedIcon />}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
            <CategoriesTable
              categories={categories}
              onCategoryDelete={handleRemoveCategory}
              readOnly={readOnly}
              onAddSecondary={handleAddSecondary}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ChildCategories;
