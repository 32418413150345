import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import ProjectDetailsTabs from "../ProjectDetailsTabs/ProjectDetailsTabs";
import RegisterRow from "./RegisterRow/RegisterRow";
import RegisterCategories from "./RegisterCategories/RegisterCategories";
import StyledTableCell from "../Shared/StyledTableCell/StyledTableCell";
import { LevelsService } from "../../Service/LevelsService";
import { AttributesService } from "../../Service/AttributesService";
import { UsersService } from "../../Service/UsersService";
import * as PROJECTS from "../constants/projects";
import "./registerDetails.css";

import Alert from "@material-ui/lab/Alert";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ProjectProvider from "../Context/ProjectContext/ProjectContext";

const TabPanel = ({ index, tab, children }) => {
  return (
    <div
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      hidden={tab !== index}
    >
      {children}
    </div>
  );
};

export default function RegisterDetails(props) {
  //#region Component State
  const urlParams = useParams();
  const projectId = urlParams.id;

  const [project, setProject] = useState({});
  const [registers, setRegisters] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [userPermissions, setUserPermissions] = useState({
    systemAdmin: false,
    projectManager: false,
    projectRole: 0,
  });
  const [currentTab, setCurrentTab] = useState(0);

  //#endregion

  //#region componentDidMount
  useEffect(() => {
    if (projectId) {
      _initialize();
    } else {
      alert("Invalid or no ID provided.");
      goHome();
    }
  }, []);

  const _initialize = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle("Registers");
      props.setViewMode("admin");

      const [registers, attributes, currencies, project, currentUser] =
        await Promise.all([
          LevelsService.getRegisters(projectId),
          AttributesService.getAttributes(),
          AttributesService.getCurrencies(),
          LevelsService.getProjectById(projectId),
          UsersService.getCurrentUser(),
        ]);

      // Bug Fix: Registers can exist w/o attributes
      registers.forEach(function (item) {
        if (item.attributes === undefined) {
          item.attributes = [];
        }
      });

      // Determine User permissions
      const permissions = _initializePermissions(currentUser, project);
      const validProjectRoles = [
        PROJECTS.Roles.ProjectAdmin,
        PROJECTS.Roles.InternalUser,
        PROJECTS.Roles.Viewer,
      ];

      if (
        !permissions.systemAdmin &&
        !permissions.projectManager &&
        !validProjectRoles.includes(permissions.projectRole)
      ) {
        alert("You do not have access to view the project");
        goHome();
      }

      props.setPageTitle(`Registers - ${project.name}`);
      setProject(project);
      setRegisters(registers);
      setAttributes(attributes);
      setCurrencies(currencies);
      setUserPermissions(permissions);
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setShowSpinner(false);
    }
  };

  const _initializePermissions = (currentUser, project) => {
    const systemAdmin = currentUser.admin;

    const projectManagerId = project.manager ? project.manager.id : null;
    const projectManager = currentUser.id === projectManagerId;

    const userPermission = currentUser.permissions.find(
      (p) => p.projectId === parseInt(projectId)
    );
    const projectRole = userPermission
      ? userPermission.role
      : PROJECTS.Roles.None;

    return {
      systemAdmin,
      projectManager,
      projectRole,
    };
  };
  //#endregion

  //#region Navigation
  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };
  //#endregion

  //#region Attributes Event Handlers
  const addNewAttributeHandler = async (registerId, name, description) => {
    try {
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Adding new attribute...",
          severity: "info",
          key: new Date().getTime(),
        },
      ]);

      // Get register
      const registerIndex = registers.findIndex((r) => r.id === registerId);
      const aRegister = registers[registerIndex];

      // Add new attribute
      const newAttribute = await AttributesService.addAttribute(
        name,
        description
      );
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "New attribute saved successfully",
          severity: "success",
          key: new Date().getTime(),
        },
      ]);

      // Insert into attributes array
      const updatedAttributes = [...attributes, newAttribute];
      setAttributes(updatedAttributes);

      // Update local Register
      const updatedRegister = {
        ...aRegister,
        attributes: [...aRegister.attributes, newAttribute],
      };

      const updatedRegistersArray = [...registers];
      updatedRegistersArray[registerIndex] = updatedRegister;
      setRegisters(updatedRegistersArray);

      const tmpRegister = await LevelsService.updatedLevel(
        projectId,
        updatedRegister
      );
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Register updated successfully",
          severity: "success",
          key: new Date().getTime(),
        },
      ]);
    } catch (error) {
      setSnackQueue((queue) => [
        ...queue,
        {
          message: error.data.detail,
          severity: "error",
          key: new Date().getTime(),
        },
      ]);
    }
  };

  const removeAttributeHandler = async (registerId, attributeId) => {
    try {
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Removing attribute...",
          severity: "info",
          key: new Date().getTime(),
        },
      ]);

      // Get register
      const registerIndex = registers.findIndex((r) => r.id === registerId);
      const aRegister = registers[registerIndex];

      // Update Register - Remove attribute
      const updatedRegister = {
        ...aRegister,
        attributes: aRegister.attributes.filter((a) => a.id !== attributeId),
      };

      // Update Registers State
      const updatedRegisters = [...registers];
      updatedRegisters[registerIndex] = updatedRegister;
      setRegisters(updatedRegisters);

      // Save register in DB
      const tmpRegister = await LevelsService.updatedLevel(
        projectId,
        updatedRegister
      );
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Attribute removed successfully",
          severity: "success",
          key: new Date().getTime(),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const addExistingAttributesHandler = async (registerId, attributesToAdd) => {
    try {
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Assigning attributes...",
          severity: "info",
          key: new Date().getTime(),
        },
      ]);

      // Get register
      const registerIndex = registers.findIndex((r) => r.id === registerId);
      const aRegister = registers[registerIndex];

      // Update Registers state
      const updatedRegister = {
        ...aRegister,
        attributes: aRegister.attributes.concat([...attributesToAdd]),
      };

      const updatedRegisters = [...registers];
      updatedRegisters[registerIndex] = updatedRegister;
      setRegisters(updatedRegisters);

      const tmpRegister = await LevelsService.updatedLevel(
        projectId,
        updatedRegister
      );
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Attributes assigned successfully",
          severity: "success",
          key: new Date().getTime(),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region Snackbar
  const [snackQueue, setSnackQueue] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackQueue.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackQueue[0] });
      setSnackQueue((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackQueue.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackQueue, messageInfo, open]);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const exitSnackbar = () => {
    setMessageInfo(undefined);
  };

  const changeTab = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const onResult = (response) => {
    if (response.status === 200 || response.status === 204) {
      setSnackQueue((queue) => [
        ...queue,
        {
          message: "Success",
          severity: "success",
          key: new Date().getTime(),
        },
      ]);
    } else {
      setSnackQueue((queue) => [
        ...queue,
        {
          message: response.data.detail,
          severity: "error",
          key: new Date().getTime(),
        },
      ]);
    }
  };
  //#endregion

  return (
    <div className="RegisterDetails">
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>

      <div className="fullWidth spaceEvenly marginTop marginBottom20">
        <ProjectDetailsTabs currentTab="register"></ProjectDetailsTabs>
      </div>

      <div className="fullWidth spaceEvenly marginTop30">
        <Grid item xs={10}>
          <Paper elevation={2} className="padding20">
            <Typography variant="h5" component="h5" className="marginBottom20">
              Registers
            </Typography>

            <Tabs
              value={currentTab}
              onChange={changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              className="spaceEvenly marginBottom20"
            >
              <Tab label="Attributes" value={0} key={0} />
              <Tab label="Categories" value={1} key={1} />
            </Tabs>

            <TabPanel tab={currentTab} index={0}>
              {registers && registers.length > 0 ? (
                <div>
                  <TableContainer component={Paper} className="">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell />
                          <StyledTableCell align="left">ID</StyledTableCell>
                          <StyledTableCell align="left">Name</StyledTableCell>
                          <StyledTableCell align="left">
                            WBS Code
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Currency
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cost/Man Hours
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Progress System
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {registers.map((register, index) => (
                          <RegisterRow
                            key={register.id}
                            register={register}
                            attributes={
                              attributes !== undefined ? attributes : []
                            }
                            userPermissions={userPermissions}
                            onAddNewAttribute={addNewAttributeHandler}
                            onRemoveAttribute={removeAttributeHandler}
                            onAddExistingAttributes={
                              addExistingAttributesHandler
                            }
                          ></RegisterRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <Grid container justify="center" className="padding20">
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    There are no Registers for this Project.
                  </Grid>
                </Grid>
              )}
            </TabPanel>
            <TabPanel tab={currentTab} index={1}>
              {registers && registers.length > 0 ? (
                <div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell />
                          <StyledTableCell align="left">ID</StyledTableCell>
                          <StyledTableCell align="left">Name</StyledTableCell>
                          <StyledTableCell align="left">
                            WBS Code
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Progress System
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <ProjectProvider>
                          {registers.map((register, index) => (
                            <RegisterCategories
                              key={register.id}
                              register={register}
                              userPermissions={userPermissions}
                              onResult={onResult}
                            ></RegisterCategories>
                          ))}
                        </ProjectProvider>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <Grid container justify="center" className="padding20">
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    There are no Registers for this Project.
                  </Grid>
                </Grid>
              )}
            </TabPanel>
          </Paper>
        </Grid>
      </div>

      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        onExited={exitSnackbar}
      >
        <Alert
          elevation={6}
          variant="filled"
          iconMapping={{ info: <AutorenewRoundedIcon /> }}
          severity={messageInfo ? messageInfo.severity : undefined}
        >
          {messageInfo ? messageInfo.message : undefined}
        </Alert>
      </Snackbar>
    </div>
  );
}
