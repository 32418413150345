import React, { useState } from "react";

const useExpandableRow = (defaultExpanded) => {
  const [isExpanded, setRowExpanded] = useState(defaultExpanded);

  const toggleExpand = () => {
    setRowExpanded(!isExpanded);
  };

  return [isExpanded, toggleExpand];
};

export default useExpandableRow;
