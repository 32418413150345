import React, { useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DATE_FORMAT } from "../../constants/strings";
import "./quantity.css";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  activityDatePicker: {
    backgroundColor: "rgba(255,255,255,0.12)",
    borderRadius: "4px 4px 0 0",
    margin: "0 0 20px 0",
    "& > div": {
      margin: 0,
      padding: "22px 16px 10px 16px",
    },
    "& > label": {
      left: "16px",
      top: "10px",
    },
  },
  numericTextFieldRoot: {
    "& > div": {
      paddingLeft: "16px",
    },
    "& > label": {
      paddingLeft: "16px",
    },
  },
  statusChipContainer: {
    "& .rocStatus": {
      height: "9px",
      margin: "0 1px",
      width: "9px",
    },
    "& .rocStatus:first-child": {
      marginLeft: "0",
    },
    "& .rocStatus:last-child": {
      marginRight: "0",
    },
  },
}));

const Accordion = withStyles({
  root: {
    borderRadius: "5px",
    margin: "8px 0",
    "&.MuiAccordion-root:before": {
      display: "none",
    },
    "&:first-child": {
      borderRadius: "5px",
    },
    "&:last-child": {
      borderRadius: "5px",
    },
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#F1F4F9",
    borderRadius: "5px",
    "&$expanded": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const QuantityActivityAccordion = ({ key, activity, saveQuantityProgress }) => {
  const classes = useStyles();

  const [quantityDetails, setQuantityDetails] = useState({
    ...activity.configuration.quantity,
  });

  const onFieldChange = (fieldName, newValue) => {
    setQuantityDetails({ ...quantityDetails, [fieldName]: newValue });
  };

  const handleSaveActivity = () => {
    saveQuantityProgress(activity, quantityDetails);
  };

  const getActivityStatusProps = () => {
    const chipProps = {
      label: null,
      style: {
        backgroundColor: null,
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.25px",
        lineHeight: "20px",
        textAlign: "Center",
        width: "100px",
      },
    };

    const { actualStartDate, actualFinishDate } =
      activity.configuration.quantity;

    if (actualStartDate && actualFinishDate) {
      chipProps.label = "Complete";
      chipProps.style.backgroundColor = "LimeGreen";
    } else {
      switch (activity.status) {
        case 0:
          chipProps.label = "Past Due";
          chipProps.style.backgroundColor = "rgba(218,58,58,0.87)";
          break;
        case 1:
          chipProps.label = "Due in 3 days";
          chipProps.style.backgroundColor = "rgba(255,152,0,0.87)";
          break;
        case 2:
        case 3:
        default:
          chipProps.label = "In progress";
          chipProps.style.backgroundColor = "rgba(199,208,216,0.87)";
      }
    }
    return chipProps;
  };

  const ActivityPath = ({ path }) => {
    const getPathString = () => path.join(" > ");
    return <span className="activity-path">{getPathString()}</span>;
  };

  return (
    <Accordion className="quantity-accordion">
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container justify="space-between">
          <Grid item xs={6} container justify="flex-start">
            <Typography variant="h6" className="section-label">
              Activity Name
            </Typography>
            <ActivityPath path={activity.path} />
            <Typography
              variant="h6"
              className="section-value"
              style={{ height: "32px" }}
            >
              {activity.description}
            </Typography>
          </Grid>

          <Grid item xs={6} container justify="flex-end" alignItems="center">
            <Box>
              <Chip {...getActivityStatusProps()} />
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ backgroundColor: "#E8ECF2" }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {/* Planned Dates */}
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={`plannedStartDate-${activity.id}`}
                  className={classes.activityDatePicker}
                  disableToolbar
                  variant="inline"
                  format={DATE_FORMAT}
                  margin="normal"
                  label={`Planned Start`}
                  value={quantityDetails.planStartDate ?? null}
                  minDate={null}
                  onChange={() => {}}
                  autoOk={true}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  readOnly={false}
                  InputProps={{ readOnly: false }}
                  disabled={true}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={`plannedFinishDate-${activity.id}`}
                  className={classes.activityDatePicker}
                  disableToolbar
                  variant="inline"
                  format={DATE_FORMAT}
                  margin="normal"
                  label={`Planned Finish`}
                  value={quantityDetails.planFinishDate ?? null}
                  minDate={null}
                  onChange={() => {}}
                  autoOk={true}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  readOnly={false}
                  InputProps={{ readOnly: false }}
                  disabled={true}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={`forecastStartDate-${activity.id}`}
                  className={classes.activityDatePicker}
                  disableToolbar
                  variant="inline"
                  format={DATE_FORMAT}
                  margin="normal"
                  label={`Forecast Start`}
                  value={quantityDetails.forecastStartDate ?? null}
                  minDate={null}
                  onChange={(newDate) =>
                    onFieldChange("forecastStartDate", newDate)
                  }
                  autoOk={true}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  readOnly={false}
                  InputProps={{ readOnly: false }}
                  disabled={activity.configuration.quantity.actualStartDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={`forecastFinishDate-${activity.id}`}
                  className={classes.activityDatePicker}
                  disableToolbar
                  variant="inline"
                  format={DATE_FORMAT}
                  margin="normal"
                  label={`Forecast Finish`}
                  value={quantityDetails.forecastFinishDate ?? null}
                  onChange={(newDate) =>
                    onFieldChange("forecastFinishDate", newDate)
                  }
                  autoOk={true}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  readOnly={false}
                  InputProps={{ readOnly: false }}
                  disabled={activity.configuration.quantity.actualFinishDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={`actualStartDate-${activity.id}`}
                  className={classes.activityDatePicker}
                  disableToolbar
                  variant="inline"
                  format={DATE_FORMAT}
                  margin="normal"
                  label={`Actual Start`}
                  value={quantityDetails.actualStartDate ?? null}
                  onChange={(newDate) =>
                    onFieldChange("actualStartDate", newDate)
                  }
                  autoOk={true}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  readOnly={false}
                  InputProps={{ readOnly: false }}
                  disabled={activity.configuration.quantity.actualStartDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={`actualFinishDate-${activity.id}`}
                  className={classes.activityDatePicker}
                  disableToolbar
                  variant="inline"
                  format={DATE_FORMAT}
                  margin="normal"
                  label={`Actual Finish`}
                  value={quantityDetails.actualFinishDate ?? null}
                  onChange={(newDate) =>
                    onFieldChange("actualFinishDate", newDate)
                  }
                  autoOk={true}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  readOnly={false}
                  InputProps={{ readOnly: false }}
                  disabled={activity.configuration.quantity.actualFinishDate}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" fullWidth>
              <TextField
                id={`totalQuantity-${activity.id}`}
                label={`Total Quantity`}
                type={"number"}
                value={quantityDetails.plannedQuantity}
                onChange={(event) =>
                  onFieldChange("plannedQuantity", event.target.value)
                }
                variant="standard"
                classes={{
                  root: classes.numericTextFieldRoot,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" fullWidth>
              <TextField
                id={`completedQuantity-${activity.id}`}
                label={`Completed Quantity`}
                type={"number"}
                value={quantityDetails.completedQuantity}
                onChange={(event) =>
                  onFieldChange("completedQuantity", event.target.value)
                }
                variant="standard"
                classes={{
                  root: classes.numericTextFieldRoot,
                }}
              />
            </FormControl>
          </Grid>
          <Grid container justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              justifySelf="end"
              onClick={handleSaveActivity}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuantityActivityAccordion;
