import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import "powerbi-report-authoring";

import ProjectDetailsTabs from "../ProjectDetailsTabs/ProjectDetailsTabs";
import ProgressReport from "./ProgressReport/ProgressReport";
import { ProjectsService } from "../../Service/ProjectsService";
import { PowerBIService } from "../../Service/PowerBIService";
import { LevelsService } from "../../Service/LevelsService";
import { UsersService } from "../../Service/UsersService";
import * as PROJECTS from "../constants/projects";
import pbiConfig from "./embedConfig";
import "./report.css";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReportRerun from "./ReportRerun";

const TabPanel = ({ index, tab, children }) => {
  return (
    <div
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      hidden={tab !== index}
    >
      {children}
    </div>
  );
};

const Report = (props) => {
  // URL Parameters
  const urlParams = useParams();

  // History
  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };

  // Component State
  const projectId = urlParams.id;
  const [project, setProject] = useState(false);
  const [levels, setLevels] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPBIError, setShowPBIError] = useState({
    visible: false,
    message: "",
  });
  const [liveError, setLiveError] = useState(null);

  // Report Tabs
  const [selectedTab, setSelectedTab] = useState(0);
  const onClickTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // PowerBI Report object (to be received via callback)
  const [report, setReport] = useState(null);

  // Report config useState hook
  // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
      },
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.Custom,
      },
    },
  });

  // Callback method to get the embedded PowerBI entity object
  const getEmbeddedComponent = async (embedObject) => {
    setReport(embedObject);
  };

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      "loaded",
      () => {
        setShowSpinner(false);
      },
    ],
    ["rendered"],
    [
      "error",
      (event) => {
        // Wipe out PBI report and display error message
        setShowPBIError({
          visible: true,
          message: "Failed to correctly render the Power BI report.",
        });
      },
    ],
  ]);

  // componentDidMount
  useEffect(() => {
    if (projectId) {
      _initialize();
    } else {
      alert("Invalid or no ID provided.");
      goHome();
    }
  }, []);

  const _initialize = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle("Reports");
      props.setViewMode("admin");

      const [accessTokenResponse, project, levels, currentUser] =
        await Promise.all([
          PowerBIService.getAccessToken(),
          ProjectsService.getProjectById(projectId),
          LevelsService.getLevelsByProjectId(projectId, { activities: true }),
          UsersService.getCurrentUser(),
        ]);

      // Kick out user if they have no valid access
      const permissions = _initializePermissions(currentUser, project);
      const validProjectRoles = [
        PROJECTS.Roles.ProjectAdmin,
        PROJECTS.Roles.InternalUser,
        PROJECTS.Roles.Viewer,
      ];
      if (
        !permissions.systemAdmin &&
        !permissions.projectManager &&
        !validProjectRoles.includes(permissions.projectRole)
      ) {
        alert("You do not have access to view the project");
        goHome();
      }

      // Fetch Report Embed Token
      const reportData = await PowerBIService.getReportInGroup(
        pbiConfig.workspaceId,
        pbiConfig.reportId,
        accessTokenResponse
      );
      const embedTokenResponse = await PowerBIService.generateEmbedToken(
        projectId,
        pbiConfig.reportId,
        reportData.datasetId,
        accessTokenResponse
      );

      // Configure Project-Level Slicers
      const projectSlicers = _configureProjectSlicers(project, levels);

      // Configure Run Time Slicers
      const jobRunTimeSlicers = _configureRunTimeSlicers();

      props.setPageTitle(`Reports - ${project.name}`);
      setProject(project);
      setLevels(levels);
      setReportConfig({
        ...reportConfig,
        embedUrl: reportData.embedUrl,
        accessToken: embedTokenResponse.data.token,
        filters: [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "JobReportDefinitions",
              column: "ProjectId",
            },
            operator: "In",
            values: [project.id],
            filterType: models.FilterType.BasicFilter,
            requireSingleSelection: true,
          },
        ],

        slicers: [...projectSlicers, ...jobRunTimeSlicers],
      });
    } catch (error) {
      const errorObj = {
        visible: true,
        message: "Failed to load the Power BI report.",
      };

      if (error.data && error.data.error) {
        if (error.data.error.code === "TokenExpired") {
          errorObj.message =
            "Failed to load the Power BI report because the access token expired.";
        } else if (error.data.error.code === "PowerBIEntityNotFound") {
          errorObj.message =
            "Failed to load the Power BI report because the embed token is invalid.";
        }
      }

      setShowSpinner(false);
      setShowPBIError(errorObj);
    }
  };

  const _initializePermissions = (currentUser, project) => {
    const systemAdmin = currentUser.admin;

    const projectManagerId = project.manager ? project.manager.id : null;
    const projectManager = currentUser.id === projectManagerId;

    const userPermission = currentUser.permissions.find(
      (p) => p.projectId === parseInt(projectId)
    );
    const projectRole = userPermission
      ? userPermission.role
      : PROJECTS.Roles.None;

    return {
      systemAdmin,
      projectManager,
      projectRole,
    };
  };

  const _configureProjectSlicers = (project, levels) => {
    const newPbiConfig = { ...pbiConfig };
    newPbiConfig.projectSlicers[0].slicerValues = [
      `${project.name} [${project.id}]`,
    ];
    newPbiConfig.projectSlicers[1].slicerValues = ["--Select--"];
    newPbiConfig.projectSlicers[2].slicerValues = ["--Select--"];
    newPbiConfig.projectSlicers[3].slicerValues = [
      `${project.name} [${project.id}]`,
    ];
    newPbiConfig.projectSlicers[4].slicerValues = ["--Select--"];
    newPbiConfig.projectSlicers[5].slicerValues = ["--Select--"];
    newPbiConfig.projectSlicers[6].slicerValues = ["--Select--"];

    // Configure PBI Filters and Slicers
    return newPbiConfig.projectSlicers.map((slicerObj) =>
      _generateSlicerState(
        slicerObj.name,
        slicerObj.tableName,
        slicerObj.columnName,
        slicerObj.slicerValues
      )
    );
  };

  const _configureRunTimeSlicers = () => {
    return pbiConfig.jobRunTimeSlicers.map((slicerObj) => ({
      selector: new models.VisualSelector(slicerObj.name),
      state: {
        filters: [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: slicerObj.table,
              column: slicerObj.column,
            },
            operator: "In",
            values: ["--Select--"],
          },
        ],
      },
    }));
  };

  const _generateSlicerState = (
    slicerName,
    tableName,
    columnName,
    slicerValues
  ) => ({
    selector: new models.VisualSelector(slicerName), // Internal Visual Name
    state: {
      filters: [
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: tableName,
            column: columnName,
          },
          operator: "In",
          values: slicerValues,
        },
      ],
    },
  });

  const onChangeCutOffDate = async (newCutOffDate) => {
    try {
      setShowSpinner(true);

      const params = {
        activities: true,
        cutOffDate: newCutOffDate,
      };

      const newLevels = await LevelsService.getLevelsByProjectId(
        projectId,
        params
      );
      setLevels(newLevels);
      setLiveError(null);
      setShowSpinner(false);
    } catch (error) {
      setLiveError("Failed to fetch levels data");
      setShowSpinner(false);
    }
  };

  return (
    <div className="Report">
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>

      <div className="fullWidth spaceEvenly marginTop marginBottom20">
        <ProjectDetailsTabs currentTab="report"></ProjectDetailsTabs>
      </div>

      <div className="fullWidth spaceEvenly marginBottom20">
        <Grid item xs={10}>
          <Paper className="fullWidth" elevation={2}>
            <Tabs
              value={selectedTab}
              onChange={onClickTab}
              aria-label="report-tabs"
              indicatorColor="secondary"
              textColor="secondary"
              centered
            >
              <Tab label="Live" id="tab-0" controls="tabpanel-0" />
              <Tab label="Power BI" id="tab-1" controls="tabpanel-1" />
            </Tabs>

            <TabPanel tab={selectedTab} index={0}>
              <ProgressReport
                levels={levels}
                onChangeCutOffDate={onChangeCutOffDate}
                liveError={liveError}
              />
            </TabPanel>

            <TabPanel tab={selectedTab} index={1}>
              {!showPBIError.visible ? (
                <PowerBIEmbed
                  embedConfig={reportConfig}
                  getEmbeddedComponent={getEmbeddedComponent}
                  eventHandlers={eventHandlersMap}
                  cssClassName={"embedContainer"}
                />
              ) : (
                <Grid container justify="center" className="padding20">
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    {showPBIError.message}
                  </Grid>
                </Grid>
              )}
            </TabPanel>
            <ReportRerun projectId={project.id} />
          </Paper>
        </Grid>
      </div>
    </div>
  );
};

export default Report;
