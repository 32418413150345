import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import './projectDetailsTabs.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const ProjectDetailsTabs = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [tabs] = useState([
    {
      label: 'Project Details',
      value: 'projectDetails'
    },
    {
      label: 'WBS',
      value: 'wbs'
    },
    {
      label: 'Project Attributes',
      value: 'register'
    },
    {
      label: 'Project Users',
      value: 'projectUsers'
    },
    {
      label: 'RULE OF CREDIT',
      value: 'ruleOfCredits'
    },
    {
      label: 'Activities',
      value: 'activities'
    },
    {
      label: 'Report',
      value: 'report'
    }
  ]);

  const changeTab = (event, newValue) => {
    switch (newValue) {
      case 'register':
        history.push('/register-details/' + id);
        break;
      case 'projectDetails':
        history.push('/project-details/' + id);
        break;
      case 'wbs':
        history.push('/level-details/' + id);
        break;
      case 'projectUsers':
        history.push('/project-users/' + id);
        break;
      case 'ruleOfCredits':
        history.push('/rule-of-credits/' + id);
        break;
      case 'activities':
        history.push('/activities/' + id);
        break;
      case 'report':
        history.push('/report/' + id);
        break;
      default:
        history.push('/');
    }
  };

  return (
    <Grid item lg={10} xs={12} className="ProjectDetailsTabs">
      <Paper className="root" elevation={3}>
        <Tabs
          value={props.currentTab}
          onChange={changeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          className="spaceEvenly"
        >
          {tabs.map((tab) =>
            <Tab label={tab.label} value={tab.value} key={tab.value} />
          )}
        </Tabs>
      </Paper>
    </Grid>
  );
};

export default ProjectDetailsTabs;