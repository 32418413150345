// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { SimpleProvider, ProviderState } from "@microsoft/mgt";
import { appConfig } from "../../appConfig";

// Msal Configurations
const config = {
  auth: {
    authority: appConfig.authority.api,
    clientId: appConfig.clientId,
    responseType: "code",
    redirectUri: window.location.origin,
    // replyUrlsWithType: "spa",
    validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true,
  },
  cache: {
    // cacheLocation: "sessionStorage",
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const authenticationParameters = {
  scopes: appConfig.scopes.api,
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html",
  navigateToLoginRequestUrl: false,
};

let authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);

let graphProvider = new SimpleProvider(async () => {
  const graphScopes = [
    "User.Read",
    "User.ReadBasic.All",
    "People.Read",
    "Contacts.Read",
  ];

  let token = await authProvider.getAccessToken({ scopes: graphScopes });
  return token.accessToken;
});
graphProvider.setState(ProviderState.SignedIn);

export { graphProvider, authProvider };
