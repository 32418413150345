import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { newProjectService } from '../../Service/NewProjectService';
import FormHelperText from '@material-ui/core/FormHelperText';
import './newProjectForm.css';
import { DATE_FORMAT } from '../constants/strings';

class NewProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      projectName: '',
      externalReference: '',
      client: '',
      contractualStart: null,
      contractualEnd: null,
      forecastStart: null,
      forecastEnd: null,
      facilityEquipment: '',
      subBusinessLine: '',
      executionLocation: '',
      facilityLocation: '',
      projectManager: '',
      actualStart: null,
      actualEnd: null,
      cutOffMonthDay: '',
      cutOffWeekDay: '',
      projectAdmins: [],
      projectAdminOptions: [],
      projectManagerOptions: [],
      facilityEquipmentOptions: [],
      subBusinessLineOptions: [],
      executionLocationOptions: [],
      facilityLocationOptions: [],
      cutOffMonthDays: [...Array(28).keys()].map(k => k + 1),
      cutOffWeekDays: [
        { label: 'Sunday', value: 0 },
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 }
      ],
      errors: {
        projectName: {
          error: false,
          helperText: ''
        },
        projectNumber: {
          error: false,
          helperText: ''
        },
        externalReference: {
          error: false,
          helperText: ''
        },
        client: {
          error: false,
          helperText: ''
        },
        facilityEquipment: {
          error: false,
          helperText: ''
        },
        subBusinessLine: {
          error: false,
          helperText: ''
        },
        executionLocation: {
          error: false,
          helperText: ''
        },
        facilityLocation: {
          error: false,
          helperText: ''
        },
        contractualStart: {
          error: false,
          helperText: ''
        },
        contractualEnd: {
          error: false,
          helperText: ''
        },
        projectAdmins: {
          error: false,
          helperText: ''
        },
        projectManager: {
          error: false,
          helperText: ''
        },
        forecastEnd: {
          error: false,
          helperText: ''
        },
        actualEnd: {
          error: false,
          helperText: ''
        },
        cutOffWeekDay: {
          error: false,
          helperText: ''
        },
        cutOffMonthDay: {
          error: false,
          helperText: ''
        },
      },

    };

    this.props.setPageTitle("New Project");
    this.props.setViewMode("admin");
  }

  setShowSpinner = (arg) => {
    this.setState({
      showSpinner: arg
    })
  }

  setExecutionLocationOptions = (args) => {
    this.setState({
      executionLocationOptions: args
    })
  }

  setFacilityLocationOptions = (args) => {
    this.setState({
      facilityLocationOptions: args
    })
  }
  setSubBusinessLineOptions = (args) => {
    this.setState({
      subBusinessLineOptions: args
    })
  }
  setFacilityEquipmentScopeOptions = (args) => {
    this.setState({
      facilityEquipmentOptions: args
    })
  }
  setProjectManagerOptions = (args) => {
    this.setState({
      projectManagerOptions: args
    })
  }
  setProjectAdminOptions = (args) => {
    this.setState({
      projectAdminOptions: args
    })
  }

  componentDidMount = async () => {
    try {
      this.setShowSpinner(true);
      const tempCurrentUser = await newProjectService.getCurrentUser();

      if (tempCurrentUser.user.admin) {
        const [
          tempUsers,
          tempExLoc,
          tempFacLoc,
          tempSubLine,
          tempFacEquip
        ] = await Promise.all([newProjectService.getAllUsers(),
        newProjectService.getExecutionLocations(),
        newProjectService.getFacilityLocations(),
        newProjectService.getSubBusinessLines(),
        newProjectService.getFacilityEquipmentScopes()
        ])

        this.setExecutionLocationOptions(tempExLoc.executionLocations);
        this.setFacilityLocationOptions(tempFacLoc.facilityLocations);
        this.setSubBusinessLineOptions(tempSubLine.subBusinessLines);
        this.setFacilityEquipmentScopeOptions(tempFacEquip.facilityEquipmentScopes);
        this.setProjectManagerOptions(tempUsers.users);
        this.setProjectAdminOptions(tempUsers.users);
      } else {
        alert("Only system admins can create new projects.")
        this.props.history.push('/');
      }
    }
    catch (err) {
      console.error(err);
    }
    finally {
      this.setShowSpinner(false);
    }

  }

  /* navigates home */
  goHome = () => {
    this.props.history.push('/');
  }

  /* these 6 functions change the date to what the user picks */
  handleContractualStartChange = (date) => {
    this.setState({
      contractualStart: date
    })
  };
  handleContractualEndChange = (date) => {
    this.setState({
      contractualEnd: date
    })
  };
  handleForecastStartChange = (date) => {
    this.setState({
      forecastStart: date
    })
  };
  handleForecastEndChange = (date) => {
    this.setState({
      forecastEnd: date
    })
  };
  handleActualEndDateChange = (date) => {
    this.setState({
      actualEnd: date
    })
  };

  handleActualStartDateChange = (date) => {
    this.setState({
      actualStart: date
    })
  };
  /* these 4 functions change the select option when the user selects a new option */
  handleFacilityEquipmentChange = (event) => {
    this.setState({
      facilityEquipment: event.target.value
    })
  };
  handleSubBusinessLineChange = (event) => {
    this.setState({
      subBusinessLine: event.target.value
    })
  };
  handleExecutionLocationChange = (event) => {
    this.setState({
      executionLocation: event.target.value
    })
  };
  handleFacilityLocationChange = (event) => {
    this.setState({
      facilityLocation: event.target.value
    })
  };

  /* these 3 functions change the select options for the people drop downs */

  handleProjectManagerChange = (event) => {
    this.setState({
      projectManager: event.target.value
    })
  };

  handleProjectAdminsChange = (event) => {
    this.setState({
      projectAdmins: event.target.value
    })
  };

  handleProjectCoordinatorsChange = (event) => {
    this.setState({
      projectCoordinators: event.target.value
    })
  };
  handleMonthlyCutoffDayChange = (event) => {
    this.setState({
      cutOffMonthDay: event.target.value
    })
  };

  handleWeeklyCutoffDayChange = (event) => {
    this.setState({
      cutOffWeekDay: event.target.value
    })
  };

  validateNewProjectForm = () => {

    let formValid = true;
    let errorObject = {
      projectName: {
        error: false,
        helperText: ''
      },
      projectNumber: {
        error: false,
        helperText: ''
      },
      externalReference: {
        error: false,
        helperText: ''
      },
      client: {
        error: false,
        helperText: ''
      },
      facilityEquipment: {
        error: false,
        helperText: ''
      },
      subBusinessLine: {
        error: false,
        helperText: ''
      },
      executionLocation: {
        error: false,
        helperText: ''
      },
      facilityLocation: {
        error: false,
        helperText: ''
      },
      contractualStart: {
        error: false,
        helperText: ''
      },
      contractualEnd: {
        error: false,
        helperText: ''
      },
      projectAdmins: {
        error: false,
        helperText: ''
      },
      projectManager: {
        error: false,
        helperText: ''
      },
      forecastEnd: {
        error: false,
        helperText: ''
      },
      actualEnd: {
        error: false,
        helperText: ''
      },
      cutOffWeekDay: {
        error: false,
        helperText: ''
      },
      cutOffMonthDay: {
        error: false,
        helperText: ''
      },
    }
    if (!this.state.projectName) {
      formValid = false;
      errorObject.projectName.error = true;
      errorObject.projectName.helperText = 'Project Name is required';
    }
    if (!this.state.projectNumber) {
      formValid = false;
      errorObject.projectNumber.error = true;
      errorObject.projectNumber.helperText = 'Project Number is required';
    }
    if (!this.state.facilityEquipment) {
      formValid = false;
      errorObject.facilityEquipment.error = true;
      errorObject.facilityEquipment.helperText = 'Facility Equipment Scope is required';
    }
    if (this.state.cutOffWeekDay === '') {
      formValid = false;
      errorObject.cutOffWeekDay.error = true;
      errorObject.cutOffWeekDay.helperText = 'Weekly Cutoff is required';
    }
    if (!this.state.cutOffMonthDay) {
      formValid = false;
      errorObject.cutOffMonthDay.error = true;
      errorObject.cutOffMonthDay.helperText = 'Monthly Cutoff is required';
    }
    if (!this.state.subBusinessLine) {
      formValid = false;
      errorObject.subBusinessLine.error = true;
      errorObject.subBusinessLine.helperText = 'Sub Business Line is required';
    }
    if (!this.state.executionLocation) {
      formValid = false;
      errorObject.executionLocation.error = true;
      errorObject.executionLocation.helperText = 'Execution Location is required';
    }
    if (!this.state.facilityLocation) {
      formValid = false;
      errorObject.facilityLocation.error = true;
      errorObject.facilityLocation.helperText = 'Facility Location is required';
    }
    if (!this.state.externalReference) {
      formValid = false;
      errorObject.externalReference.error = true;
      errorObject.externalReference.helperText = 'External Reference is required';
    }
    if (!this.state.client) {
      formValid = false;
      errorObject.client.error = true;
      errorObject.client.helperText = 'Client is required';
    }
    if (!this.state.contractualStart) {
      formValid = false;
      errorObject.contractualStart.error = true;
      errorObject.contractualStart.helperText = 'Contractual Start is required';
    }
    if (!this.state.contractualEnd) {
      formValid = false;
      errorObject.contractualEnd.error = true;
      errorObject.contractualEnd.helperText = 'Contractual End is required';
    }
    if (!this.state.projectAdmins.length > 0) {
      formValid = false;
      errorObject.projectAdmins.error = true;
      errorObject.projectAdmins.helperText = 'Project Admins are required';
    }
    if (!this.state.projectManager) {
      formValid = false;
      errorObject.projectManager.error = true;
      errorObject.projectManager.helperText = 'Project Manager is required';
    }

    if (this.state.contractualEnd < this.state.contractualStart) {
      formValid = false;
      errorObject.contractualEnd.error = true;
      errorObject.contractualEnd.helperText = 'Contractual End is required';
    }
    if (this.state.forecastEndDate < this.state.forecastStartDate) {
      formValid = false;
      errorObject.forecastEndDate.error = true;
      errorObject.forecastEndDate.helperText = 'Forecast End cannot be before Forecast Start';
    }

    this.setState({
      errors: errorObject
    })

    return formValid;

  }

  submitNewProject = async () => {
    if (this.validateNewProjectForm()) {
      try {
        this.setShowSpinner(true);
        let projectData = {
          "name": this.state.projectName,
          "client": this.state.client,
          "internalReference": this.state.projectNumber,
          "externalReference": this.state.externalReference,
          "contractualStartDate": this.state.contractualStart,
          "contractualEndDate": this.state.contractualEnd,
          "forecastStartDate": this.state.forecastStart,
          "forecastEndDate": this.state.forecastEnd,
          "managerId": this.state.projectManager,
          "facilityEquipmentScopeId": this.state.facilityEquipment,
          "subBusinessLineId": this.state.subBusinessLine,
          "executionLocationId": this.state.executionLocation,
          "facilityLocationId": this.state.facilityLocation,
          "actualStartDate": this.state.actualStart,
          "actualEndDate": this.state.actualEnd,
          "cutOffWeekDay": this.state.cutOffWeekDay,
          "cutOffMonthDay": this.state.cutOffMonthDay,
          "permissions": this.state.projectAdmins.map((admin) => (
            {
              userId: admin.id,
              role: 1
            }
          ))
        }
        const addProject = await newProjectService.addProject(projectData);
        console.log(addProject);
        this.props.history.push('/project-details/' + addProject.project.id)
      }
      catch (error) {
        console.error(error);
      }
    } else {
      alert("Please make sure all the required fields are filled properly.");
    }


  };


  render() {
    return (
      <div className="fullWidth spaceEvenly marginTop30">
        <Backdrop className="backDropZIndex" open={this.state.showSpinner}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Grid item xs={10} >
          <Paper className="fullWidth" elevation={2}>

            <Typography variant="h3" component="h3" className="padding20">
              New Project Form
                                </Typography>
            <form id="newProject" name="newProject" autoComplete="off" className="padding20">
              <Grid container spacing={3} >
                {/* Project Name Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      ref="projectName"
                      id="outlined-basic"
                      label="Project Name"
                      variant="outlined"
                      size="small"
                      value={this.state.projectName}
                      onChange={event => {
                        this.setState({
                          ...this.state, projectName: event.target.value
                        })
                      }}
                      error={this.state.errors.projectName.error}
                      helperText={this.state.errors.projectName.helperText}
                      required />
                  </FormControl>
                </Grid>
                {/* Project Number */}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      ref="projectNumber"
                      className="fullWidth"
                      id="outlined-basic"
                      label="Project Number"
                      variant="outlined"
                      size="small"
                      required
                      value={this.state.projectNumber}
                      error={this.state.errors.projectNumber.error}
                      helperText={this.state.errors.projectNumber.helperText}
                      onChange={event => {
                        this.setState({
                          ...this.state, projectNumber: event.target.value
                        })
                      }}
                    />
                  </FormControl >
                </Grid>
                {/* External Reference */}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      ref="externalReference"
                      className="fullWidth"
                      id="outlined-basic"
                      label="External Reference"
                      variant="outlined"
                      size="small"
                      required
                      value={this.state.externalReference}
                      error={this.state.errors.externalReference.error}
                      helperText={this.state.errors.externalReference.helperText}
                      onChange={event => {
                        this.setState({
                          ...this.state, externalReference: event.target.value
                        })
                      }}
                    />
                  </FormControl >
                </Grid>
                {/* Client Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField ref="client"
                      className="fullWidth"
                      id="outlined-basic"
                      label="Client"
                      variant="outlined"
                      size="small"
                      value={this.state.client}
                      onChange={event => {
                        this.setState({
                          ...this.state, client: event.target.value
                        })
                      }}
                      error={this.state.errors.client.error}
                      helperText={this.state.errors.client.helperText}
                      required />
                  </FormControl >
                </Grid>
                {/* Project Manager Required */}
                <Grid item lg={6} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="project-manager" >Project Manager</InputLabel>
                    <Select
                      labelId="project-manager"
                      id="demo-simple-select-outlined"
                      label="Facility Equipment Scope"
                      margin="dense"
                      value={this.state.projectManager}
                      onChange={this.handleProjectManagerChange}
                      required
                      error={this.state.errors.projectManager.error}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {this.state.projectManagerOptions.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>{option.name} </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.projectManager.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Project Admins Required*/}
                <Grid item lg={6} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="demo-mutiple-chip-label">Project Admins</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={this.state.projectAdmins}
                      onChange={this.handleProjectAdminsChange}
                      input={<Input id="select-multiple-chip" />}
                      margin="dense"
                      renderValue={(selected) => (
                        <div className="flexWrap">
                          {selected.map((option) => (
                            <Chip key={option.id} label={option.name} />
                          ))}
                        </div>
                      )}
                      error={this.state.errors.projectAdmins.error}
                      required
                    >
                      {this.state.projectAdminOptions.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          <Checkbox color="primary" checked={this.state.projectAdmins.indexOf(option) > -1} />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.projectAdmins.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Facility Equipment Scope */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required >
                    <InputLabel id="facility-label" >Facility Equipment Scope</InputLabel>
                    <Select
                      labelId="facility-label"
                      id="demo-simple-select-outlined"
                      label="Facility Equipment Scope"
                      margin="dense"
                      value={this.state.facilityEquipment}
                      onChange={this.handleFacilityEquipmentChange}
                      error={this.state.errors.facilityEquipment.error}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {this.state.facilityEquipmentOptions.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>{option.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.facilityEquipment.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Sub Business Line */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="sub-business-line-label">Sub Business Line</InputLabel>
                    <Select
                      labelId="sub-business-line-label"
                      id="demo-simple-select-outlined"
                      label=" Sub Business Line"
                      margin="dense"
                      value={this.state.subBusinessLine}
                      onChange={this.handleSubBusinessLineChange}
                      error={this.state.errors.subBusinessLine.error}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {this.state.subBusinessLineOptions.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>{option.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.subBusinessLine.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Execution Location */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="execution-location-label" >Execution Location</InputLabel>
                    <Select
                      labelId="execution-location-label"
                      id="demo-simple-select-outlined"
                      label="Execution Location"
                      margin="dense"
                      value={this.state.executionLocation}
                      onChange={this.handleExecutionLocationChange}
                      error={this.state.errors.executionLocation.error}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {this.state.executionLocationOptions.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>{option.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.executionLocation.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Facility Location */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="facility-location-label" >Facility Location</InputLabel>
                    <Select
                      labelId="facility-location-label"
                      id="demo-simple-select-outlined"
                      label="Facility Location"
                      margin="dense"
                      value={this.state.facilityLocation}
                      onChange={this.handleFacilityLocationChange}
                      error={this.state.errors.facilityLocation.error}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {this.state.facilityLocationOptions.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>{option.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.facilityLocation.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Contractual Start Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="normal"
                        id="date-picker-inline"
                        label="Contractual Start"
                        value={this.state.contractualStart}
                        onChange={this.handleContractualStartChange}
                        autoOk={true}
                        error={this.state.errors.contractualStart.error}
                        helperText={this.state.errors.contractualStart.helperText}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Contractual End Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="normal"
                        id="date-picker-inline"
                        label="Contractual End"
                        minDate={this.state.contractualStart}
                        value={this.state.contractualEnd}
                        onChange={this.handleContractualEndChange}
                        autoOk={true}
                        error={this.state.errors.contractualEnd.error}
                        helperText={this.state.errors.contractualEnd.helperText}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Forecast Start */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="normal"
                        id="date-picker-inline"
                        label="Forecast Start"
                        value={this.state.forecastStart}
                        onChange={this.handleForecastStartChange}
                        autoOk={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Forecast End */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="normal"
                        id="date-picker-inline"
                        label="Forecast End"
                        minDate={this.state.forecastStart}
                        value={this.state.forecastEnd}
                        onChange={this.handleForecastEndChange}
                        autoOk={true}
                        error={this.state.errors.forecastEnd.error}
                        helperText={this.state.errors.forecastEnd.helperText}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Actual Start */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="actual-start-date"
                        label="Actual Start"
                        value={this.state.actualStart}
                        onChange={this.handleActualStartDateChange}
                        autoOk={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Actual End */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="actual-end-date"
                        label="Actual End"
                        minDate={this.state.actualStart}
                        value={this.state.actualEnd}
                        onChange={this.handleActualEndDateChange}
                        autoOk={true}
                        error={this.state.errors.actualEnd.error}
                        helperText={this.state.errors.actualEnd.helperText}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Monthly Cutoff Date */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="monthly-cutoff-date-label" >Monthly Cutoff Day</InputLabel>
                    <Select
                      id="monthly-cutoff-date"
                      labelId="monthly-cutoff-date-label"
                      label="Monthly Cutoff Day"
                      margin="dense"
                      value={this.state.cutOffMonthDay}
                      defaultValue={1}
                      onChange={this.handleMonthlyCutoffDayChange}
                      error={this.state.errors.cutOffMonthDay.error}
                      required
                    >
                      {this.state.cutOffMonthDays.map((option, index) => (
                        <MenuItem value={option} key={'cutOffMonthDay_' + option}>{option}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.cutOffMonthDay.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
                {/* Weekly Cutoff Date */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="weekly-cutoff-date-label" >Weekly Cutoff Day</InputLabel>
                    <Select
                      id="weekly-cutoff-date"
                      labelId="weekly-cutoff-date-label"
                      label="Weekly Cutoff Day"
                      margin="dense"
                      value={this.state.cutOffWeekDay}
                      defaultValue={0}
                      onChange={this.handleWeeklyCutoffDayChange}
                      error={this.state.errors.cutOffWeekDay.error}
                      required
                    >
                      {this.state.cutOffWeekDays.map((option, index) => (
                        <MenuItem value={option.value} key={option.label}>{option.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redColorHelperText">{this.state.errors.cutOffWeekDay.helperText}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
            {/* Action button container */}
            <Grid container justify="flex-end" spacing={3} className="spaceEvenly paddingBottom10">
              <Grid item lg={3} xs={12} className="spaceEvenly flexWrap" >
                <Grid item lg={5} xs={12} className="padding10">
                  <Button fullWidth={true} variant="contained" color="primary" onClick={this.submitNewProject}>
                    Submit
                                    </Button>
                </Grid>
                <Grid item lg={5} xs={12} className="padding10">
                  <Button fullWidth={true} color="secondary" onClick={this.goHome} >
                    Cancel
                                    </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

        </Grid>
      </div >


    );
  }
}
export default NewProjectForm;