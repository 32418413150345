import { authProvider } from "../components/MSAL/authProvider";

const getToken = async () => {
  return await authProvider.getAccessToken().then((tokenResponse) => {
    return "Bearer " + tokenResponse.accessToken;
  });
};

export const AuthTokenGenerator = {
  getToken
};