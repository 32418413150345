import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import QuantityActivityAccordion from "../Quantity/QuantityActivityAccordion";
import ROCActivityAccordion from "../RuleOfCredit/ROCActivityAccordion";
import "./activitiesAccordion.css";
import usePagination from "../usePagination";

const ActivitiesAccordion = ({
  activities,
  saveQuantityProgress,
  onSaveActivityProgress,
}) => {
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    activities,
    5
  );

  return (
    <div className="activities-accordion">
      {currentData().map((activity) => {
        if (activity.model === 0) {
          return (
            <ROCActivityAccordion
              key={activity.id}
              activity={activity}
              rocName={activity.configuration.ruleOfCreditName}
              steps={activity.configuration.ruleOfCreditSteps}
              onSaveActivityProgress={onSaveActivityProgress}
            />
          );
        } else if (activity.model === 1) {
          return (
            <QuantityActivityAccordion
              key={activity.id}
              activity={activity}
              saveQuantityProgress={saveQuantityProgress}
            />
          );
        }
      })}
      {activities.length > 5 && (
        <Grid container justify="center" alignItems="center">
          <div className="activities-accordion__pagination">
            <div className="pagination__button">
              <IconButton disabled={currentPage === 1} onClick={prev}>
                <KeyboardArrowLeft />
              </IconButton>
              Prev
            </div>
            {[...Array(maxPage).keys()].slice(0).map((i) => {
              return (
                <span
                  className={`pagination__link ${
                    i + 1 === currentPage ? "pagination__link--selected" : ""
                  }`}
                  onClick={() => jump(i + 1)}
                >
                  {i + 1}
                </span>
              );
            })}
            <div className="pagination__button">
              <IconButton disabled={currentPage === maxPage} onClick={next}>
                <KeyboardArrowRight />
              </IconButton>
              Next
            </div>
          </div>
        </Grid>
      )}
    </div>
  );
};

export default ActivitiesAccordion;
