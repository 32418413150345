import React, { useState } from "react";
import "./uploadActivitiesDialog.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AttachFileRoundedIcon from "@material-ui/icons/AttachFileRounded";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffRoundedIcon from "@material-ui/icons/CancelRounded";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LevelsService } from "../../../Service/LevelsService";

const POLL_INTERVAL_DURATION = 10000;
let pollInterval;

const UploadActivitiesDialog = (props) => {
  const [attachmentName, setAttachmentName] = useState("");
  const [registerAttachment, setRegisterAttachment] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleClose = () => {
    removeAttachment();
    props.closeHandler();
  };

  const handleCloseError = () => {
    setErrorSettings({ title: "", message: "", open: false });
  };

  const [dialogSettings, setDialogSettings] = useState({
    title: "",
    message: "",
    open: false,
    onClose: undefined,
  });

  const [errorSettings, setErrorSettings] = useState({
    title: "",
    message: "",
    open: false,
  });

  const removeAttachment = () => {
    setRegisterAttachment({});
    setAttachmentName("");
  };

  const _basicErrorHandler = (error) => {
    if (error.data && error.data.detail) {
      setErrorSettings({
        title: error.data.title,
        message: error.data.detail,
        open: true,
      });
    } else {
      alert(
        "An unexpected error ocurred. Please try again.\nIf error persists, contact the support team."
      );
    }
  };

  const uploadExcel = async () => {
    setUploadProgress(0);

    try {
      setDialogSettings({
        title: "Excel Upload In Progress",
        message:
          "Excel upload can takes minutes. Please be patient and remain on the page. Page will auto-refresh when upload is complete.",
        open: true,
      });
      handleClose();

      let operation = await LevelsService.uploadLevelExcel(
        props.projectId,
        props.registerId,
        registerAttachment.file
      );
      if (operation.status === 0 || operation.status === 1) {
        pollInterval = setInterval(async () => {
          const currentProgress = operation.percentage;
          operation = await LevelsService.getOperationStatus(operation.id);
          if (operation.percentage > currentProgress) {
            setUploadProgress(operation.percentage);
          }
          if (operation.status === 2) {
            setDialogSettings({
              open: true,
              message: "Excel upload completed successfully",
              title: "Completed",
              onClose: () => window.location.reload(),
            });
            dialogCleanup(pollInterval);
          }
          if (operation.status === 3) {
            setErrorSettings({
              message: "Excel upload failed",
              title: "Error",
              open: true,
            });
            dialogCleanup(pollInterval);
          }
        }, POLL_INTERVAL_DURATION);
      }
    } catch (error) {
      setDialogSettings({
        ...dialogSettings,
        open: false,
      });
      _basicErrorHandler(error);
    }
  };

  const dialogCleanup = (interval) => {
    clearInterval(interval);
  };

  const onFileChangeHandler = (event) => {
    const files = event.target.files;
    if (files.length < 1) {
      return;
    }

    const returnObj = {
      file: files[0],
      name: files[0].name,
      type: files[0].type,
      size: files[0].size,
    };

    var fileReader = new FileReader();

    fileReader.onload = (e) => {
      var contents = e.target.result;
      returnObj.fileContent = contents;

      setRegisterAttachment(returnObj);
      setAttachmentName(returnObj.name);
    };

    fileReader.readAsBinaryString(files[0]);
  };

  return (
    <Grid item lg={6} xs={12} className="UploadActivitiesDialog">
      <Dialog
        className="topLevelDialogContainer dialogWidth"
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Upload Activities for Register
        </DialogTitle>
        <DialogContent>
          <form
            id="uploadActivityForm"
            name="uploadActivityForm"
            autoComplete="off"
            className="padding20"
          >
            <Grid container spacing={3}>
              {/* Attachment Control */}
              <Grid item lg={12} xs={12}>
                <div className="attachment-control bottomBorder">
                  <Grid item lg={1} xs={12} className="flexAlignRight">
                    <label
                      htmlFor="excel-attachment"
                      className="attachment-label"
                    >
                      <input
                        type="file"
                        id="excel-attachment"
                        name="excel-attachment"
                        accept=".xlsx"
                        onChange={onFileChangeHandler}
                        hidden
                      />
                      <IconButton
                        color="primary"
                        component="span"
                        className="noPadding"
                      >
                        <AttachFileRoundedIcon />
                      </IconButton>
                    </label>
                  </Grid>
                  <Grid item lg={8} xs={12}>
                    <Typography className="attachment-name">
                      {attachmentName === ""
                        ? "Please attach an excel file"
                        : attachmentName}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} xs={12} className="flexAlignLeft">
                    {attachmentName !== "" && (
                      <IconButton
                        color="secondary"
                        onClick={removeAttachment}
                        className="noPadding"
                      >
                        <HighlightOffRoundedIcon />
                      </IconButton>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={uploadExcel} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="dialog-title"
        open={dialogSettings.open}
        onClose={dialogSettings.onClose}
      >
        <DialogTitle id="dialog-title">{dialogSettings.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {dialogSettings.message}
          </DialogContentText>
          {uploadProgress === 100 ? (
            <DialogActions>
              <Button onClick={() => window.location.reload()} color="primary">
                Ok
              </Button>
            </DialogActions>
          ) : (
            <LinearProgress
              style={{ marginBottom: "12px" }}
              value={uploadProgress}
              variant="determinate"
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="error'title"
        open={errorSettings.open}
        onClose={handleCloseError}
      >
        <DialogTitle id="error'title">{errorSettings.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error'description">
            {errorSettings.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UploadActivitiesDialog;
