import axios from "axios";
import { AuthTokenGenerator } from "./AuthTokenGenerator";
import { appConfig } from "../appConfig";
import moment from "moment";

const basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

const getLevelsByProjectId = async (projectId, params) => {
  const token = await AuthTokenGenerator.getToken();

  const config = {
    headers: {
      Authorization: token,
    },
  };

  if (params) {
    config.params = params;
  }

  return axios
    .get(appConfig.baseUrl + "/projects/" + projectId + "/levels", config)
    .then(function (response) {
      return response.data.payload.levels;
    })
    .catch(basicErrorHandler);
};

const getRegisters = async (projectId, params) => {
  const token = await AuthTokenGenerator.getToken();

  const config = {
    headers: {
      Authorization: token,
    },
  };

  if (params) {
    config.params = params;
  }

  return axios
    .get(appConfig.baseUrl + "/projects/" + projectId + "/levels", config)
    .then(function (response) {
      const lvl1s = response.data.payload.levels;

      const fakeLevelZero = { type: 0, children: lvl1s };
      const registers = searchRegisters(fakeLevelZero);

      return registers;
    })
    .catch(basicErrorHandler);
};

const searchRegisters = (aLevel) => {
  if (aLevel.type === 1) {
    return [aLevel];
  } else if (aLevel.children && aLevel.children.length > 0) {
    const registers = aLevel.children.map(searchRegisters).flat();
    return registers;
  } else {
    return [];
  }
};

const updatedLevel = async (projectId, level) => {
  const lvlData = {
    id: level.id,
    name: level.name,
    type: level.type,
    discipline: level.discipline,
    manHour: level.manHour,
    cost: level.cost,
    model: level.model,
    currencyId: level.currency.id,
    weighting: level.weight ? level.weight.value : null,
    costBasis: level.costBasis,
  };

  if (level.type === 1) {
    lvlData.attributeIds = level.attributes.map((att) => att.id);
  }

  const token = await AuthTokenGenerator.getToken();

  return axios
    .put(appConfig.baseUrl + "/projects/" + projectId + "/levels", lvlData, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.level;
    })
    .catch(basicErrorHandler);
};

const getLevelExcel = async (projectId, levelId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(
      appConfig.baseUrl +
        "/projects/" +
        projectId +
        "/levels/" +
        levelId +
        "/download",
      {
        headers: {
          Authorization: token,
        },
        responseType: "arraybuffer",
      }
    )
    .then(function (response) {
      return {
        file: response.data,
        type: response.headers["content-type"],
      };
    })
    .catch(basicErrorHandler);
};

const uploadLevelExcel = async (projectId, levelId, excel) => {
  var formData = new FormData();
  formData.append("file", excel);

  const token = await AuthTokenGenerator.getToken();

  return axios
    .post(
      `${appConfig.baseUrl}/projects/${projectId}/levels/${levelId}/upload`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      return response.data.payload.operation;
    })
    .catch(basicErrorHandler);
};

const getOperationStatus = async (operationId) => {
  const Authorization = await AuthTokenGenerator.getToken();
  return axios
    .get(
      `${appConfig.baseUrl}/operations/status/${operationId}`,
      {
        headers: {
          Authorization,
        },
      }
    )
    .then((response) => {
      return response.data.payload.operation;
    })
    .catch(basicErrorHandler);
};

const getProjectById = async (id) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/projects/" + id, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.project;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};

const getRegisterArchives = async (id, levelId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(`${appConfig.baseUrl}/projects/${id}/levels/${levelId}/archives`, {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.archives.map((arch, index) => ({
        ...arch,
        version: index + 1,
      }));
    })
    .catch(basicErrorHandler);
};

const downloadMDRExcel = async (id, levelId, cutoffDate) => {
  const Authorization = await AuthTokenGenerator.getToken();
  let mdrUrl = `${appConfig.baseUrl}/projects/${id}/levels/${levelId}/mdr/download`;
  if (cutoffDate) {
    const date = moment.utc(cutoffDate).format("yyyy-MM-DD");
    mdrUrl = `${mdrUrl}?cutoffDate=${date}`;
  }
  return axios
    .get(mdrUrl, {
      headers: {
        Authorization,
      },
    })
    .then((response) => response.data.payload.operation)
    .catch(basicErrorHandler);
};

export const LevelsService = {
  getLevelsByProjectId,
  getRegisters,
  updatedLevel,
  getLevelExcel,
  uploadLevelExcel,
  searchRegisters,
  getProjectById,
  getRegisterArchives,
  getOperationStatus,
  downloadMDRExcel,
};
