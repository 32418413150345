import React from 'react';

import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import { currency } from '../../../filters/currency';
import { DATE_FORMAT } from '../../../constants/strings';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';

const useDatePickerStyles = makeStyles({
  root: {
    '& > div': {
      marginTop: '4px',
      marginBottom: '4px'
    }
  }
});

const CustomDatePicker = (props) => {

  // Custom Styles
  const datePickerClasses = useDatePickerStyles();

  return (
    <FormControl variant="outlined" fullWidth className={datePickerClasses.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id={props.id}
          disableToolbar
          variant="inline"
          format={DATE_FORMAT}
          margin="normal"
          label={props.label}
          value={props.value}
          onChange={props.onChange}
          autoOk={true}
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          readOnly={props.readOnly}
          InputProps={{ readOnly: props.readOnly }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

const ROCActivityRow = (props) => {

  // Props state
  const levelId = props.levelId;
  const activity = props.activity;
  const attributes = props.attributes;
  const ruleOfCredit = props.ruleOfCredit;
  const readOnly = props.readOnly;

  // Props Functions
  const onUpdateRocProgressDate = props.onUpdateRocProgressDate;
  const onAddNewRocProgress = props.onAddNewRocProgress;
  const onAddNewRocProgressObj = props.onAddNewRocProgressObj;

  const _renderAttributeValues = (anActivity) =>
    attributes.map(anAttribute => {
      var attributeValue = null;

      if (anActivity.definitions) {
        const attributeDef = anActivity.definitions.find(d => d.attributeId === anAttribute.id);
        attributeValue = attributeDef ? attributeDef.value : null;
      }

      return (
        <TableCell key={"activity-" + anActivity.id + "_attribute-" + anAttribute.id}>
          {attributeValue}
        </TableCell>
      );
    });

  const _renderRocProgresses = (anActivity) => {
    const ruleOfCreditId = ruleOfCredit.id;
    const rocSteps = ruleOfCredit.activitySteps;

    // Quantity Based Activity
    if (anActivity.type === 1) {
      return rocSteps.map(anRocStep =>
        <TableCell key={"activity-" + anActivity.id + "_roc-step-" + anRocStep.id}>
          QTY BASED
        </TableCell>
      );
    }

    // Loop through Step
    return rocSteps.map(anRocStep => {

      const tableCellSetup = {
        planned: {
          id: `plannedDate-activity_${anActivity.id}-rocStep_${anRocStep.id}`,
          label: "Planned",
          value: null,
          onChange: null
        },
        forecast: {
          id: `forecastDate-activity_${anActivity.id}-rocStep_${anRocStep.id}`,
          label: "Forecast",
          value: null,
          onChange: null
        },
        actual: {
          id: `actualDate-activity_${anActivity.id}-rocStep_${anRocStep.id}`,
          label: "Actual",
          value: null,
          onChange: null
        }
      };

      // Activity absolutely has no ROC progress
      if (!anActivity.configuration || !anActivity.configuration.ruleOfCreditSteps) {
        tableCellSetup.planned.onChange = (newDate) => { onAddNewRocProgress(levelId, anActivity.id, ruleOfCreditId, anRocStep.id, "plannedDate", newDate) };
        tableCellSetup.forecast.onChange = (newDate) => { onAddNewRocProgress(levelId, anActivity.id, ruleOfCreditId, anRocStep.id, "forecastDate", newDate) };
        tableCellSetup.actual.onChange = (newDate) => { onAddNewRocProgress(levelId, anActivity.id, ruleOfCreditId, anRocStep.id, "actualDate", newDate) };
      }
      else {
        const anRocStepProgress = anActivity.configuration.ruleOfCreditSteps.find(p => p.stepId === anRocStep.id);

        if (anRocStepProgress) {
          tableCellSetup.planned.value = (anRocStepProgress.plannedDate) ? anRocStepProgress.plannedDate : null;
          tableCellSetup.planned.onChange = (newDate) => { onUpdateRocProgressDate(levelId, anActivity.id, anRocStep.id, "plannedDate", newDate) };

          tableCellSetup.forecast.value = (anRocStepProgress.forecastDate) ? anRocStepProgress.forecastDate : null;
          tableCellSetup.forecast.onChange = (newDate) => { onUpdateRocProgressDate(levelId, anActivity.id, anRocStep.id, "forecastDate", newDate) };

          tableCellSetup.actual.value = anRocStepProgress.actualDate ? anRocStepProgress.actualDate : null;
          tableCellSetup.actual.onChange = (newDate) => { onUpdateRocProgressDate(levelId, anActivity.id, anRocStep.id, "actualDate", newDate) };
        }
        else {
          tableCellSetup.planned.onChange = (newDate) => { onAddNewRocProgressObj(levelId, anActivity.id, anRocStep.id, "plannedDate", newDate) };
          tableCellSetup.forecast.onChange = (newDate) => { onAddNewRocProgressObj(levelId, anActivity.id, anRocStep.id, "forecastDate", newDate) };
          tableCellSetup.actual.onChange = (newDate) => { onAddNewRocProgressObj(levelId, anActivity.id, anRocStep.id, "actualDate", newDate) };
        }
      }

      return (
        <TableCell key={"activity-" + anActivity.id + "_roc-step-" + anRocStep.id}>
          <Grid container justify="center" alignItems="center">

            <Grid item xs={12}>
              <CustomDatePicker
                id={tableCellSetup.planned.id}
                label={tableCellSetup.planned.label}
                value={tableCellSetup.planned.value}
                onChange={tableCellSetup.planned.onChange}
                readOnly={readOnly}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomDatePicker
                id={tableCellSetup.forecast.id}
                label={tableCellSetup.forecast.label}
                value={tableCellSetup.forecast.value}
                onChange={tableCellSetup.forecast.onChange}
                readOnly={readOnly}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomDatePicker
                id={tableCellSetup.actual.id}
                label={tableCellSetup.actual.label}
                value={tableCellSetup.actual.value}
                onChange={tableCellSetup.actual.onChange}
                readOnly={readOnly}
              />
            </Grid>

          </Grid>
        </TableCell>
      );
    });
  };

  return (
    <TableRow key={activity.id}>
      <TableCell>{activity.id}</TableCell>
      <TableCell className="stickyColumn" style={{ backgroundColor: '#fff' }}>{activity.description}</TableCell>
      <TableCell>{currency(activity.costValue)}</TableCell>
      <TableCell>{activity.weighting}</TableCell>
      <TableCell>{activity.responsibleParty}</TableCell>
      <TableCell>{activity.primaveraTaskId}</TableCell>
      <TableCell>{activity.weight.project}</TableCell>
      { _renderAttributeValues(activity)}
      { _renderRocProgresses(activity)}
    </TableRow >
  );
};

export default ROCActivityRow;