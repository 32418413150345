import { appConfig } from "../../appConfig";

const getPBIConfigurations = () => {
  const baseUrl = appConfig.baseUrl;
  const config = {
    projectSlicers: [
      {
        name: "fb42366582162d32c886",
        tableName: "JobReportDefinitions",
        columnName: "Slicer Label",
      },
      {
        name: "029e7c66d786b3972c27",
        tableName: "LevelReports",
        columnName: "Parent Level Slicer",
      },
      {
        name: "50bde846515e1a462b63",
        tableName: "LevelReports",
        columnName: "Parent Level Slicer",
      },
      {
        name: "ae89ebb5a885253b0b80",
        tableName: "JobReportDefinitions",
        columnName: "Slicer Label",
      },
      {
        name: "47c217f280d4b904ec51",
        tableName: "LevelReports",
        columnName: "Parent Level Slicer",
      },
      {
        name: "6a6a83f8bbddb3755907",
        tableName: "LevelReports",
        columnName: "Parent Level Slicer",
      },
      {
        name: "105adcd17c71c14dc497",
        tableName: "LevelReports",
        columnName: "Parent Level Slicer",
      },
    ],
    jobRunTimeSlicers: [
      {
        name: "fdc47b6f10a454ada065",
        table: "JobReportDefinitions",
        column: "Run Time Slicer Label",
      },
      {
        name: "9f9e1cbbdf4c1bef1a9a",
        table: "JobReportDefinitions",
        column: "Run Time Slicer Label",
      },
      {
        name: "7931b92d0a1743676d37",
        table: "JobReportDefinitions",
        column: "Run Time Slicer Label",
      },
      {
        name: "137d760e81a651cc2074",
        table: "JobReportDefinitions",
        column: "Run Time Slicer Label",
      },
      {
        name: "0927bbbce81ff2cab7db",
        table: "JobReportDefinitions",
        column: "Run Time Slicer Label",
      },
      {
        name: "5be7b3336dca29bcbc59",
        table: "JobReportDefinitions",
        column: "Run Time Slicer Label",
      },
      {
        name: "a6c4ddd429d84bd5ca35",
        table: "JobReportDefinitions",
        column: "Run Time Slicer Label",
      },
    ],
  };

  config.workspaceId = appConfig.report.workspace;
  config.reportId = appConfig.report.id;

  return config;
};

const pbiConfig = getPBIConfigurations();
export default pbiConfig;
