import React, { useState, useEffect, useRef } from "react";

import '@microsoft/mgt';
import StyledTableCell from '../Shared/StyledTableCell/StyledTableCell';
import UserRow from './UserRow/UserRow';
import { UsersService } from '../../Service/UsersService';
import { ProjectsService, UserAccessRole } from '../../Service/ProjectsService';

import './userManagement.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SaveIcon from '@material-ui/icons/Save';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';

const UserManagement = (props) => {

  /** State Variables */
  const [newUser, setNewUser] = useState("");
  const [isNewUserSysAdmin, setIsNewUserSysAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    severity: "success",
    message: "I <3 snacks"
  });

  /** Refs */
  const mgtPeoplePickerEl = useRef(null);

  // componentDidMount
  useEffect(() => {

    // Set people picker event
    mgtPeoplePickerEl.current.addEventListener('selectionChanged', (e) => {
      e.stopPropagation();

      if (e.target.selectedPeople.length !== 0) {
        const splitEmail = e.target.selectedPeople[0].userPrincipalName.split('@');
        setNewUser({
          ...newUser,
          name: e.target.selectedPeople[0].displayName,
          alias: splitEmail[0]
        });
      } else {
        setNewUser("");
      }
    }, false);

    // Call DB to get users and projects
    _initModule();

    // componentWillUnmount
    return () => {
      console.log('[UseManagement.js] componentWillUnmount');
    };
  }, []);

  /** Event Handlers */
  const onSysAdminChecked = (event) => {
    setIsNewUserSysAdmin(event.target.checked);
  }

  const onAddUser = async () => {
    if (newUser === "") {
      return;
    }

    const existingUser = users.find(u => {
      return u.alias === newUser.alias;
    });

    if (existingUser) {
      const message = newUser.name + ' is already in the system.';
      window.alert(message);
      return;
    }

    const aNewUser = {
      name: newUser.name,
      alias: newUser.alias,
      admin: isNewUserSysAdmin
    };

    try {
      setSnackbarSettings({open: true, message: "Adding new user...", severity: "info"});
      const newUser = await UsersService.addUser(aNewUser);
      
      setUsers([...users, newUser]);
      setNewUser("");
      setIsNewUserSysAdmin(false);
      setSnackbarSettings({open: true, message: "New user added", severity: "success"});
      mgtPeoplePickerEl.current.selectedPeople = [];
    }
    catch (error) {
      console.log(error);
    }
  };

  const sysAdminHandler = async (userId, isAdmin) => {
    // Get correct User
    const userIndex = users.findIndex(u => u.id === userId);
    const aUser = users[userIndex];
    
    const userData = {
      id: aUser.id,
      name: aUser.name,
      alias: aUser.alias,
      admin: isAdmin
    };

    try {
      // Update copy of User Array
      const tmpUser = {...aUser};
      tmpUser.admin = isAdmin;

      const tmpUsers = [...users];
      tmpUsers[userIndex] = tmpUser;

      setUsers(tmpUsers);

      // Save change to DB
      const tmp = await UsersService.updatedUser(userData);
      setSnackbarSettings({open: true, severity: "success", message: "System admin edit saved"});
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUserHandler = async (userId) => {
    const userIndex = users.findIndex(u => u.id === userId);
    
    try {
      // Save change to DB
      setSnackbarSettings({open: true, message: "Deleting user...", severity: "info"});
      const deletedUserId = await UsersService.deleteUser(userId);
      
      // Remove user from local memory
      const updatedUsers = [...users];
      updatedUsers.splice(userIndex, 1);
      
      setUsers(updatedUsers);
      setSnackbarSettings({open: true, message: "User deletion saved", severity: "success"});
    }
    catch (error) {
      console.log(error);
    }
  };

  const projectRoleHandler = async (userId, projectId, newRole) => {
    
    // Get correct User & project permission index
    const userIndex = users.findIndex(u => u.id === userId);
    const aUser = users[userIndex];
    const projPermissionIndex = aUser.permissions.findIndex(p => p.projectId === projectId);

    // Get project obj
    const projectIndex = projects.findIndex(p => p.id === projectId);
    const project = projects[projectIndex];

    try {
      // Update user obj
      const updatedUser = {...aUser};
      updatedUser.permissions[projPermissionIndex].role = newRole;

      // Update users array
      const tmpUsers = [...users];
      tmpUsers[userIndex] = updatedUser;

      // Update UI (User Role)
      setUsers(tmpUsers);

      // Save edit to DB
      const updatedProject = await ProjectsService.updateUserProjectRole(project, userId, newRole);

      // Update project
      const updatedProjects = [...projects];
      updatedProjects[projectIndex] = updatedProject;
      setProjects(updatedProjects);

      setSnackbarSettings({open: true, message: "User role saved", severity: "success"});
    } catch (error) {
      console.log(error);
      const errorMessage = (error.data && error.data.detail)
        ? error.data.detail
        : 'There was a problem with saving the user\'s permission.\nPlease refresh the page and try again.';
      alert(errorMessage);
    }
  };

  const removeFromProjectHandler = async (userId, projectId) => {
    // Get correct User
    const userIndex = users.findIndex(u => u.id === userId);
    const aUser = users[userIndex];

    // Get correct project permission Index
    const projPermissionIndex = aUser.permissions.findIndex(p => p.projectId === projectId);

    // Get correct project obj
    const projIndex = projects.findIndex(p => p.id === projectId);
    const project = projects[projIndex];

    try {
      setSnackbarSettings({open: true, message: "Removing user from project...", severity: "info"});
      const updatedProject = await ProjectsService.removeUserFromProject(project, userId);

      // Updated copy of User
      const tmpUser = { ...aUser };
      tmpUser.permissions.splice(projPermissionIndex, 1);

      // Update copy of User Array
      const tmpUsersList = [...users];
      tmpUsersList[userIndex] = tmpUser;

      // Update project
      const tmpProjects = [...projects];
      tmpProjects[projIndex] = updatedProject;
      
      setProjects(tmpProjects);
      setUsers(tmpUsersList);
      setSnackbarSettings({open: true, message: "User removed from project", severity: "success"});
    } catch (error) {
      console.log(error);
      const errorMessage = (error.data && error.data.detail) ? error.data.detail : 'There was a problem with removing the user from the project'; 
      alert(errorMessage);
    }
  };

  const addUserToProjectsHandler = async (user, selectedProjects) => {    
    try {
      // Update selected projects in DB
      setSnackbarSettings({open: true, message: "Adding users to projects...", severity: "info"});
      const updatedProjects = await Promise.all(
        selectedProjects.map(proj => ProjectsService.addUserToProject(proj, user.id, UserAccessRole.Viewer))
      );

      // Update user's permissions
      const userIndex = users.findIndex(u => u.id === user.id);
      const updatedUser = { ...user };
      
      const tmpProjectsPermissions = selectedProjects.map(p => {
        return {
          projectId: p.id,
          name: p.name,
          role: UserAccessRole.Viewer
        };
      });
      updatedUser.permissions = updatedUser.permissions.concat(tmpProjectsPermissions);

      const tmpUsers = [...users];
      tmpUsers[userIndex] = updatedUser;

      // Replace updated projects
      const projIndexes = updatedProjects.map(up => {
        return projects.findIndex(p => p.id === up.id);
      });

      const newProjects = [...projects];
      for (let i = 0; i < projIndexes.length; i++) {
        const projIndex = projIndexes[i];
        newProjects[projIndex] = updatedProjects[i];
      }

      setUsers(tmpUsers);
      setProjects(newProjects);
      
      // Alert user
      setSnackbarSettings({open: true, message: "User added to projects", severity: "success"});
    } catch (error) {
      console.log(error);
    }
  };

  const closeSnackbar = () => {
    setSnackbarSettings({...snackbarSettings, open: false});
  };

  /** Private/Helper Functions */
  const _initModule = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle("User Management");
      props.setViewMode("admin");

      const [users, projects] = await Promise.all([
        UsersService.getUsers(),
        ProjectsService.getProjects()
      ]);

      setUsers(users);
      setProjects(projects);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setShowSpinner(false);
    }
  };

  /** Render */
  return (
    <Grid container justify="center" alignItems="center" className="UserManagement">
      <Grid container item spacing={2} xs={10}>
        <Backdrop className="backDropZIndex" open={showSpinner}>
          <CircularProgress color="primary" />
        </Backdrop>
        
        {/* New User Form */}
        <Grid container item>
          <Paper className="new-user-form">
            <Typography variant="h4" component="h4" className="new-user-form-title">
              New User Form
            </Typography>
            <form id="newUser" name="newUser" autoComplete="off">
              <Grid container item md={12} spacing={3} >
                <Grid item md={4} xs={12} >
                  <mgt-people-picker
                    selection-mode="single"
                    placeholder="Enter employee name"
                    show-max="4"
                    id="pp-new-user"
                    className="pp-new-user"
                    ref={mgtPeoplePickerEl}
                  >
                  </mgt-people-picker>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox color="primary" name="new-sysadmin-checkbox" checked={isNewUserSysAdmin} onChange={onSysAdminChecked} />}
                    label="System Admin"
                    labelPlacement="End"
                  />
                </Grid>
              </Grid>
              <Grid container item md={12} justify="flex-end">
                <Grid item md={1} xs={12}>
                  <Button variant="contained" color="primary" size="small" fullWidth={true} startIcon={<SaveIcon />} onClick={onAddUser.bind(this)}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        {/* Existing Users */}
        <Grid container item>
          <Paper className="fullWidth">
            <Grid item md={12}>
              <Typography variant="h4" component="h4" className="padding20">Users</Typography>
            </Grid>
            <Grid item md={12}>
              <div className="padding20">
                <TableContainer component={Paper} className="tableRowColor">
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell />
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Alias</StyledTableCell>
                        <StyledTableCell>System Admin</StyledTableCell>
                        <StyledTableCell>Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user, index) =>
                        <UserRow
                          key={user.id}
                          user={user}
                          projects={projects}
                          onSysAdminChecked={sysAdminHandler}
                          onProjectRoleChanged={projectRoleHandler}
                          onRemoveFromProject={removeFromProjectHandler}
                          onDeleteUser={deleteUserHandler}
                          onAddUserToProjects={addUserToProjectsHandler}
                        />
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Paper>
        </Grid>

        {/* Custom Snackbar */}
        <Snackbar
          key="custom-snackbar"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackbarSettings.open}
          onClose={closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert
            elevation={6}
            variant="filled"
            iconMapping={{info: <AutorenewRoundedIcon/>}}
            severity={snackbarSettings.severity}
          >
            {snackbarSettings.message}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default UserManagement;