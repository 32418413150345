import axios from "axios";
import { AuthTokenGenerator } from './AuthTokenGenerator';
import { appConfig } from "../appConfig";

const getAccessToken = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/users/token", {
      headers: {
        Authorization: token,
      }
    })
    .then(response => {
      return {
        token_type: 'Bearer',
        access_token: response.data.payload.token
      };
    })
    .catch(_basicErrorHandler);
};

const getReportInGroup = async (workspaceId, reportId, accessTokenResponse) => {
  return axios
    .get(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `${accessTokenResponse.token_type} ${accessTokenResponse.access_token}`
      }
    })
    .then(response => response.data)
    .catch(_basicErrorHandler);
};

const generateEmbedToken = async (projectId, reportId, datasetId, accessTokenResponse) => {
  const body = {
    datasets: [
      {
        id: datasetId
      }
    ],
    reports: [
      {
        id: reportId
      }
    ]
    // ,identities: [
    //   {
    //     username: "DPS-NoReply@slb.com",
    //     roles: [
    //       `Project ${projectId}`
    //     ],
    //     datasets: [
    //       datasetId
    //     ]
    //   }
    // ]
  };
  
  return axios
    .post('https://api.powerbi.com/v1.0/myorg/GenerateToken', body, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `${accessTokenResponse.token_type} ${accessTokenResponse.access_token}`
      }
    })
    .then(response => response)
    .catch(_basicErrorHandler);
};

const _basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

export const PowerBIService = {
  getAccessToken,
  getReportInGroup,
  generateEmbedToken
};