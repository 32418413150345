import axios from "axios";
import { appConfig } from "../appConfig";
import { AuthTokenGenerator } from './AuthTokenGenerator';

const getUsers = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/users", {
      headers: {
        Authorization: token,
      }
    })
    .then(function (response) {
      return response.data.payload.users;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const addUser = async (newUser) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .post(appConfig.baseUrl + "/users", newUser, {
      headers: {
        Authorization: token,
      }
    })
    .then(response => {
      const newUser = response.data.payload.user;
      newUser.permissions = [];  // Required by UserManagement module
      return newUser;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const updatedUser = async (user) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .put(appConfig.baseUrl + "/users", user, {
      headers: {
        Authorization: token,
      }
    })
    .then(response => {
      const updatedUser = response.data.payload.user;
      updatedUser.permissions = [];  // Required by UserManagement module
      return updatedUser;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const deleteUser = async (userId) => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .delete(appConfig.baseUrl + "/users/" + userId, {
      headers: {
        Authorization: token,
      }
    })
    .then(response => {
      return userId;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

const getCurrentUser = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/users/me", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload.user;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
}

const getUserActivities = async () => {
  const token = await AuthTokenGenerator.getToken();
  return axios
    .get(appConfig.baseUrl + "/users/activities", {
      headers: {
        Authorization: token,
      },
    })
    .then(function (response) {
      return response.data.payload;
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        throw error.response;
      } else {
        console.log(error);
        throw error;
      }
    });
};

export const UsersService = {
  getUsers,
  addUser,
  updatedUser,
  deleteUser,
  getCurrentUser,
  getUserActivities
};