import React, { useState } from "react";
import * as PROJECTS from "../../constants/projects";

import ChildCategories from "./ChildCategories";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import StyledTableCell from "../../Shared/StyledTableCell/StyledTableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import useExpandableRow from "../useExpandableRow";

import "./registerCategories.css";

const RegisterCategories = ({ register, userPermissions, onResult }) => {
  const [isExpanded, toggleExpand] = useExpandableRow(false);

  const _outputRegisterModel = () => {
    if (register.model === 0) {
      return "Rule of Credit";
    } else if (register.model === 1) {
      return "Quantity";
    } else {
      return "None";
    }
  };

  const _userOnlyHasReadAccess = () => {
    const readOnlyRoles = [PROJECTS.Roles.InternalUser, PROJECTS.Roles.Viewer];

    return (
      !userPermissions.systemAdmin &&
      !userPermissions.projectManager &&
      readOnlyRoles.includes(userPermissions.projectRole)
    );
  };

  return (
    <>
      <TableRow className="register-row main-row">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleExpand}
          >
            {isExpanded ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {register.id}
        </TableCell>
        <TableCell align="left">{register.name}</TableCell>
        <TableCell align="left">{register.discipline}</TableCell>
        <TableCell align="left">{_outputRegisterModel()}</TableCell>
      </TableRow>
      <TableRow className="children-row collapsable-row">
        <TableCell colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Typography variant="h6" gutterBottom component="div">
              Children
            </Typography>
            <TableContainer component={Paper} className="inner-table">
              <Table size="small" className="grey-table">
                <TableHead>
                  <TableRow className="children-table-header">
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {register.children &&
                    register.children.map((registerChild, index) => (
                      <ChildCategories
                        key={registerChild.id}
                        child={registerChild}
                        readOnly={_userOnlyHasReadAccess()}
                        onResult={onResult}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RegisterCategories;
