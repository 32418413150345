export const TYPE = {
  Level: 0,
  Register: 1
};

export const Model = {
  RuleOfCredit: 0,
  Quantity: 1,
  None: 2
};

export const CostBasis = {
  ManHour: 0,
  Cost: 1
};