import React, { useState } from 'react';

import StyledTableCell from '../../Shared/StyledTableCell/StyledTableCell';
import * as PROJECTS from '../../constants/projects';
import './registerRow.css';

import { currency } from '../../filters/currency';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Button from '@material-ui/core/Button';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const RegisterRow = (props) => {

  const classes = useRowStyles();

  // Props State
  const register = props.register;
  const attributesList = props.attributes;
  const userPermissions = props.userPermissions;

  // Component State
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  // User can only select un-associated attributes
  const existingAttributes = attributesList.filter(att => {
    const currentAttributes = register.attributes;
    const index = currentAttributes.findIndex(current => current.id === att.id);
    return index === -1;
  });

  /* Event Handlers */
  const openAttributes = () => setOpen(!open);

  const onChangeName = (event) => {
    setNewName(event.target.value);
  };

  const onChangeDescription = (event) => {
    setNewDescription(event.target.value);
  };

  const onClickAddNewAttribute = () => {
    props.onAddNewAttribute(register.id, newName, newDescription);
    setNewName("");
    setNewDescription("");
  };

  const onClickRemoveAttribute = (attributeId) => {
    props.onRemoveAttribute(register.id, attributeId);
  };

  const onSelectAttributes = (event) => {
    const updatedSelectedAttributes = event.target.value;
    setSelectedAttributes(updatedSelectedAttributes);
  };

  const onClickAddExistingAttribute = () => {
    props.onAddExistingAttributes(register.id, selectedAttributes);
    setSelectedAttributes([]);
  }

  const _outputRegisterModel = () => {
    if (register.model === 0) {
      return "Rule of Credit";
    }
    else if (register.model === 1) {
      return "Quantity";
    }
    else {
      return "None";
    }
  };

  const _userOnlyHasReadAccess = () => {
    const readOnlyRoles = [PROJECTS.Roles.InternalUser, PROJECTS.Roles.Viewer];

    return (!userPermissions.systemAdmin &&
      !userPermissions.projectManager &&
      readOnlyRoles.includes(userPermissions.projectRole)
    );
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={openAttributes}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {register.id}
        </TableCell>
        <TableCell align="left">
          {register.name}
        </TableCell>
        <TableCell align="left">
          {register.discipline}
        </TableCell>
        <TableCell align="left">
          {register.currency ? register.currency.name : ""}
        </TableCell>
        <TableCell align="left">
          {currency(register.costBasis === 0
            ? register.manHour
            : register.cost)
          }
        </TableCell>
        <TableCell align="left">
          {_outputRegisterModel()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" gutterBottom component="div">Attributes</Typography>

              {!_userOnlyHasReadAccess() &&
                <>
                  {/* New Attribute */}
                  <Grid container spacing={3} className="newAttributesGrid">
                    {/* Name */}
                    <Grid item lg={2} xs={12}>
                      <FormControl fullWidth required>
                        <TextField
                          id="newName"
                          label="Name"
                          variant="outlined"
                          size="small"
                          value={newName}
                          onChange={onChangeName}
                          required />
                      </FormControl>
                    </Grid>
                    {/* Description */}
                    <Grid item lg={2} xs={12}>
                      <FormControl fullWidth required>
                        <TextField
                          id="newDescription"
                          label="Description"
                          variant="outlined"
                          size="small"
                          value={newDescription}
                          onChange={onChangeDescription}
                          required />
                      </FormControl>
                    </Grid>
                    {/* Add Attribute Button */}
                    <Grid item lg={2} xs={12}>
                      <div className="fullWidth">
                        <Grid item lg={9} xs={12}>
                          <Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            onClick={onClickAddNewAttribute}
                            startIcon={<AddCircleOutlineRoundedIcon />}>
                            Add New
                          </Button>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                  {/* Existing Attribute */}
                  <Grid container spacing={3} className="existingAttributesGrid">
                    {/* Attribute Selector */}
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="attributes-label">Existing Attributes</InputLabel>
                        <Select
                          labelId="attributes-label"
                          id="attributes-selector"
                          multiple
                          value={selectedAttributes}
                          onChange={onSelectAttributes}
                          input={<Input id="attributes-selector-input" />}
                          renderValue={(selected) => (
                            <div>
                              {selected.map((value) => (
                                <Chip key={value.id} label={value.name} />
                              ))}
                            </div>
                          )}
                        >
                          {existingAttributes.map((attribute) =>
                            <MenuItem key={attribute.id} value={attribute}>
                              <Checkbox color="primary" checked={selectedAttributes.indexOf(attribute) > -1} />
                              <ListItemText primary={attribute.name} />
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Add Attribute Button */}
                    <Grid item lg={2} xs={12}>
                      <div className="fullWidth" >
                        <Grid item lg={9} xs={12}>
                          <Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            onClick={onClickAddExistingAttribute}
                            startIcon={<AddCircleOutlineRoundedIcon />}>
                            Add Existing
                          </Button>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </>
              }

              {/* Attributes Table */}
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow className={classes.tableHead}>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell>Remove</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {register.attributes.map((attribute, index) =>
                      <TableRow key={attribute.id}>
                        <TableCell component="th" scope="row">{attribute.id}</TableCell>
                        <TableCell>{attribute.name}</TableCell>
                        <TableCell>{attribute.description}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={() => onClickRemoveAttribute(attribute.id)}
                            disabled={_userOnlyHasReadAccess()}
                          >
                            <HighlightOffRoundedIcon color={_userOnlyHasReadAccess() ? "disabled" : "secondary"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default RegisterRow;