import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import { levelDetailsService } from '../../Service/levelDetailsService';

import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const ImportWBS = () => {
  const { id } = useParams();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showWeightDialog, setShowWeightDialog] = useState(false);
  const [importedLevels, setImportedLevels] = useState([]);

  const importDefaultLevels = async () => {
    try {
      setShowSpinner(true);

      const templateLevels = await levelDetailsService.getTemplateLevels();

      let tmpArray = templateLevels.map(item => (
        {
          name: item.name,
          discipline: item.discipline,
          basis: item.costBasis,
          model: item.model,
          currency: item.currencyId,
          cost: item.cost,
          manHour: item.manHour,
          type: item.type,
          expanded: false,
          disableRegister: false,
          number: item.number,
          weighting: '',
          order: item.order,
          l2s: item.children.map(child => (
            {
              name: child.name,
              basis: child.costBasis,
              model: child.model,
              discipline: child.discipline,
              currency: child.currencyId,
              cost: child.cost,
              manHour: item.manHour,
              type: child.type,
              number: child.number,
              order: item.order,
              projectWeighting: child.projectWeighting ? child.projectWeighting : '',
            }
          ))
        }
      ));

      setImportedLevels(tmpArray);
      setShowWeightDialog(true);

    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }

  };

  const submitImportedLevels = async () => {
    if (validateWeightDialog()) {
      setShowWeightDialog(false);
      setShowSpinner(true);
      try {
        const savedL1s = await Promise.all(
          importedLevels.map(item => (
            createL1(item)
          ))
        );

        savedL1s.forEach(function (item, index) {
          importedLevels[index].id = item;
        })

        const tempL2s = [];

        for (var i = 0; i < importedLevels.length; i++) {
          for (var j = 0; j < importedLevels[i].l2s.length; j++) {
            tempL2s.push(createL2(importedLevels[i].l2s[j], importedLevels[i].id));
          }
        }

        await Promise.all(tempL2s);

      } catch (error) {
        alert(error.data.detail);
      } finally {
        window.location.reload();
      }

    } else {
      alert('Please make sure all weights are entered.');
    }
  };

  const createL1 = async (arg) => {

    try {
      setShowSpinner(true);
      let l1Data = {
        "name": arg.name,
        "type": arg.type,
        "discipline": arg.discipline,
        "costBasis": arg.basis,
        "currencyId": arg.currency,
        "weighting": arg.weighting,
        "order": arg.order
      }

      if (arg.basis === 0) {
        l1Data.manHour = arg.manHour
      } else {
        l1Data.cost = arg.cost;
      }
      if (arg.model && arg.model !== 2) {
        l1Data.model = arg.model;
      }

      const saveL1 = await levelDetailsService.createL1(id, l1Data);
      return saveL1;
    } catch (error) {
      console.log(error);
      throw error;
    }

  };

  const createL2 = async (arg, parentId) => {

    try {
      setShowSpinner(true);
      let l2Data = {
        "name": arg.name,
        "type": arg.type,
        "discipline": arg.discipline,
        "costBasis": arg.basis,
        "currencyId": arg.currency,
        "parentId": parentId,
        "model": arg.model,
        "projectWeighting": arg.projectWeighting,
        "order": arg.order
      }

      if (l2Data.costBasis === 1) {
        l2Data.cost = arg.cost;
      } else {
        l2Data.manHour = arg.manHour;
      }

      if (!isNaN(arg.weighting)) {
        l2Data.weighting = arg.weighting;
      }

      const saveL2 = await levelDetailsService.createL2(id, l2Data);
      return saveL2.id;
    } catch (error) {
      throw error;
    }
  };

  const setLevelWeight = (l1Index, l2Index, number, value) => {
    let tempArray = [...importedLevels];
    if (number === 1) {
      tempArray[l1Index].weighting = value;
      setImportedLevels(tempArray);
    }

    if (number === 2) {
      tempArray[l1Index].l2s[l2Index].weighting = value;
      setImportedLevels(tempArray);
    }
  }

  const validateWeightDialog = () => {
    let formValid = true;

    for (var i = 0; i < importedLevels.length; i++) {
      if (importedLevels[i].weighting === '') {
        formValid = false;
      }
      for (var j = 0; j < importedLevels[i].l2s.length; j++) {
        if (importedLevels[i].l2s[j].weighting === '' && importedLevels[i].l2s[j].type === 1) {
          formValid = false;
        }
      }
    }

    return formValid;
  };

  const handleWeightDialogClose = () => {
    setShowWeightDialog(false);
  };

  return (
    <>
      <div className="fullWidth spaceEvenly marginTop30">
        <Grid item xs={10}  >
          <Paper className="fullWidth" elevation={2}>
            <Typography variant="h5" component="h4" className="padding20">
              Import Standard WBS
            </Typography>
            <Grid container spacing={2} className="padding20">
              <Grid item lg={2} xs={12}>
                <Button
                  fullWidth={true}
                  variant="contained"
                  size="small"
                  onClick={importDefaultLevels}
                  color="inherit"
                  className="greenBackground">
                  Standard WBS
                </Button>
              </Grid>
            </Grid>

          </Paper>
        </Grid>
      </div>
      <Dialog id="weightDialog" className="topLevelDialogContainer dialogWidth overFlowYScroll" open={showWeightDialog} onClose={handleWeightDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Weights</DialogTitle>
        <DialogContent className=" ">
          <Grid container spacing={0} >
            <Grid item lg={12} xs={12}>
              <TableContainer >
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '19%' }} align="left">Name</TableCell>
                      <TableCell style={{ width: '17%' }} align="right">Register</TableCell>
                      <TableCell style={{ width: '17%' }} align="right">Level Number</TableCell>
                      <TableCell style={{ width: '17%' }} align="right">Parent</TableCell>
                      <TableCell style={{ width: '25%', minWidth: '140px' }} align="right">Weight</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {importedLevels.map((item, index) => (
                      <React.Fragment>
                        <TableRow key={item.name}>
                          <TableCell align="left">{item.name}</TableCell>
                          <TableCell align="right">{item.type === 1 ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="right">{item.number}</TableCell>
                          <TableCell align="right">None</TableCell>
                          <TableCell align="right">
                            <TextField
                              id="weight"
                              label=""
                              variant="outlined"
                              size="small"
                              value={item.weighting}
                              onChange={(event) => setLevelWeight(index, null, 1, event.target.value)}
                              margin={'dense'}
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        {item.l2s.map((child, childIndex) => (
                          (child.type === 1) && <TableRow key={child.name}>
                            <TableCell align="left">{child.name}</TableCell>
                            <TableCell align="right">{child.type === 1 ? 'Yes' : 'No'}</TableCell>
                            <TableCell align="right">{child.number}</TableCell>
                            <TableCell align="right">{item.name}</TableCell>
                            <TableCell align="right">
                              <TextField
                                id="weightL2"
                                label=""
                                variant="outlined"
                                size="small"
                                value={child.weighting}
                                onChange={(event) => setLevelWeight(index, childIndex, 2, event.target.value)}
                                margin={'dense'}
                                type="number"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>

                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWeightDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitImportedLevels} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default ImportWBS;