import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import ProjectDetailsTabs from '../ProjectDetailsTabs/ProjectDetailsTabs';
import NewROCForm from './NewROCForm/NewROCForm';
import ROCTable from './ROCTable/ROCTable';
import { LevelsService } from '../../Service/LevelsService';
import { RuleOfCreditsService } from '../../Service/RuleOfCreditsService';
import { UsersService } from '../../Service/UsersService';
import * as PROJECTS from '../constants/projects';
import * as LEVELS from '../constants/levels';
import './ruleOfCredits.css';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';

const RuleOfCredits = (props) => {

  // URL Parameters
  const urlParams = useParams();

  // Module State
  const [project, setProject] = useState({});
  const projectId = urlParams.id;
  const [registers, setRegisters] = useState([]);
  const [userPermissions, setUserPermissions] = useState({
    systemAdmin: false,
    projectManager: false,
    projectRole: 0
  });

  // New ROC Form State
  const [newROC, setNewROC] = useState({ name: "", activitySteps: [] });
  const [newRocRegister, setNewRocRegister] = useState("");
  const [newStep, setNewStep] = useState({ name: "", percent: "" });
  const [nextActivityId, setNextActivityId] = useState(20);

  // ROC Table State
  const [ruleOfCredits, setRuleOfCredits] = useState([]);

  // Backdrop State
  const [showSpinner, setShowSpinner] = useState(false);

  //#region componentDidMount
  useEffect(() => {
    if (projectId) {
      _initialize();
    } else {
      alert('Invalid or no ID provided.');
      goHome();
    }
  }, []);

  const _initialize = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle("Rule of Credits");
      props.setViewMode("admin");

      const [levels, project, currentUser] = await Promise.all([
        LevelsService.getLevelsByProjectId(projectId),
        LevelsService.getProjectById(projectId),
        UsersService.getCurrentUser()
      ]);

      // Determine User permissions
      const permissions = _initializePermissions(currentUser, project);
      const validProjectRoles = [PROJECTS.Roles.ProjectAdmin, PROJECTS.Roles.InternalUser, PROJECTS.Roles.Viewer];
      
      if (
        !permissions.systemAdmin &&
        !permissions.projectManager &&
        !validProjectRoles.includes(permissions.projectRole)
      ) {
        alert('You do not have access to view the project');
        goHome();
      } 
      
      // Get ROC based Registers
      const fakeParentLvl = { type: 0, children: levels };
      const registers = _searchRegisters(fakeParentLvl);
      const rocRegisters = registers.filter(rg => rg.model === LEVELS.Model.RuleOfCredit);
      const ruleOfCredits = rocRegisters
        .filter(rg => rg.ruleOfCredits && rg.ruleOfCredits.length > 0)
        .map(rg => rg.ruleOfCredits)
        .flat();

      props.setPageTitle(`Rule of Credits - ${project.name}`);
      setProject(project);
      setRegisters(rocRegisters);
      setRuleOfCredits(ruleOfCredits);
      setUserPermissions(permissions);
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  };

  const _searchRegisters = (aLevel) => {

    if (aLevel.type === 1) {
      return [aLevel];
    }
    else if (aLevel.children && aLevel.children.length > 0) {
      const arrayOfRegisterArrays = aLevel.children.map(_searchRegisters);
      const flatRegisters = [].concat.apply([], arrayOfRegisterArrays);
      return flatRegisters;
    }
    else {
      return [];
    }
  };

  const _initializePermissions = (currentUser, project) => {
    const systemAdmin = currentUser.admin;

    const projectManagerId = (project.manager) ? project.manager.id : null;
    const projectManager = currentUser.id === projectManagerId;

    const userPermission = currentUser.permissions.find(p => p.projectId === parseInt(projectId));
    const projectRole = (userPermission) ? userPermission.role : PROJECTS.Roles.None;

    return {
      systemAdmin,
      projectManager,
      projectRole
    };
  };
  //#endregion

  //#region Navigation
  const history = useHistory();
  const goHome = () => {
    history.push('/');
  }
  //#endregion

  //#region New ROC Form Event Handlers
  const onChangeNewROCName = (event) => {
    setNewROC({ ...newROC, name: event.target.value });
  };

  const onChangeNewRocRegister = (event) => {
    setNewRocRegister(event.target.value)
  };

  const onChangeNewStepName = (event) => {
    setNewStep({ ...newStep, name: event.target.value });
  };

  const onChangeNewStepPercent = (event) => {
    setNewStep({ ...newStep, percent: event.target.value });
  };

  const addNewActivityStep = (event) => {
    event.stopPropagation();

    if (!newStep.name || newStep.name === "") {
      alert("Please fill out the new step's name");
      return;
    }
    if (!newStep.percent || newStep.percent === "") {
      alert("Please fill out the new step's percent");
      return;
    }

    // Updated steps
    const updatedActivitySteps = [...newROC.activitySteps];
    updatedActivitySteps.push(newStep);

    // Update new ROC obj
    const updatedROC = { ...newROC };
    updatedROC.activitySteps = updatedActivitySteps;

    setNewROC(updatedROC);
    setNewStep({ name: "", percent: "" });
  };

  const editActivityStep = (index, property, newValue) => {
    // Copy state
    const updatedROC = { ...newROC };
    const updatedSteps = [...updatedROC.activitySteps];

    updatedSteps[index][property] = newValue;
    updatedROC.activitySteps = updatedSteps;

    setNewROC(updatedROC);
  };

  const removeNewActivityStep = (event, index) => {
    event.stopPropagation();

    const updatedNewROC = { ...newROC };
    updatedNewROC.activitySteps = [...updatedNewROC.activitySteps];
    updatedNewROC.activitySteps.splice(index, 1);

    setNewROC(updatedNewROC);
  };

  const addRuleOfCredit = async () => {
    // Validate new ROC
    const [isValid, errorMessage] = _isNewROCValid(newROC);
    if (!isValid) {
      window.alert(errorMessage);
      return;
    }

    try {
      // Save ROC to DB
      setSnackQueue(queue => [...queue, { message: "Saving new Rule of Credit...", severity: "info", key: new Date().getTime() }]);

      // Add order property to each Activity Step
      newROC.activitySteps.forEach((step, index) => {
        step.order = index + 1;
      });

      const addedROC = await RuleOfCreditsService.addRuleOfCredit(projectId, newRocRegister.id, newROC);

      // Update ROC array
      const updatedRocList = [...ruleOfCredits];
      updatedRocList.push(addedROC);

      // Update Register Object
      const updatedRegisters = [...registers];
      const registerIndex = updatedRegisters.findIndex(rg => rg.id === newRocRegister.id);
      const updatedReg = updatedRegisters[registerIndex];

      if (updatedReg.ruleOfCredits && updatedReg.ruleOfCredits.length > 0) {
        updatedReg.ruleOfCredits = [...updatedReg.ruleOfCredits];
      }
      else {
        updatedReg.ruleOfCredits = [];
      }

      updatedReg.ruleOfCredits.push(addedROC);

      setRuleOfCredits(updatedRocList);
      setRegisters(updatedRegisters);
      setNewRocRegister("");
      setNewROC({ name: "", activitySteps: [] });
      setSnackQueue(queue => [...queue, { message: "Successfully saved new Rule of Credit", severity: "success", key: new Date().getTime() }]);
    }
    catch (error) {
      _basicErrorHandler(error);
    }
  };

  const _isNewROCValid = (newROC) => {
    // Validate the new Rule of Credit
    if (newROC.name === "") {
      return [false, "Please fill out the Rule of Credit name"];
    }

    if (newRocRegister == null || newRocRegister === "") {
      return [false, "Please select a Register"];
    }

    if (newROC.activitySteps.length === 0) {
      return [false, "Please add activity steps"];
    }

    // Validate the Activity Steps
    const activitySteps = newROC.activitySteps
    const length = activitySteps.length;

    if (length === 1) {
      if (activitySteps[0].percent == 100) {
        return [true, null];
      }
      else {
        return [false, "The single activity percent should be 100"];
      };
    }
    else if (length === 2) {
      if (activitySteps[0].percent <= 0 || activitySteps[0].percent >= 100) {
        return [false, "The first activity percent should be greater than 0 and less than 100"];
      }

      if (activitySteps[1].percent != 100) {
        return [false, "The final actvity percent should be 100"];
      }

      return [true, null];
    }
    else {
      var prevPercent = parseInt(activitySteps[0].percent);

      for (var i = 0; i < length; i++) {
        var percent = parseInt(activitySteps[i].percent);

        if (i === 0) {
          if (!(percent > 0 && percent < 100)) {
            return [false, "The first activity percent should be greater than 0 and less than 100"];
          }
        }
        else if (i < length - 1) {
          if (!(percent >= prevPercent && percent < 100)) {
            return [false, "Verify all intermediate activity percents are between 0-100 and increasing"];
          }
        }
        else {
          if (!(percent >= prevPercent && percent === 100)) {
            return [false, "The final actvity percent should be 100"];
          }
        }

        prevPercent = percent;
      }

      return [true, null];
    }
  };
  //#endregion

  //#region ROC Table Event Handlers
  const deleteRuleOfCredit = async (rocId, registerId) => {
    // Get indexes
    const rocIndex = ruleOfCredits.findIndex(roc => roc.id === rocId);
    const ruleOfCredit = ruleOfCredits[rocIndex];

    const proceed = window.confirm("Are you sure you want to delete the " + ruleOfCredit.name + " Rule of Credit?");
    if (!proceed) { return; }

    try {
      setSnackQueue(queue => [...queue, { message: "Deleting rule of credit...", severity: "info", key: new Date().getTime() }]);
      const deletedId = await RuleOfCreditsService.deleteRuleOfCredit(projectId, registerId, rocId);

      // Remove rule of credit
      const updatedRuleOfCredits = [...ruleOfCredits];
      updatedRuleOfCredits.splice(rocIndex, 1);

      // TODO: Update ROC within Register. Is it worth it?

      // Update state
      setRuleOfCredits(updatedRuleOfCredits);
      setSnackQueue(queue => [...queue, { message: "Successfully deleted rule of credit", severity: "success", key: new Date().getTime() }]);
    }
    catch (error) {
      _basicErrorHandler(error);
    }
  };

  const updateRuleOfCreditName = (rocId, newName) => {
    // Get ROC
    const rocIndex = ruleOfCredits.findIndex(roc => roc.id === rocId);
    const aROC = ruleOfCredits[rocIndex];

    // Create updated ROC
    const updatedROC = { ...aROC };
    updatedROC.name = newName;

    // Update state
    const updatedRocArray = [...ruleOfCredits];
    updatedRocArray[rocIndex] = updatedROC;

    setRuleOfCredits(updatedRocArray);
  };

  const updatedAnActivityStep = (rocId, stepId, prop, newValue) => {
    // Get ROC
    const rocIndex = ruleOfCredits.findIndex(roc => roc.id === rocId);
    const aROC = ruleOfCredits[rocIndex];

    // Get Activity Step
    const stepIndex = aROC.activitySteps.findIndex(step => step.id === stepId);
    const oldStep = aROC.activitySteps[stepIndex];

    // Create updated step
    const updatedStep = { ...oldStep };
    updatedStep[prop] = newValue;

    // Update state
    const updatedRocArray = [...ruleOfCredits];
    const updatedROC = updatedRocArray[rocIndex];
    updatedROC.activitySteps = [...updatedROC.activitySteps];
    updatedROC.activitySteps[stepIndex] = updatedStep;

    setRuleOfCredits(updatedRocArray);
  }

  const updateRuleOfCreditRegister = (rocId, register) => {
    // Get indexes
    const rocIndex = ruleOfCredits.findIndex(roc => roc.id === rocId);
    const anROC = ruleOfCredits[rocIndex];

    // Remove ROC from old parent
    // Add ROC to new parent

    // Update state
  };

  const deleteAnActivityStep = (rocId, stepId) => {
    // Get indexes
    const rocIndex = ruleOfCredits.findIndex(roc => roc.id === rocId);
    const stepIndex = ruleOfCredits[rocIndex].activitySteps.findIndex(step => step.id === stepId);

    // Update state
    const tempROCs = [...ruleOfCredits];
    tempROCs[rocIndex].activitySteps = [...ruleOfCredits[rocIndex].activitySteps];
    tempROCs[rocIndex].activitySteps.splice(stepIndex, 1);

    setRuleOfCredits(tempROCs);
  };

  const addAnActivityStep = (rocId, name, percent) => {
    // Get indexes
    const rocIndex = ruleOfCredits.findIndex(roc => roc.id === rocId);

    const tempROCs = [...ruleOfCredits];
    tempROCs[rocIndex].activitySteps = [...ruleOfCredits[rocIndex].activitySteps];

    const tmpActivitySteps = tempROCs[rocIndex].activitySteps;

    var index = 0;
    for (; index < tmpActivitySteps.length; index++) {
      if (percent <= tmpActivitySteps[index].percent) {
        break;
      }
    }

    tmpActivitySteps.splice(index, 0, {
      name: name,
      percent: parseInt(percent),
      id: nextActivityId
    })

    setRuleOfCredits(tempROCs);
    setNextActivityId(prevId => prevId + 1);
  };

  const saveRuleOfCredit = async (rocId, registerId) => {
    try {
      setSnackQueue(queue => [...queue, { message: "Saving rule of credit...", severity: "info", key: new Date().getTime() }]);

      // Get ROC
      const rocIndex = ruleOfCredits.findIndex(roc => roc.id === rocId);
      const aROC = ruleOfCredits[rocIndex];

      // Save change to DB
      const updatedROC = await RuleOfCreditsService.updateRuleOfCredit(projectId, registerId, aROC);
      setSnackQueue(queue => [...queue, { message: "Successfully saved rule of credit", severity: "success", key: new Date().getTime() }]);
    } catch (error) {
      _basicErrorHandler(error);
    }
  };

  const saveRuleOfCredits = async () => {
    try {
      setSnackQueue(queue => [...queue, { message: "Saving all rule of credits...", severity: "info", key: new Date().getTime() }]);

      const creditsWithRegisterIDs = ruleOfCredits.map(ruleOfCredit => {
        const aRegister = registers
          .filter(rg => rg.ruleOfCredits)
          .find(rg => {
            const index = rg.ruleOfCredits.findIndex(roc => roc.id === ruleOfCredit.id);
            return index !== -1;
          });

        return {
          ruleOfCredit: ruleOfCredit,
          registerId: aRegister.id
        };
      });

      const results = await Promise.all(creditsWithRegisterIDs.map(c =>
        RuleOfCreditsService.updateRuleOfCredit(projectId, c.registerId, c.ruleOfCredit)
      ));

      setSnackQueue(queue => [...queue, { message: "Successfully saved all rule of credits", severity: "success", key: new Date().getTime() }]);
    } catch (error) {
      _basicErrorHandler(error);
    };
  };
  //#endregion

  //#region Permissions
  const userHasReadOnlyAccess = () => {
    const readOnlyRoles = [PROJECTS.Roles.InternalUser, PROJECTS.Roles.Viewer];

    return (!userPermissions.systemAdmin && 
      !userPermissions.projectManager && 
      readOnlyRoles.includes(userPermissions.projectRole)
    );
  };
  //#endregion

  //#region Snackbar
  const [snackQueue, setSnackQueue] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  useEffect(() => {
    if (snackQueue.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackQueue[0] });
      setSnackQueue((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackQueue.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackQueue, messageInfo, open]);

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setOpen(false);
  };

  const exitSnackbar = () => {
    setMessageInfo(undefined);
  };
  //#endregion

  //#region Private Helper Functions
  const _basicErrorHandler = (error) => {
    console.log(error);

    if (error.data && error.data.detail) {
      alert(error.data.detail);
    }
    else {
      alert('An unexpected error ocurred. Please try again.\nIf error persists, contact the support team.');
    }
  };
  //#endregion

  return (
    <div className="RuleOfCredits">
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>

      <div className="fullWidth spaceEvenly marginTop marginBottom20">
        <ProjectDetailsTabs currentTab="ruleOfCredits"></ProjectDetailsTabs>
      </div>

      {!userHasReadOnlyAccess() &&
        <NewROCForm
          newROC={newROC}
          newRocRegister={newRocRegister}
          newStep={newStep}
          registers={registers}
          onChangeNewROCName={onChangeNewROCName}
          onChangeNewRocRegister={onChangeNewRocRegister}
          onChangeNewStepName={onChangeNewStepName}
          onChangeNewStepPercent={onChangeNewStepPercent}
          addNewActivityStep={addNewActivityStep}
          editActivityStep={editActivityStep}
          removeNewActivityStep={removeNewActivityStep}
          addRuleOfCredit={addRuleOfCredit}
        />
      }

      <ROCTable
        ruleOfCredits={ruleOfCredits}
        registers={registers}
        readOnly={userHasReadOnlyAccess()}
        deleteRuleOfCredit={deleteRuleOfCredit}
        updateRuleOfCreditName={updateRuleOfCreditName}
        updateRuleOfCreditRegister={updateRuleOfCreditRegister}
        updatedAnActivityStep={updatedAnActivityStep}
        deleteAnActivityStep={deleteAnActivityStep}
        addAnActivityStep={addAnActivityStep}
        saveRuleOfCredit={saveRuleOfCredit}
        saveRuleOfCredits={saveRuleOfCredits}
      >
      </ROCTable>

      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        onExited={exitSnackbar}
      >
        <Alert
          elevation={6}
          variant="filled"
          iconMapping={{ info: <AutorenewRoundedIcon /> }}
          severity={messageInfo ? messageInfo.severity : undefined}
        >
          {messageInfo ? messageInfo.message : undefined}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RuleOfCredits;