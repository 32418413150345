import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';

import ActivityImage from '../../assets/activityIMG.png';

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  landingButtonContainer: {
    backgroundColor: '#F1F4F9',
    borderRadius: '5px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    cursor: "pointer",
    "& button": {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      color: '#FFFFFF',
      fontSize: '25px',
      height: '100%',
      minWidth: '0',
      width: '100%'
    },
    "& img": {
      maxHeight: '90px',
      maxWidth: '100%'
    }
  }
}));

const LandingButton = (props) => {

  const classes = useStyles();

  return (      
    <Grid container justify="space-between" className={classes.landingButtonContainer} onClick={props.clickHandler}>
      
      <Grid item xs={11} container spacing={1} justify="flexStart" alignItems="center" style={{
        paddingBottom: '5px',
        paddingLeft: '20px',
        paddingTop: '5px'
      }}>

        <Grid item xs={4}>
          <img src={ActivityImage} />
        </Grid>
        
        <Grid item xs={8}>
          <Typography className={classes.headerText}>
            {props.children}
          </Typography>
        </Grid>

      </Grid>
      
      <Grid item xs={1}>
        <Button
          color="primary"
          variant="contained"
        >
          <ArrowForwardSharpIcon/>
        </Button>
      </Grid>
    </Grid>
  );
};

export default LandingButton;