import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ProjectDetailsTabs from "../ProjectDetailsTabs/ProjectDetailsTabs";
import { ProjectDetailsService } from "./projectDetailsService";
import { MasterDataService } from "./masterDataService";
import { UsersService } from "../../Service/UsersService";
import * as PROJECTS from "../constants/projects";
import "./projectDetails.css";
import { DATE_FORMAT } from "../constants/strings";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import "date-fns";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

const ProjectDetails = (props) => {
  /* URL Parameters */
  const { id } = useParams();

  /* State */
  const [project, setProject] = useState({
    name: "",
    projectNumber: "",
    externalReference: "",
    client: "",
    contractualStartDate: null,
    contractualEndDate: null,
    forecastStartDate: null,
    forecastEndDate: null,
    facilityEquipmentScope: "",
    subBusinessLine: "",
    executionLocation: "",
    facilityLocation: "",
    manager: "",
    projectAdmins: [],
    cutOffMonthDay: "",
    cutOffWeekDay: "",
    actualStartDate: null,
    actualEndDate: null,
  });
  const [errorObject, setErrorObject] = useState({
    projectName: {
      error: false,
      helperText: "",
    },
    projectNumber: {
      error: false,
      helperText: "",
    },
    externalReference: {
      error: false,
      helperText: "",
    },
    client: {
      error: false,
      helperText: "",
    },
    contractualStart: {
      error: false,
      helperText: "",
    },
    contractualEnd: {
      error: false,
      helperText: "",
    },
    projectAdmins: {
      error: false,
      helperText: "",
    },
    projectManager: {
      error: false,
      helperText: "",
    },
    forecastEnd: {
      error: false,
      helperText: "",
    },
    cutOffMonthDay: {
      error: false,
      helperText: "",
    },
    cutOffWeekDay: {
      error: false,
      helperText: "",
    },
    actualEnd: {
      error: false,
      helperText: "",
    },
  });
  const [projectPermissions, setProjectPermissions] = useState({
    systemAdmin: false,
    projectManager: false,
    projectAdmin: false,
    internalUser: false,
    viewer: false,
  });
  const [projectAdminOptions, setProjectAdminOptions] = useState([]);
  const [projectManagerOptions, setProjectManagerOptions] = useState([]);
  const [facilityEquipmentScopes, setFacilityEquipmentScopes] = useState([]);
  const [subBusinessLines, setSubBusinessLines] = useState([]);
  const [executionLocations, setExecutionLocations] = useState([]);
  const [facilityLocations, setFacilityLocations] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [cutOffMonthDays, setCutOffMonthDays] = useState([]);
  const [cutOffWeekDays, setCutOffWeekDays] = useState([]);

  const handleCloseDefault = () => {
    setDialogSettings({ title: "", message: "", open: false });
  };

  const handleCloseSuccess = () => {
    setDialogSettings({ title: "", message: "", open: false });
    window.location.reload();
  };

  const [dialogSettings, setDialogSettings] = useState({
    title: "",
    message: "",
    open: false,
    handleClose: handleCloseDefault,
  });

  /* componentDidMount */
  useEffect(() => {
    if (id) {
      _initialize();
    } else {
      alert("Invalid or no ID provided.");
      goHome();
    }
  }, []);

  const _initialProjectSetup = (projectDetails) => {
    let tempProject = {};

    tempProject.name = projectDetails.name;
    tempProject.projectNumber = projectDetails.internalReference;
    tempProject.externalReference = projectDetails.externalReference;
    tempProject.client = projectDetails.client;
    tempProject.manager = projectDetails.manager.id;
    tempProject.projectAdmins = projectDetails.permissions
      .filter((item) => item.role === PROJECTS.Roles.ProjectAdmin)
      .map((item) => item.user.id);
    tempProject.facilityEquipmentScope =
      projectDetails.facilityEquipmentScope.id;
    tempProject.subBusinessLine = projectDetails.subBusinessLine.id;
    tempProject.executionLocation = projectDetails.executionLocation.id;
    tempProject.facilityLocation = projectDetails.facilityLocation.id;
    tempProject.contractualStartDate = new Date(
      reverseDate(projectDetails.contractualStartDate)
    );
    tempProject.contractualEndDate = new Date(
      reverseDate(projectDetails.contractualEndDate)
    );
    tempProject.forecastStartDate = projectDetails.forecastStartDate
      ? new Date(reverseDate(projectDetails.forecastStartDate))
      : null;
    tempProject.forecastEndDate = projectDetails.forecastEndDate
      ? new Date(reverseDate(projectDetails.forecastEndDate))
      : null;
    tempProject.cutOffMonthDay = projectDetails.cutOffMonthDay;
    tempProject.cutOffWeekDay = projectDetails.cutOffWeekDay;
    tempProject.actualStartDate = projectDetails.actualStartDate
      ? new Date(reverseDate(projectDetails.actualStartDate))
      : null;
    tempProject.actualEndDate = projectDetails.actualEndDate
      ? new Date(reverseDate(projectDetails.actualEndDate))
      : null;
    tempProject.permissions = projectDetails.permissions;

    return tempProject;
  };

  const reverseDate = (date) => {
    let tempDateArray = date.split("-");
    return tempDateArray[1] + "-" + tempDateArray[2] + "-" + tempDateArray[0];
  };

  const _initializePermissions = (currentUser, tmpProject) => {
    const systemAdmin = currentUser.admin;
    const projectManager = currentUser.id === tmpProject.manager;
    const projectAdmin = tmpProject.projectAdmins.includes(currentUser.id);
    const internalUser = !!currentUser.permissions.find(
      (p) =>
        p.projectId === parseInt(id) && p.role === PROJECTS.Roles.InternalUser
    );
    const viewer = !!currentUser.permissions.find(
      (p) => p.projectId === parseInt(id) && p.role === PROJECTS.Roles.Viewer
    );

    return {
      systemAdmin,
      projectManager,
      projectAdmin,
      internalUser,
      viewer,
    };
  };

  /* navigates home */
  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };

  /* these 4 functions change the date to what the user picks */
  const handleContractualStartDateChange = (date) => {
    const updatedProject = { ...project };
    updatedProject.contractualStartDate = date;
    setProject(updatedProject);
  };
  const handleContractualEndDateChange = (date) => {
    const updatedProject = { ...project };
    updatedProject.contractualEndDate = date;
    setProject(updatedProject);
  };
  const handleForecastStartDateChange = (date) => {
    const updatedProject = { ...project };
    updatedProject.forecastStartDate = date;
    setProject(updatedProject);
  };
  const handleForecastEndDateChange = (date) => {
    const updatedProject = { ...project };
    updatedProject.forecastEndDate = date;
    setProject(updatedProject);
  };
  const handleActualStartDateChange = (date) => {
    const updatedProject = { ...project };
    updatedProject.actualStartDate = date;
    setProject(updatedProject);
  };
  const handleActualEndDateChange = (date) => {
    const updatedProject = { ...project };
    updatedProject.actualEndDate = date;
    setProject(updatedProject);
  };

  /* these 4 functions change the select option when the user selects a new option */
  const handleFacilityEquipmentScopeChange = (event) => {
    const updatedProject = { ...project };
    updatedProject.facilityEquipmentScope = event.target.value;
    setProject(updatedProject);
  };
  const handleSubBusinessLineChange = (event) => {
    const updatedProject = { ...project };
    updatedProject.subBusinessLine = event.target.value;
    setProject(updatedProject);
  };
  const handleExecutionLocationChange = (event) => {
    const updatedProject = { ...project };
    updatedProject.executionLocation = event.target.value;
    setProject(updatedProject);
  };
  const handleFacilityLocationChange = (event) => {
    const updatedProject = { ...project };
    updatedProject.facilityLocation = event.target.value;
    setProject(updatedProject);
  };

  /* these 3 functions change the select options for the people drop downs */

  const handleProjectManagerChange = (event) => {
    const updatedProject = { ...project };
    updatedProject.manager = event.target.value;
    setProject(updatedProject);
  };

  const handleProjectAdminsChange = (event) => {
    const updatedProject = { ...project };
    if (updatedProject.projectAdmins.length < event.target.value.length) {
      addProjectAdmin(updatedProject, event.target.value);
    } else {
      removeProjectAdmin(updatedProject, event.target.value);
    }
  };
  
  const addProjectAdmin = (projectToUpdate, adminIds) => {
    const lastAdmin = adminIds.slice(-1)[0];
    projectToUpdate.permissions.push({
      role: 1,
      user: {
        id: lastAdmin
      }
    });
    
    projectToUpdate.projectAdmins = adminIds;
    setProject(projectToUpdate);
  };
  
  const removeProjectAdmin = (projectToUpdate, adminIds) => {
    const removedAdmin = projectToUpdate.projectAdmins.filter(admin => !adminIds.includes(admin))[0];
    const permission = projectToUpdate.permissions.find(permission => permission.user.id === removedAdmin);
    if (permission) {
      permission.role = 2
    }
    projectToUpdate.projectAdmins = adminIds;
    setProject(projectToUpdate);
  };
  
  /* Private Helpers Functions */
  const _initialize = async () => {
    try {
      setShowSpinner(true);
      props.setPageTitle("Project Details");
      props.setViewMode("admin");

      const [
        project,
        currentUser,
        facilityEquipmentScopes,
        subBusinessLines,
        executionLocations,
        facilityLocations,
        allUsers,
      ] = await Promise.all([
        ProjectDetailsService.getProject(id),
        UsersService.getCurrentUser(),
        MasterDataService.getFacilityEquipmentScopes(),
        MasterDataService.getSubBusinessLines(),
        MasterDataService.getExecutionLocations(),
        MasterDataService.getFacilityLocations(),
        MasterDataService.getAllUsers(),
      ]);

      const tmpProject = _initialProjectSetup(project);

      // Determine User permissions
      const projectPermissions = _initializePermissions(
        currentUser,
        tmpProject
      );
      if (
        !projectPermissions.systemAdmin &&
        !projectPermissions.projectManager &&
        !projectPermissions.projectAdmin &&
        !projectPermissions.internalUser &&
        !projectPermissions.viewer
      ) {
        alert("You do not have access to view the project");
        goHome();
      }

      props.setPageTitle(`Project Details - ${project.name}`);
      setProject(tmpProject);
      setProjectPermissions(projectPermissions);
      setProjectAdminOptions(allUsers.users);
      setProjectManagerOptions(allUsers.users);
      setFacilityEquipmentScopes(facilityEquipmentScopes);
      setSubBusinessLines(subBusinessLines);
      setExecutionLocations(executionLocations);
      setFacilityLocations(facilityLocations);
      setCutOffMonthDays([...Array(28).keys()].map((k) => k + 1));
      setCutOffWeekDays([
        { label: "Sunday", value: 0 },
        { label: "Monday", value: 1 },
        { label: "Tuesday", value: 2 },
        { label: "Wednesday", value: 3 },
        { label: "Thursday", value: 4 },
        { label: "Friday", value: 5 },
        { label: "Saturday", value: 6 },
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  };

  const validateNewProjectForm = () => {
    let formValid = true;
    let errorObject = {
      projectName: {
        error: false,
        helperText: "",
      },
      projectNumber: {
        error: false,
        helperText: "",
      },
      externalReference: {
        error: false,
        helperText: "",
      },
      client: {
        error: false,
        helperText: "",
      },
      contractualStart: {
        error: false,
        helperText: "",
      },
      contractualEnd: {
        error: false,
        helperText: "",
      },
      projectAdmins: {
        error: false,
        helperText: "",
      },
      projectManager: {
        error: false,
        helperText: "",
      },
      forecastEnd: {
        error: false,
        helperText: "",
      },
      cutOffMonthDay: {
        error: false,
        helperText: "",
      },
      cutOffWeekDay: {
        error: false,
        helperText: "",
      },
      actualEnd: {
        error: false,
        helperText: "",
      },
    };

    if (!project.name) {
      formValid = false;
      errorObject.projectName.error = true;
      errorObject.projectName.helperText = "Project Name is required";
    }
    if (!project.projectNumber) {
      formValid = false;
      errorObject.projectNumber.error = true;
      errorObject.projectNumber.helperText = "Project Number is required";
    }
    if (!project.externalReference) {
      formValid = false;
      errorObject.externalReference.error = true;
      errorObject.externalReference.helperText =
        "External Reference is required";
    }
    if (!project.client) {
      formValid = false;
      errorObject.client.error = true;
      errorObject.client.helperText = "Client is required";
    }

    /** Contractual Dates */
    if (!project.contractualStartDate) {
      formValid = false;
      errorObject.contractualStart.error = true;
      errorObject.contractualStart.helperText =
        "Contractual Start Date is required";
    }
    if (!project.contractualEndDate) {
      formValid = false;
      errorObject.contractualEnd.error = true;
      errorObject.contractualEnd.helperText =
        "Contractual End Date is required";
    }
    if (
      project.contractualStartDate &&
      project.contractualEndDate &&
      project.contractualEndDate < project.contractualStartDate
    ) {
      formValid = false;
      errorObject.contractualEnd.error = true;
      errorObject.contractualEnd.helperText =
        "Contractual End Date cannot be before Contractual Start Date";
    }

    if (!project.projectAdmins.length > 0) {
      formValid = false;
      errorObject.projectAdmins.error = true;
      errorObject.projectAdmins.helperText = "Project Admins are required";
    }
    if (!project.manager) {
      formValid = false;
      errorObject.projectManager.error = true;
      errorObject.projectManager.helperText = "Project Manager is required";
    }

    /** Forecast Dates */
    if (
      project.forecastStartDate != null &&
      project.forecastEndDate != null &&
      project.forecastEndDate < project.forecastStartDate
    ) {
      formValid = false;
      errorObject.forecastEnd.error = true;
      errorObject.forecastEnd.helperText =
        "Forecast End Date cannot be before Forecast Start Date";
    }

    /** Actual Dates */
    if (
      project.actualStartDate != null &&
      project.actualEndDate != null &&
      project.actualEndDate < project.actualStartDate
    ) {
      formValid = false;
      errorObject.actualEnd.error = true;
      errorObject.actualEnd.helperText =
        "Actual End Date cannot be before Actual Start Date";
    }

    if (!project.cutOffMonthDay) {
      formValid = false;
      errorObject.cutOffMonthDay.error = true;
      errorObject.cutOffMonthDay.helperText = "Monthly Cutoff Day is required";
    }
    if (project.cutOffWeekDay === "") {
      formValid = false;
      errorObject.cutOffWeekDay.error = true;
      errorObject.cutOffWeekDay.helperText = "Weekly Cutoff Day is required";
    }

    setErrorObject({ ...errorObject });

    return formValid;
  };

  const dateToString = (dateObject) => {
    var year = dateObject.getFullYear();
    var month = dateObject.getMonth() + 1;
    var day = dateObject.getDate();

    var returnString = year + "-" + month + "-" + day;

    return returnString;
  };

  const updateProject = async () => {
    if (validateNewProjectForm()) {
      try {
        setShowSpinner(true);

        let projectData = {
          id: id,
          name: project.name,
          client: project.client,
          internalReference: project.projectNumber,
          externalReference: project.externalReference,
          contractualStartDate: dateToString(project.contractualStartDate),
          contractualEndDate: dateToString(project.contractualEndDate),
          forecastStartDate:
            project.forecastStartDate != null
              ? dateToString(project.forecastStartDate)
              : null,
          forecastEndDate:
            project.forecastEndDate != null
              ? dateToString(project.forecastEndDate)
              : null,
          managerId: project.manager,
          facilityEquipmentScopeId: project.facilityEquipmentScope,
          subBusinessLineId: project.subBusinessLine,
          executionLocationId: project.executionLocation,
          facilityLocationId: project.facilityLocation,
          cutOffMonthDay: project.cutOffMonthDay,
          cutOffWeekDay: project.cutOffWeekDay,
          actualStartDate:
            project.actualStartDate != null
              ? dateToString(project.actualStartDate)
              : null,
          actualEndDate:
            project.actualEndDate != null
              ? dateToString(project.actualEndDate)
              : null,
          permissions: mapUserPermissions(project.permissions),
        };

        const updateProjectTmp = await ProjectDetailsService.updateProject(
          projectData
        );
        if (updateProjectTmp) {
          setDialogSettings({
            title: "",
            message: "Project updated successfully",
            open: true,
            handleClose: handleCloseSuccess,
          });
        }
      } catch (error) {
        setDialogSettings({
          title: error.data.title,
          message: error.data.detail,
          open: true,
          handleClose: handleCloseDefault,
        });
        setShowSpinner(false);
      }
    } else {
      alert("Please make sure all the required fields are filled properly.");
    }
  };

  const mapUserPermissions = (permissions) =>
    permissions.map((permission) => {
      return { role: permission.role, userId: permission.user.id };
    });

  const _userOnlyHasReadAccess = () => {
    const readWriteAccess =
      projectPermissions.systemAdmin ||
      projectPermissions.projectManager ||
      projectPermissions.projectAdmin;
    const onlyReadAccess =
      projectPermissions.internalUser || projectPermissions.viewer;

    return !readWriteAccess && onlyReadAccess;
  };

  return (
    <div className="ProjectDetails fullWidth  marginTop">
      <Backdrop className="backDropZIndex" open={showSpinner}>
        <CircularProgress color="primary" />
      </Backdrop>

      <div className="fullWidth spaceEvenly marginTop marginBottom20">
        <ProjectDetailsTabs currentTab="projectDetails"></ProjectDetailsTabs>
      </div>
      <div className="fullWidth spaceEvenly">
        <Grid item xs={10}>
          <Paper className="fullWidth" elevation={2}>
            <form
              id="projectDetails"
              name="projectDetails"
              autoComplete="off"
              className="padding20"
            >
              <Grid container spacing={3}>
                {/* Project Name Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      id="outlined-basic"
                      label="Project Name"
                      variant="outlined"
                      size="small"
                      value={project.name}
                      onChange={(event) => {
                        setProject({
                          ...project,
                          name: event.target.value,
                        });
                      }}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      error={errorObject.projectName.error}
                      helperText={errorObject.projectName.helperText}
                      required
                    />
                  </FormControl>
                </Grid>
                {/* Project Number */}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      className="fullWidth"
                      id="outlined-basic"
                      label="Project Number"
                      variant="outlined"
                      size="small"
                      value={project.projectNumber}
                      onChange={(event) => {
                        setProject({
                          ...project,
                          projectNumber: event.target.value,
                        });
                      }}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      error={errorObject.projectNumber.error}
                      helperText={errorObject.projectNumber.helperText}
                    />
                  </FormControl>
                </Grid>
                {/* External Reference */}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      className="fullWidth"
                      id="outlined-basic"
                      label="External Reference"
                      variant="outlined"
                      size="small"
                      value={project.externalReference}
                      onChange={(event) => {
                        setProject({
                          ...project,
                          externalReference: event.target.value,
                        });
                      }}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      error={errorObject.externalReference.error}
                      helperText={errorObject.externalReference.helperText}
                    />
                  </FormControl>
                </Grid>
                {/* Client Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl fullWidth required>
                    <TextField
                      className="fullWidth"
                      id="outlined-basic"
                      label="Client"
                      variant="outlined"
                      size="small"
                      value={project.client}
                      onChange={(event) => {
                        setProject({
                          ...project,
                          client: event.target.value,
                        });
                      }}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      error={errorObject.client.error}
                      helperText={errorObject.client.helperText}
                      required
                    />
                  </FormControl>
                </Grid>
                {/* Project Manager Required */}
                <Grid item lg={6} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="project-manager">
                      Project Manager
                    </InputLabel>
                    <Select
                      labelId="project-manager"
                      id="demo-simple-select-outlined"
                      label="Project Manager"
                      margin="dense"
                      value={project.manager}
                      onChange={handleProjectManagerChange}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      error={errorObject.projectManager.error}
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {projectManagerOptions.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorObject.projectManager.helperText}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Project Admins Required*/}
                <Grid item lg={6} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="demo-multiple-chip-label">
                      Project Admins
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={project.projectAdmins}
                      onChange={handleProjectAdminsChange}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      input={<Input id="select-multiple-chip" />}
                      margin="dense"
                      renderValue={(selected) => (
                        <div className="flexWrap">
                          {selected.map((value) => {
                            const tempAdmin = projectAdminOptions.find(
                              (admin) => {
                                return admin.id === value;
                              }
                            );
                            if (tempAdmin) {
                              return (
                                <Chip
                                  key={tempAdmin.id}
                                  label={tempAdmin.name}
                                />
                              );
                            }
                          })}
                        </div>
                      )}
                      error={errorObject.projectAdmins.error}
                    >
                      {projectAdminOptions.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={
                              project.projectAdmins.indexOf(item.id) > -1
                            }
                          />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorObject.projectAdmins.helperText}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Facility Equipment Scope */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="facility-label">
                      Facility Equipment Scope
                    </InputLabel>
                    <Select
                      labelId="facility-label"
                      id="demo-simple-select-outlined"
                      label="Facility Equipment Scope"
                      margin="dense"
                      value={project.facilityEquipmentScope}
                      onChange={handleFacilityEquipmentScopeChange}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {facilityEquipmentScopes.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Sub Business Line */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="sub-business-line-label">
                      Sub Business Line
                    </InputLabel>
                    <Select
                      labelId="sub-business-line-label"
                      id="demo-simple-select-outlined"
                      label=" Sub Business Line"
                      margin="dense"
                      value={project.subBusinessLine}
                      onChange={handleSubBusinessLineChange}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {subBusinessLines.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Execution Location */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="execution-location-label">
                      Execution Location
                    </InputLabel>
                    <Select
                      labelId="execution-location-label"
                      id="demo-simple-select-outlined"
                      label="Execution Location"
                      margin="dense"
                      value={project.executionLocation}
                      onChange={handleExecutionLocationChange}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {executionLocations.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Facility Location */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="facility-location-label">
                      Facility Location
                    </InputLabel>
                    <Select
                      labelId="facility-location-label"
                      id="demo-simple-select-outlined"
                      label="Facility Location"
                      margin="dense"
                      value={project.facilityLocation}
                      onChange={handleFacilityLocationChange}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {facilityLocations.map((option, index) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Contractual Start Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="date-picker-inline"
                        label="Contractual Start"
                        value={project.contractualStartDate}
                        onChange={handleContractualStartDateChange}
                        autoOk={true}
                        error={errorObject.contractualStart.error}
                        helperText={errorObject.contractualStart.helperText}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        readOnly={_userOnlyHasReadAccess()}
                        InputProps={{
                          readOnly: _userOnlyHasReadAccess(),
                        }}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Contractual End Required*/}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="date-picker-inline"
                        label="Contractual End"
                        minDate={project.contractualStartDate}
                        value={project.contractualEndDate}
                        onChange={handleContractualEndDateChange}
                        autoOk={true}
                        error={errorObject.contractualEnd.error}
                        helperText={errorObject.contractualEnd.helperText}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        readOnly={_userOnlyHasReadAccess()}
                        InputProps={{
                          readOnly: _userOnlyHasReadAccess(),
                        }}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Forecast Start */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="date-picker-inline"
                        label="Forecast Start"
                        value={project.forecastStartDate}
                        onChange={handleForecastStartDateChange}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        readOnly={_userOnlyHasReadAccess()}
                        InputProps={{
                          readOnly: _userOnlyHasReadAccess(),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Forecast End */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="date-picker-inline"
                        label="Forecast End"
                        minDate={project.forecastStartDate}
                        value={project.forecastEndDate}
                        onChange={handleForecastEndDateChange}
                        autoOk={true}
                        error={errorObject.forecastEnd.error}
                        helperText={errorObject.forecastEnd.helperText}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        readOnly={_userOnlyHasReadAccess()}
                        InputProps={{
                          readOnly: _userOnlyHasReadAccess(),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Actual Start */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="actual-start-date"
                        label="Actual Start"
                        value={project.actualStartDate}
                        onChange={handleActualStartDateChange}
                        autoOk={true}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        readOnly={_userOnlyHasReadAccess()}
                        InputProps={{
                          readOnly: _userOnlyHasReadAccess(),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Actual End */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format={DATE_FORMAT}
                        margin="dense"
                        id="actual-end-date"
                        label="Actual End"
                        minDate={project.actualStartDate}
                        value={project.actualEndDate}
                        onChange={handleActualEndDateChange}
                        autoOk={true}
                        error={errorObject.actualEnd.error}
                        helperText={errorObject.actualEnd.helperText}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        readOnly={_userOnlyHasReadAccess()}
                        InputProps={{
                          readOnly: _userOnlyHasReadAccess(),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                {/* Monthly Cutoff Date */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="monthly-cutoff-date-label">
                      Monthly Cutoff Day
                    </InputLabel>
                    <Select
                      id="monthly-cutoff-date"
                      labelId="monthly-cutoff-date-label"
                      label="Monthly Cutoff Day"
                      margin="dense"
                      value={project.cutOffMonthDay}
                      defaultValue={1}
                      onChange={(event) => {
                        setProject({
                          ...project,
                          cutOffMonthDay: event.target.value,
                        });
                      }}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      error={errorObject.cutOffMonthDay.error}
                      required
                    >
                      {cutOffMonthDays.map((option, index) => (
                        <MenuItem
                          value={option}
                          key={"cutOffMonthDay_" + option}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorObject.cutOffMonthDay.helperText}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Weekly Cutoff Date */}
                <Grid item lg={3} xs={12}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="weekly-cutoff-date-label">
                      Weekly Cutoff Day
                    </InputLabel>
                    <Select
                      id="weekly-cutoff-date"
                      labelId="weekly-cutoff-date-label"
                      label="Weekly Cutoff Day"
                      margin="dense"
                      value={project.cutOffWeekDay}
                      defaultValue={0}
                      onChange={(event) => {
                        setProject({
                          ...project,
                          cutOffWeekDay: event.target.value,
                        });
                      }}
                      inputProps={{
                        readOnly: _userOnlyHasReadAccess(),
                      }}
                      error={errorObject.cutOffWeekDay.error}
                      required
                    >
                      {cutOffWeekDays.map((option, index) => (
                        <MenuItem value={option.value} key={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorObject.cutOffWeekDay.helperText}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
            {/* Action button container */}
            <Grid
              container
              justify="flex-end"
              spacing={3}
              className="spaceEvenly paddingBottom10"
            >
              <Grid item lg={3} xs={12} className="spaceEvenly flexWrap">
                <Grid item lg={5} xs={12} className="padding10">
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="primary"
                    onClick={updateProject}
                    disabled={_userOnlyHasReadAccess()}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item lg={5} xs={12} className="padding10">
                  <Button fullWidth={true} color="secondary" onClick={goHome}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>

      <Dialog
        aria-labelledby="error'title"
        open={dialogSettings.open}
        onClose={dialogSettings.handleClose}
      >
        <DialogTitle id="error'title">{dialogSettings.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error'description">
            {dialogSettings.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogSettings.handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ProjectDetails;
