import React from 'react';
import { useHistory } from "react-router-dom";

import StyledTableCell from '../Shared/StyledTableCell/StyledTableCell';

import './DashboardTable.css';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('PCM', '1DHSB2229', 'Saudi Aramco', 'Abdullah Mohammad', 10),
    createData('Software Matrix', '92MDG76ZBDBN', 'Cameron', 'Lan Zhang', 30),
    createData('SAP Rollout', '9MNVGYUKSAN', 'Shell', 'Jairo Vera', 100),
    createData('Facility XYZ', '8WSNB2KL', 'Chevron', 'Nasser Ayub', 65),
    createData('Construction XYZ', '02NCGHSA7', 'Halliburton', 'Jane Doe', 95),
    createData('Construction ABC', '02NCGHSA7', 'Halliburton', 'Jane Doe', 20),
    createData('XYZ', '02NCGHSA7', 'Halliburton', 'Jane Doe', 95),
    createData('Facility XYZ v2', '8WSNB2KL', 'Chevron', 'Nasser Ayub', 65),
    createData('SAP Rollout Finale', '9MNVGYUKSAN', 'Shell', 'Jairo Vera', 100),
    createData('Construction ABC v3', '02NCGHSA7', 'Halliburton', 'Jane Doe', 20),
    createData('PCM Rollout', '1DHSB2229 ', 'Saudi Aramco', 'Abdullah Mohammad', 10),
    createData('Software Matrix v2', '92MDG76ZBDBN', 'Cameron', 'Lan Zhang', 30),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const DashBoardTable = (props) => {
    const classes = useStyles();
    const history = useHistory();


    const getClassName = (value) => {

        if (value > 90) {
            return 'successColor';
        } else {
            if (value > 50) {
                return 'warningColor';
            } else {
                return 'errorColor';
            }
        }
    }

    const navigateToProjectDetails = (projectId) => {
        history.push('/project-details/' + projectId);
    }



    return (
        <TableContainer className="dashBoardTable" component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Project Name</StyledTableCell>
                        <StyledTableCell align="right">Project Number</StyledTableCell>
                        <StyledTableCell align="right">Client</StyledTableCell>
                        <StyledTableCell align="right">Project Manager</StyledTableCell>
                        <StyledTableCell align="right">Project Location</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.projects.map((project) => (
                        <TableRow key={project.id} hover={true} onClick={() => navigateToProjectDetails(project.id)} className="tableRow">
                            <StyledTableCell component="th" scope="row">
                                {project.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">{project.internalReference}</StyledTableCell>
                            <StyledTableCell align="right">{project.client}</StyledTableCell>
                            <StyledTableCell align="right">{project.manager.name}</StyledTableCell>
                            <StyledTableCell align="right">{project.facilityLocation.name}</StyledTableCell>
                        </TableRow >
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
}

export default DashBoardTable;