import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleAltSharpIcon from "@material-ui/icons/PeopleAltSharp";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import GroupAddRoundedIcon from "@material-ui/icons/GroupAddRounded";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { ReactComponent as Logo } from "../../assets/PCMlogo.svg";
import "./AppBar.css";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  list: {
    width: "250px",
    "& .navLinks": {
      textDecoration: "none",
      color: "inherit",
    },
    "& .navLinks:hover": {
      textDdecoration: "none",
    },
  },
  logo: {
    height: "39px",
    width: "218px",
  },
  rightButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Roboto",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
}));

export const ButtonAppBar = (props) => {
  const classes = useStyles();

  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const hamburgerOptions = [
    {
      to: "/",
      label: "Home",
      icon: <HomeRoundedIcon />,
    },
    {
      to: "/dashboard",
      label: "Dashboard",
      icon: <DashboardRoundedIcon />,
    },
    {
      to: "/new-project",
      label: "New Project",
      icon: <AddRoundedIcon />,
    },
    {
      to: "/user-management",
      label: "User Management",
      icon: <GroupAddRoundedIcon />,
    },
  ];

  const outputRightIcons = () => {
    let icons = null;
    let containerWidth = null;

    if (props.viewMode === "admin") {
      icons = (
        <IconButton color="inherit">
          <PersonSharpIcon />
        </IconButton>
      );
      containerWidth = "105px";
    } else if (props.viewMode === "user") {
      icons = (
        <>
          <IconButton color="inherit">
            <PeopleAltSharpIcon />
          </IconButton>

          <IconButton color="inherit">
            <InfoOutlinedIcon />
          </IconButton>
        </>
      );
      containerWidth = "160px";
    }

    return (
      <div
        className={classes.rightButtonsContainer}
        style={{ width: containerWidth }}
      >
        {icons}

        <IconButton
          onClick={toggleDrawer(true)}
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor={"right"}
          open={openDrawer}
          onClose={toggleDrawer(false)}
        >
          <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {hamburgerOptions.map((option) => (
                <Link to={option.to} className="navLinks">
                  <ListItem button>
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText primary={option.label} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  };

  return (
    <div>
      <AppBar position="sticky">
        <Toolbar>
          {props.pageTitle ? (
            <>
              <IconButton color="inherit" onClick={goHome}>
                <ArrowBackSharpIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {props.pageTitle}
              </Typography>
            </>
          ) : (
            <div>
              <Link to="/">
                <Logo className={classes.logo} />
              </Link>
            </div>
          )}

          <div className={classes.grow} />

          {outputRightIcons()}
        </Toolbar>
      </AppBar>
    </div>
  );
};
