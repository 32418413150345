import React, { useState, useEffect } from "react";

import StyledTableCell from "../../Shared/StyledTableCell/StyledTableCell";

import "./progressReport.css";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Alert from "@material-ui/lab/Alert";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import { DATE_FORMAT } from "../../constants/strings";

const ProgressReport = (props) => {

  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    setSelectedTime(moment());
    props.onChangeCutOffDate( moment().format("yyyy-MM-DD"))
  }, []);

  const _formatDecimalNumber = (num) => {
    return `${num.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}%`;
  };

  const computeL1Row = (l1) => {
    const computedData = {
      weight: l1.weight.value,
      plan: 0,
      forecast: 0,
      actual: 0,
      planVariance: 0,
      forecastVariance: 0,
    };

    if (l1.children) {
      l1.children.forEach((l2) => {
        computedData.plan += l2.weight.plan;
        computedData.forecast += l2.weight.forecast;
        computedData.actual += l2.weight.actual;
        computedData.planVariance += l2.weight.planVariance;
        computedData.forecastVariance += l2.weight.forecastVariance;
      });
    }

    return (
      <TableRow key={l1.id}>
        <StyledTableCell>{l1.name}</StyledTableCell>
        <StyledTableCell align="right">
          {_formatDecimalNumber(computedData.weight)}
        </StyledTableCell>
        <StyledTableCell align="right">
          {_formatDecimalNumber(computedData.plan)}
        </StyledTableCell>
        <StyledTableCell align="right">
          {_formatDecimalNumber(computedData.forecast)}
        </StyledTableCell>
        <StyledTableCell align="right">
          {_formatDecimalNumber(computedData.actual)}
        </StyledTableCell>
        <StyledTableCell align="right">
          {_formatDecimalNumber(computedData.planVariance)}
        </StyledTableCell>
        <StyledTableCell align="right">
          {_formatDecimalNumber(computedData.forecastVariance)}
        </StyledTableCell>
      </TableRow>
    );
  };

  const computeOverall = (l1s) => {
    const overall = l1s.reduce(
      (cumulative, l1) => ({
        name: cumulative.name,
        weight: cumulative.weight + l1.weight.value,
        plan: cumulative.plan + l1.weight.plan,
        forecast: cumulative.forecast + l1.weight.forecast,
        actual: cumulative.actual + l1.weight.actual,
        planVariance: cumulative.planVariance + l1.weight.planVariance,
        forecastVariance:
          cumulative.forecastVariance + l1.weight.forecastVariance,
      }),
      {
        name: "Overall",
        weight: 0,
        plan: 0,
        forecast: 0,
        actual: 0,
        planVariance: 0,
        forecastVariance: 0,
      }
    );

    return (
      <TableRow>
        <StyledTableCell className="overall">{overall.name}</StyledTableCell>
        <StyledTableCell className="overall" align="right">
          {_formatDecimalNumber(overall.weight)}
        </StyledTableCell>
        <StyledTableCell className="overall" align="right">
          {_formatDecimalNumber(overall.plan)}
        </StyledTableCell>
        <StyledTableCell className="overall" align="right">
          {_formatDecimalNumber(overall.forecast)}
        </StyledTableCell>
        <StyledTableCell className="overall" align="right">
          {_formatDecimalNumber(overall.actual)}
        </StyledTableCell>
        <StyledTableCell className="overall" align="right">
          {_formatDecimalNumber(overall.planVariance)}
        </StyledTableCell>
        <StyledTableCell className="overall" align="right">
          {_formatDecimalNumber(overall.forecastVariance)}
        </StyledTableCell>
      </TableRow>
    );
  };

  return (
    <div className="ProgressReport padding20">
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={3}
      >
        {props.liveError && (
          <Grid item xs={12}>
            <Alert severity="error">{props.liveError}</Alert>
          </Grid>
        )}

        <Grid item xs={12} lg={3}>
          <FormControl variant="outlined" fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={DATE_FORMAT}
                margin="dense"
                label={"Cutoff Date"}
                value={selectedTime}
                onChange={(newDate) => {
                  props.onChangeCutOffDate(
                    moment(newDate).format("yyyy-MM-DD")
                  );
                  setSelectedTime(newDate);
                }}
                autoOk={true}
                KeyboardButtonProps={{ "aria-label": "change date" }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            className="progress-report-table-container"
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Disciplines</StyledTableCell>
                  <StyledTableCell align="right">WTG</StyledTableCell>
                  <StyledTableCell align="right">Plan</StyledTableCell>
                  <StyledTableCell align="right">Forecast</StyledTableCell>
                  <StyledTableCell align="right">Actual</StyledTableCell>
                  <StyledTableCell align="right">Plan Variance</StyledTableCell>
                  <StyledTableCell align="right">
                    Forecast Variance
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.levels.map(computeL1Row)}
                {computeOverall(props.levels)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgressReport;
