export const appConfig = {
    baseUrl: 'https://app-8798-pcm-evp.azurewebsites.net/api',
    clientId: 'b1f1697d-4f61-42bf-a2a8-15e33c4bbdd3',
    appInsightsInstrumentationKey: '9d23f9ff-a87b-491e-aee9-3b5fc020038c',
    authority: {
        api: 'https://login.microsoftonline.com/41ff26dc-250f-4b13-8981-739be8610c21',
        graph: 'https://login.microsoftonline.com/https%3A%2F%2F41ff26dc-250f-4b13-8981-739be8610c21%2Fpcm-prod'
    },
    scopes: {
        api: ['https://41ff26dc-250f-4b13-8981-739be8610c21/pcm/user_impersonation'],
        graph: ['https://graph.microsoft.com/User.Read'],
    },
    report: {
        id: '4ed901bd-d2fd-4e21-b878-e9ac70b53b60',
        workspace: 'dd3c27ed-bbbe-44e4-bf67-1bc8c8762f72'
    }
}
