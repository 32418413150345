import React, { useState, useEffect, useContext } from 'react';

import StyledTableCell from '../../Shared/StyledTableCell/StyledTableCell';
import ROCActivityRow from './ROCActivityRow/ROCActivityRow';
import UserContext from '../context/userContext';
import * as PROJECTS from '../../constants/projects';
import './rocActivitiesTable.css';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';

const ROCActivitiesTable = (props) => {

  // Context
  const userContext = useContext(UserContext);

  // Props state
  const levelId = props.levelId;
  const activities = props.activities;
  const attributes = props.attributes;
  const ruleOfCredits = props.ruleOfCredits;
  const cutOffDate = props.cutOffDate;

  const onUpdateRocProgressDate = props.onUpdateRocProgressDate;
  const onAddNewRocProgress = props.onAddNewRocProgress;
  const onAddNewRocProgressObj = props.onAddNewRocProgressObj;
  const onChangeCutOffDate = props.onChangeCutOffDate;
  const saveActivities = props.saveActivities;
  const filterByActivitySearch = props.filterByActivitySearch;

  // Pagination Settings
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const activitiesTotalCount = activities.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = (event.target.value === 'All')
      ? activitiesTotalCount
      : parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  // Filter Handlers
  const [selectedROC, setSelectedROC] = useState(ruleOfCredits[0]);
  const [baseFilterObj, setBaseFilterObj] = useState({});
  const [attributesFilterObj, setAttributesFilterObj] = useState({});
  const [filteredActivities, setFilteredActivities] = useState([])
  
  const updateBaseFilter = (property, newValue) => {
    const updatedBaseFilterObj = { ...baseFilterObj };
    updatedBaseFilterObj[property] = { ...updatedBaseFilterObj[property] };
    updatedBaseFilterObj[property].value = newValue;
    setBaseFilterObj(updatedBaseFilterObj);
  };

  const updateAttributesFilter = (attributeId, newValue) => {
    const updatedFilters = { ...attributesFilterObj };
    updatedFilters[attributeId] = { ...updatedFilters[attributeId] };
    updatedFilters[attributeId].value = newValue;
    setAttributesFilterObj(updatedFilters);
  };

  // Filters options will change when activities array changes
  useEffect(() => {
    _buildFilters();
    setSelectedROC(ruleOfCredits[0]);
  }, [activities]);
  
  useEffect(() => {
    if (baseFilterObj.id) {
      setFilteredActivities(filterActivities(activities));
    }
  }, [baseFilterObj, attributesFilterObj, selectedROC]);

  useEffect(() => {
    if (filterByActivitySearch && baseFilterObj.id) {
      updateBaseFilter("id", filterByActivitySearch)
    }
  }, [filterByActivitySearch]);

  // Filters setup
  const _buildFilters = () => {

    // Initialize Base Filter
    const initBaseFilterObj = {
      id: {
        value: filterByActivitySearch ? filterByActivitySearch : "",
        options: []
      },
      description: {
        value: "",
        options: []
      },
      costValue: {
        value: "",
        options: []
      },
      weighting: {
        value: "",
        options: []
      },
      responsibleParty: {
        value: "",
        options: []
      },
      primaveraTaskId: {
        value: "",
        options: []
      }
    };
    // Initialize Attributes Filter
    const initAttributeFilterObj = {};

    for (const activity of activities) {

      // Base
      initBaseFilterObj.id.options.push(activity.id);
      initBaseFilterObj.description.options.push(activity.description);
      initBaseFilterObj.costValue.options.push(activity.costValue);
      initBaseFilterObj.weighting.options.push(activity.weighting);
      initBaseFilterObj.responsibleParty.options.push(activity.responsibleParty);
      initBaseFilterObj.primaveraTaskId.options.push(activity.primaveraTaskId);

      // Attributes Filter
      for (const def of activity.definitions) {
        const attId = def.attributeId;
        const value = def.value;

        if (!initAttributeFilterObj[attId]) {
          initAttributeFilterObj[attId] = { value: "", options: [] };
        }

        initAttributeFilterObj[attId].options.push(value);
      }
    }

    // Remove Duplicates & Sort
    for (const key in initBaseFilterObj) {
      initBaseFilterObj[key].options = [...new Set(initBaseFilterObj[key].options)];

      if (key === "id") {
        continue;
      }
      else if (key === "costValue" || key === "weighting") {
        initBaseFilterObj[key].options.sort((a, b) => Number(a) - Number(b));
      }
      else {
        initBaseFilterObj[key].options.sort();
      }
    }
    for (const key in initAttributeFilterObj) {
      initAttributeFilterObj[key].options = [...new Set(initAttributeFilterObj[key].options)];
      initAttributeFilterObj[key].options.sort();
    }

    setBaseFilterObj(initBaseFilterObj);
    setAttributesFilterObj(initAttributeFilterObj);
  };

  const _applyFilters = (activity) => {

    var show = true;

    if (baseFilterObj.id
      && baseFilterObj.id.value !== ""
      && activity.id !== baseFilterObj.id.value) {
      return false;
    }

    if (baseFilterObj.description
      && baseFilterObj.description.value !== ""
      && activity.description !== baseFilterObj.description.value) {
      return false;
    }

    if (baseFilterObj.costValue
      && baseFilterObj.costValue.value !== ""
      && activity.costValue !== baseFilterObj.costValue.value) {
      return false;
    }

    if (baseFilterObj.weighting
      && baseFilterObj.weighting.value !== ""
      && activity.weighting !== baseFilterObj.weighting.value) {
      return false;
    }

    if (baseFilterObj.responsibleParty
      && baseFilterObj.responsibleParty.value !== ""
      && activity.responsibleParty !== baseFilterObj.responsibleParty.value) {
      return false;
    }

    if (baseFilterObj.primaveraTaskId
      && baseFilterObj.primaveraTaskId.value !== ""
      && activity.primaveraTaskId !== baseFilterObj.primaveraTaskId.value) {
      return false;
    }

    for (const attDef of activity.definitions) {
      const attributeId = attDef.attributeId;
      const attributeValue = attDef.value;

      if (attributesFilterObj[attributeId]
        && attributesFilterObj[attributeId].value !== ""
        && attributeValue !== attributesFilterObj[attributeId].value) {
        return false;
      }
    }

    return show;
  };

  const _applyCutOffDateFilter = (activity) => {
    let show = false;

    // Ignore cutOffDate filter when date is null
    if (!cutOffDate) {
      return true;
    }

    // Filter out activities w/o activity steps
    if (!activity.configuration
      || !activity.configuration.ruleOfCreditSteps
      || activity.configuration.ruleOfCreditSteps.length === 0
    ) {
      return show;
    }

    const steps = activity.configuration.ruleOfCreditSteps;
    for (let i = 0; i < steps.length; i++) {
      if (
        (steps[i].plannedDate && _isDateOnOrBeforeCutOffDate(steps[i].plannedDate)) ||
        (steps[i].forecastDate && _isDateOnOrBeforeCutOffDate(steps[i].forecastDate)) ||
        (steps[i].actualDate && _isDateOnOrBeforeCutOffDate(steps[i].actualDate))
      ) {
        show = true;
        break;
      }
    }

    return show;
  };

  const _isDateOnOrBeforeCutOffDate = (aStepDate) => {
    let convertedDate = new Date(aStepDate);

    if (aStepDate instanceof Date) {
      convertedDate.setHours(0,0,0,0);
    }

    return convertedDate <= cutOffDate;
  };

  const filterActivities = (activities) => {
    return activities
    .filter(activity => {
      return activity.configuration && activity.configuration.ruleOfCreditId === selectedROC.id;
    })
    .filter(_applyCutOffDateFilter)
    .filter(_applyFilters);
  }

  const setRocValue = (arg) => {
    setSelectedROC(arg);
  }

  const _userHasActivityWriteAccess = (anActivity) => {
    return (userContext.role === PROJECTS.Roles.ProjectAdmin)
      || (userContext.role === PROJECTS.Roles.InternalUser
          && userContext.alias === anActivity.responsibleParty);
  };

  return (
    <Grid id="activities-grid" className="ROCActivitiesTable padding20" container spacing={3} justify="space-between">
      
      <Grid container item alignItems="flex-end" xs={12} lg={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="rule-of-credit-label">Rule of Credit</InputLabel>
          <Select
            id="rule-of-credit"
            labelId="rule-of-credit-label"
            label="Rule of Credit"
            margin="dense"
            value={selectedROC}
            onChange={(event) => { setRocValue(event.target.value) }}
          >
            {ruleOfCredits.map((roc, index) =>
              <MenuItem key={roc.id} value={roc}>
                {roc.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} lg={3}>
        <FormControl variant="outlined" fullWidth>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id={"activity-date-filter"}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="dense"
              label={"Cutoff Date"}
              value={cutOffDate}
              onChange={newDate => {
                
                const dateOnly = new Date(
                  newDate.getFullYear(),
                  newDate.getMonth(),
                  newDate.getDate()
                );  // Clear time
                
                onChangeCutOffDate(dateOnly);
              }}
              autoOk={true}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    onChangeCutOffDate(null);
                  }}>
                    <ClearRoundedIcon />
                  </IconButton>
                )
              }}
              InputAdornmentProps={{
                position: "start"
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>

      {filteredActivities.length ?
        <>
          <Grid item xs={12}>
            <Paper elevation={2}>
              <TableContainer className="activities-table-container">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell className="stickyColumn">Description</StyledTableCell>
                      <StyledTableCell>Cost Value</StyledTableCell>
                      <StyledTableCell>Weighting</StyledTableCell>
                      <StyledTableCell>Responsible Party</StyledTableCell>
                      <StyledTableCell>Primavera Task ID</StyledTableCell>
                      <StyledTableCell>Project Weighting</StyledTableCell>

                      {attributes.map(attribute =>
                        <StyledTableCell key={attribute.id}>{attribute.name}</StyledTableCell>
                      )}

                      {selectedROC.activitySteps.map(step =>
                        <StyledTableCell className="attributeMinWidth" key={step.id}>{step.name}</StyledTableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      {["id", "description", "costValue", "weighting", "responsibleParty", "primaveraTaskId"].map(field => {
                        const filterObj = baseFilterObj[field];

                        if (!filterObj) {
                          return (<TableCell key={field}></TableCell>);
                        }

                        return (
                          <TableCell
                            key={field}
                            className={`${field === 'description' ? "stickyColumn whiteBackground" : ""}`}
                            style={{ maxWidth: '200px', overflowX: 'hidden' }}
                          >
                            <FormControl>
                              <Select
                                value={filterObj.value}
                                onChange={(event) => updateBaseFilter(field, event.target.value)}
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {filterObj.options.map(op => (
                                  <MenuItem key={op} value={op}>{op}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                        );
                      })}

                      {attributes.map(attribute => {
                        const filterObj = attributesFilterObj[attribute.id];

                        if (!filterObj) {
                          return (<TableCell key={attribute.id}></TableCell>);
                        }

                        return (
                          <TableCell key={attribute.id}>
                            <FormControl>
                              <Select
                                value={filterObj.value}
                                onChange={(event) => updateAttributesFilter(attribute.id, event.target.value)}
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {filterObj.options.map(op => (
                                  <MenuItem key={op} value={op}>{op}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                        );
                      })}
                      <TableCell colSpan={selectedROC.activitySteps.length} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredActivities
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(anActivity => {
                        const activityWritePermission = _userHasActivityWriteAccess(anActivity);   
                        return (
                          <ROCActivityRow
                            key={anActivity.id}
                            levelId={levelId}
                            activity={anActivity}
                            attributes={attributes}
                            ruleOfCredit={selectedROC}
                            readOnly={!activityWritePermission}
                            onUpdateRocProgressDate={onUpdateRocProgressDate}
                            onAddNewRocProgress={onAddNewRocProgress}
                            onAddNewRocProgressObj={onAddNewRocProgressObj}
                          />
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3, 10, 20, 'All']}
                component="div"
                count={filteredActivities.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="flex-end" spacing={3}>
              <Grid item lg={3} xs={12}>
                <Button fullWidth={true} variant="contained" color="primary" onClick={saveActivities} startIcon={<SaveRoundedIcon />}>
                  Save Activities
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
        :
        <Grid container justify="center" className="padding20">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            There are no activities for this rule of credit.
          </Grid>
        </Grid>

      }



    </Grid >
  );
};

export default ROCActivitiesTable;