import axios from "axios";
import { AuthTokenGenerator } from "./AuthTokenGenerator";
import { appConfig } from "../appConfig";

const basicErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    console.log(error);
    throw error;
  }
};

const getCategories = async (projectId, levelId) => {
  const Authorization = await AuthTokenGenerator.getToken();
  return axios
    .get(
      `${appConfig.baseUrl}/projects/${projectId}/levels/${levelId}/categories`,
      {
        headers: {
          Authorization,
        },
      }
    )
    .then(function (response) {
      return response.data.payload.categories;
    })
    .catch(basicErrorHandler);
};

const addCategory = async (projectId, levelId, category) => {
  const Authorization = await AuthTokenGenerator.getToken();
  return axios
    .post(`${appConfig.baseUrl}/projects/${projectId}/levels/${levelId}/categories`, category, {
      headers: {
        Authorization
      }
    }).then(response => {
      return response.data.payload.category
    }).catch(basicErrorHandler);
};

const getSecondaryUnitsOfMeasurement = async () => {
  const Authorization = await AuthTokenGenerator.getToken();
  return axios
    .get(`${appConfig.baseUrl}/unitofmeasurements`, {
      headers: {
        Authorization
      }
    }).then(response => {
      return response.data.payload.unitOfMeasurements
    }).catch(basicErrorHandler);
};

const deleteCategory = async (projectId, levelId, category) => {
  const Authorization = await AuthTokenGenerator.getToken();
  return axios
    .delete(
      `${appConfig.baseUrl}/projects/${projectId}/levels/${levelId}/categories/${category.id}`,
      {
        headers: {
          Authorization,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(basicErrorHandler);
};

export default {
  addCategory,
  getCategories,
  deleteCategory,
  getSecondaryUnitsOfMeasurement,
};
