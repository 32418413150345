import React, { useEffect, useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import { currency } from "../../../filters/currency";
import { DATE_FORMAT } from "../../../constants/strings";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useDatePickerStyles = makeStyles({
  root: {
    "& > div": {
      marginTop: "4px",
      marginBottom: "4px",
    },
  },
});

const useStickyHeaderStyles = makeStyles({
  root: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "#fff",
  },
});

const CustomDatePicker = (props) => {
  // Custom Styles
  const datePickerClasses = useDatePickerStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={datePickerClasses.root}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id={props.id}
          disableToolbar
          variant="inline"
          format={DATE_FORMAT}
          margin="normal"
          label={props.label}
          value={props.value}
          minDate={props.minDate}
          onChange={props.onChange}
          autoOk={true}
          KeyboardButtonProps={{ "aria-label": "change date" }}
          readOnly={props.readOnly}
          InputProps={{ readOnly: props.readOnly }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

const QuantityActivityRow = (props) => {
  // Custom Styles
  const stickyHeaderClasses = useStickyHeaderStyles();

  const defaultCategory = {
    id: 0,
    name: "None",
  };

  const [selectedPrimaryCategory, setSelectedPrimaryCategory] =
    useState(defaultCategory);
  const [selectedSecondaryCategory, setSelectedSecondaryCategory] =
    useState(defaultCategory);
  const [secondaryPlannedQuantity, setSecondaryPlannedQuantity] = useState(
    props.activity.configuration.quantity.secondaryPlannedQuantity
  );
  const [secondaryCompletedQuantity, setSecondaryCompletedQuantity] = useState(
    props.activity.configuration.quantity.secondaryCompletedQuantity
  );

  // Props state
  const levelId = props.levelId;
  const activity = props.activity;
  const attributes = props.attributes;
  const categories = props.categories;
  const readOnly = props.readOnly;

  const onUpdatePlannedQuantity = props.onUpdatePlannedQuantity;
  const onUpdateCompletedQuantity = props.onUpdateCompletedQuantity;
  const onUpdateForecastStartDate = props.onUpdateForecastStartDate;
  const onUpdateForecastFinishDate = props.onUpdateForecastFinishDate;
  const onUpdateActualStartDate = props.onUpdateActualStartDate;
  const onUpdateActualFinishDate = props.onUpdateActualFinishDate;

  // Props Functions
  const _renderAttributeValues = (anActivity) => {
    return attributes.map((anAttribute) => {
      var attributeValue = null;

      if (anActivity.definitions) {
        const attributeDef = anActivity.definitions.find(
          (d) => d.attributeId === anAttribute.id
        );
        attributeValue = attributeDef ? attributeDef.value : null;
      }

      return (
        <TableCell
          key={"activity-" + anActivity.id + "_attribute-" + anAttribute.id}
        >
          {attributeValue}
        </TableCell>
      );
    });
  };

  const renderPrimaryOptions = () => {
    const array = [defaultCategory, ...categories];
    return renderCategoryList(array);
  };

  const renderSecondaryOptions = (categories) => {
    const array = [defaultCategory, ...categories];
    return renderCategoryList(array);
  };

  const renderCategoryList = (categories) =>
    categories.map((category) => (
      <MenuItem key={category.id} value={category}>
        {category.name}
      </MenuItem>
    ));

  const handleChangePrimaryCategory = (e) => {
    const primary = e.target.value;
    setSelectedPrimaryCategory(primary);
    setSelectedSecondaryCategory(defaultCategory);
    props.onUpdatePrimaryCategory(levelId, activity.id, e.target.value);
  };

  const handleChangeSecondaryCategory = (e) => {
    props.onUpdateSecondaryCategory(levelId, activity.id, e.target.value);
    setSelectedSecondaryCategory(e.target.value);
  };

  const handleChangeSecondaryPlannedQuantity = (e) => {
    setSecondaryPlannedQuantity(e.target.value);
    props.onUpdateSecondaryPlannedQuantity(
      levelId,
      activity.id,
      e.target.value
    );
  };

  const handleChangeSecondarySecondaryQuantity = (e) => {
    setSecondaryCompletedQuantity(e.target.value);
    props.onUpdateSecondaryCompletedQuantity(
      levelId,
      activity.id,
      e.target.value
    );
  };

  useEffect(() => {
    const { primaryCategory, secondaryCategory } =
      activity.configuration.quantity;
    if (primaryCategory) {
      const selectedPrimary = categories.find(
        (cat) => cat.id === primaryCategory.id
      );
      setSelectedPrimaryCategory(selectedPrimary);
      if (secondaryCategory) {
        setSelectedSecondaryCategory(
          selectedPrimary.children.find(
            (cat) => cat.id === secondaryCategory.id
          )
        );
      }
    }
  }, []);

  return (
    <TableRow key={activity.id}>
      <TableCell>{activity.id}</TableCell>
      <TableCell className={stickyHeaderClasses.root}>
        {activity.description}
      </TableCell>
      <TableCell>{currency(activity.costValue)}</TableCell>
      <TableCell>{activity.weighting}</TableCell>
      <TableCell>{activity.responsibleParty}</TableCell>
      <TableCell>{activity.primaveraTaskId}</TableCell>

      <TableCell>{activity.weight.project}</TableCell>
      <TableCell>
        {activity.configuration.quantity.unitOfMeasurement.name}
      </TableCell>
      <TableCell>{activity.configuration.quantity.UnitValue}</TableCell>
      <TableCell>{activity.configuration.quantity.Quantity}</TableCell>

      {_renderAttributeValues(activity)}

      <TableCell>{activity.configuration.quantity.planStartDate}</TableCell>
      <TableCell>{activity.configuration.quantity.planFinishDate}</TableCell>

      <TableCell>
        <CustomDatePicker
          id={`forecastStartDate-activity-${activity.id}`}
          label={null}
          value={activity.configuration.quantity.forecastStartDate ?? null}
          onChange={(newDate) =>
            onUpdateForecastStartDate(levelId, activity.id, newDate)
          }
          readOnly={readOnly}
        />
      </TableCell>

      <TableCell>
        <CustomDatePicker
          id={`forecastFinishDate-activity-${activity.id}`}
          label={null}
          value={activity.configuration.quantity.forecastFinishDate ?? null}
          onChange={(newDate) =>
            onUpdateForecastFinishDate(levelId, activity.id, newDate)
          }
          readOnly={readOnly}
          minDate={activity.configuration.quantity.forecastStartDate}
        />
      </TableCell>

      <TableCell>
        <CustomDatePicker
          id={`actualStartDate-activity-${activity.id}`}
          label={null}
          value={activity.configuration.quantity.actualStartDate ?? null}
          onChange={(newDate) =>
            onUpdateActualStartDate(levelId, activity.id, newDate)
          }
          readOnly={readOnly}
        />
      </TableCell>

      <TableCell>
        <CustomDatePicker
          id={`actualFinishDate-activity-${activity.id}`}
          label={null}
          value={activity.configuration.quantity.actualFinishDate ?? null}
          onChange={(newDate) =>
            onUpdateActualFinishDate(levelId, activity.id, newDate)
          }
          readOnly={readOnly}
          minDate={activity.configuration.quantity.actualStartDate}
        />
      </TableCell>

      <TableCell>
        <FormControl variant="outlined" required>
          <TextField
            id={`plannedQuantity-activity-${activity.id}`}
            type="number"
            size="small"
            margin="dense"
            variant="outlined"
            value={activity.configuration.quantity.plannedQuantity}
            onChange={(event) =>
              onUpdatePlannedQuantity(levelId, activity.id, event.target.value)
            }
            inputProps={{
              min: 0,
              readOnly: readOnly,
            }}
          />
        </FormControl>
      </TableCell>

      <TableCell>
        <FormControl variant="outlined" required>
          <TextField
            id={`completedQuantity-activity-${activity.id}`}
            type="number"
            size="small"
            margin="dense"
            variant="outlined"
            value={activity.configuration.quantity.completedQuantity}
            onChange={(event) =>
              onUpdateCompletedQuantity(
                levelId,
                activity.id,
                event.target.value
              )
            }
            inputProps={{
              min: 0,
              readOnly: readOnly,
            }}
          />
        </FormControl>
      </TableCell>

      <TableCell>
        <FormControl>
          <Select
            value={selectedPrimaryCategory}
            onChange={handleChangePrimaryCategory}
            disabled={selectedSecondaryCategory.id !== 0}
          >
            {categories && renderPrimaryOptions()}
          </Select>
        </FormControl>
      </TableCell>

      <TableCell>
        <FormControl>
          {selectedPrimaryCategory.children ? (
            <Select
              value={selectedSecondaryCategory}
              onChange={handleChangeSecondaryCategory}
            >
              {selectedPrimaryCategory.children &&
                renderSecondaryOptions(selectedPrimaryCategory.children)}
            </Select>
          ) : (
            <span></span>
          )}
        </FormControl>
      </TableCell>

      <TableCell>
        {selectedSecondaryCategory.id !== 0 &&
          selectedSecondaryCategory.unitOfMeasurement &&
          selectedSecondaryCategory.unitOfMeasurement.name}
      </TableCell>

      <TableCell>
        {selectedSecondaryCategory.id !== 0 && (
          <FormControl variant="outlined" required>
            <TextField
              id={`scondaryPlannedQuantity-activity-${activity.id}`}
              type="number"
              size="small"
              margin="dense"
              variant="outlined"
              value={secondaryPlannedQuantity}
              onChange={handleChangeSecondaryPlannedQuantity}
              inputProps={{
                min: 0,
                defaultValue: 0,
              }}
            />
          </FormControl>
        )}
      </TableCell>

      <TableCell>
        {selectedSecondaryCategory.id !== 0 && (
          <FormControl variant="outlined" required>
            <TextField
              id={`scondaryCompletedQuantity-activity-${activity.id}`}
              type="number"
              size="small"
              margin="dense"
              variant="outlined"
              value={secondaryCompletedQuantity}
              onChange={handleChangeSecondarySecondaryQuantity}
              inputProps={{
                min: 0,
                defaultValue: 0,
              }}
            />
          </FormControl>
        )}
      </TableCell>
    </TableRow>
  );
};

export default QuantityActivityRow;
